import * as THREE from "three";
import React, {useRef, Suspense, useState, useMemo} from "react";
import {Canvas, useThree, useFrame} from "react-three-fiber";
import {ROOM_DEPTH, FREE_SPACE} from "../config";
import LivingRoom from "./LivingRoom";
import {useStore} from "../store";
import Wardrobe from "./Wardrobe";
import Lights from "./Lights";
import {useSteps} from "../Stores/Steps";
import {getSpaceWidth} from "../Space";
import {getType as getRoomType} from "../utils/room";
import {VRCanvas, ARCanvas} from 'react-xr'
import {Camera} from "./Components/Camera";
import {PerspectiveCamera, OrbitControls, OrthographicCamera} from 'drei'
import {Stats} from "../Stats";
import {getWardrobeAttributes} from "../utils/parameters";
import {isMobile} from 'react-device-detect';

/**
 * @param {Object} wardrobeAttributes
 * @param {Boolean} fp
 * @param {String} cameraType
 * @returns {JSX.Element}
 * @constructor
 */
const Scene = ({wardrobeAttributes, fp, cameraType, cameraZ = null}) => {
    let selectedSpace = useStore(state => state.selectedSpace);
    const spacesCount = useStore(state => state.spacesCount);
    const roomWidth = wardrobeAttributes.width;
    const roomHeight = wardrobeAttributes.height;
    const roomType = useStore(state => state.roomType);
    const objType = useStore(state => state.objType);
    const spaceWidth = getSpaceWidth(objType, roomWidth, spacesCount, roomType);

    const [scene, target] = useMemo(() => {
        const scene = new THREE.Scene()
        scene.background = new THREE.Color('orange')
        const target = new THREE.WebGLMultisampleRenderTarget(1024, 1024, {
            format: THREE.RGBFormat,
            stencilBuffer: false
        })
        target.samples = 8
        return [scene, target]
    }, [])

    const cameraPositionY = useMemo(() => {
        return (roomHeight / 2) + 20;
    }, [roomHeight]);

    if (!selectedSpace) {
        selectedSpace = 1;
    } else if (isNaN(selectedSpace)) {
        selectedSpace = selectedSpace.substr(0, selectedSpace.length - 1);
    }

    if (!cameraZ) {
        cameraZ = isMobile ? 400 : 230;
    }

    const cameraPositionX = (spaceWidth * selectedSpace) - spaceWidth / 2;
    const cameraLocked = useStore(state => state.cameraLocked);
    const {camera} = useThree();

    useMemo(() => {
        if (fp) {
            camera.position.set(roomWidth / 2, 140, cameraZ);
        } else if (cameraType === 'orthographic') {
            camera.position.set(cameraPositionX, cameraPositionY - 20, cameraZ);
        } else {
            camera.position.set(roomWidth / 2, cameraPositionY, cameraZ);
        }
    }, [camera.position, roomWidth, cameraPositionY, fp, cameraPositionX, cameraType]);

    const controls = useRef();

    useFrame((state) => {
        camera.current.position.z = 5 + Math.sin(state.clock.getElapsedTime() * 1.5) * 2
        state.gl.setRenderTarget(target)
        state.gl.render(scene, camera.current)
        state.gl.setRenderTarget(null)
    })

    if (fp) {
        return <Camera/>;
    } else if (cameraType === 'orthographic') {
        return <OrthographicCamera ref={camera}/>;
    } else {
        return (
            <>
                <PerspectiveCamera ref={camera}/>
                <OrbitControls
                    ref={controls}
                    target={new THREE.Vector3(roomWidth / 2, cameraPositionY - 20, 0)}
                    minDistance={200}
                    maxDistance={400}
                    rotateSpeed={0.5}
                    minAzimuthAngle={-1.2}
                    maxAzimuthAngle={1.2}
                    maxPolarAngle={2.3}
                    minPolarAngle={0.5}
                    enableZoom={!cameraLocked}
                    enableRotate={!cameraLocked}
                    enablePan={false}
                />
            </>
        );
    }
};

/**
 * @param {Object} previewAttributes
 * @param {Boolean} vr
 * @param {Boolean} ar
 * @param {Boolean} fp
 * @param {String} cameraType
 * @param cameraZ
 * @returns {JSX.Element}
 * @constructor
 */
function PreviewContent({previewAttributes, vr, ar, fp, cameraType, cameraZ = null}) {
    const selectedRoomType = useStore(state => state.roomType);
    const selectedObjType = useStore(state => state.objType);
    const actualStep = useSteps(state => state.actualStep);
    const spaceHeight = useStore(state => state.spaceHeight);
    const wallColor = useStore(state => state.wallColor);
    const depth = useStore(state => state.depth);
    const spacesCount = useStore(state => state.spacesCount);
    const selectedProfile = useStore(state => state.selectedProfile);
    const height = useStore(state => state.height);
    const width = useStore(state => state.width);

    let roomType = useMemo(() => {
        return getRoomType(selectedRoomType, selectedObjType);
    }, [selectedRoomType, selectedObjType]);

    if (ar) {
        roomType = null;
    }

    const freeSpace = selectedObjType === 'v' ? 0 : FREE_SPACE;

    const wardrobeAttributes = useMemo(() => {
        return getWardrobeAttributes({
            width: width,
            height: height,
            depth: depth,
            type: roomType,
            spacesCount: spacesCount ?? 2,
            freeSpace: freeSpace,
        })
    }, [width, height, depth, roomType, spacesCount, freeSpace]);

    const spaceWidth = getSpaceWidth(selectedObjType, wardrobeAttributes.width, wardrobeAttributes.spacesCount, roomType, 'corpuse');
    const doorWidth = getSpaceWidth(selectedObjType, wardrobeAttributes.width, wardrobeAttributes.spacesCount, roomType, 'door');

    const spaceAttributes = useMemo(() => {
        return {
            width: spaceWidth,
            height: spaceHeight,
            actualStep: actualStep,
            depth: wardrobeAttributes.depth - freeSpace,
        }
    }, [spaceWidth, spaceHeight, actualStep, wardrobeAttributes.depth, freeSpace]);

    const doorAttributes = useMemo(() => {
        return {
            width: doorWidth,
            height: wardrobeAttributes.height - 5,
            profile: selectedProfile,
        }
    }, [doorWidth, wardrobeAttributes.height, selectedProfile]);

    const roomAttributes = useMemo(() => {
        const backWallType = 'b';
        const roomHeight = wardrobeAttributes.height;
        let roomWidth = wardrobeAttributes.width + 100;

        if (roomType === "d" || roomType === "a" || backWallType === 'b') {
            roomWidth += 100;
        }

        return {
            width: roomWidth,
            isHeightSet: wardrobeAttributes.isHeightSet,
            height: roomHeight,
            depth: ROOM_DEPTH,
            type: roomType,
            spacesCount: wardrobeAttributes.spacesCount,
            showUI: (!vr && !ar),
            objType: selectedObjType,
            backWallType: backWallType,
            wallColor: wallColor,
        }
    }, [wardrobeAttributes.isHeightSet, wardrobeAttributes.width, wardrobeAttributes.height, roomType, wardrobeAttributes.spacesCount, vr, ar, selectedObjType, wallColor]);

    return (
        <>
            <Lights
                roomAttributes={roomAttributes}
            />
            {!ar && <Scene wardrobeAttributes={wardrobeAttributes} fp={fp} cameraType={cameraType} cameraZ={cameraZ}/>}
            <Suspense fallback={false}>
                {roomType && <LivingRoom roomAttributes={roomAttributes} wardrobeAttributes={wardrobeAttributes}/>}
                <Wardrobe
                    spaceAttributes={spaceAttributes}
                    doorAttributes={doorAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    roomAttributes={roomAttributes}
                    previewAttributes={previewAttributes}
                />
            </Suspense>
        </>
    );
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()

    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
            onClick={(e) => setActive(!active)}
            onPointerOver={(e) => setHover(true)}
            onPointerOut={(e) => setHover(false)}>
            <boxBufferGeometry args={[1, 1, 1]}/>
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'}/>
        </mesh>
    )
}

/**
 * @param {Boolean} vr
 * @param {Boolean} ar
 * @param {Boolean} fp
 * @param {String} cameraType
 * @param {String} name
 * @returns {JSX.Element}
 * @constructor
 */
export default function Preview({vr, ar, fp, cameraType, name, fullScreenHandle, cameraZ = null}) {
    /*
        let vrDisplay = null;

        if(navigator.getVRDisplays) {
            console.log('WebVR 1.1 supported');
            // Then get the displays attached to the computer
            navigator.getVRDisplays().then(function(displays) {
                // If a display is available, use it to present the scene
                if(displays.length > 0) {
                    vrDisplay = displays[0];
                    // Now we have our VRDisplay object and can do what we want with it
                }
            });
        }*/

    //x vertical
    //y horizontal
    const canvasStyle = {backgroundColor: 'white'}
    let isFullScreenActive = true;

    if (!fullScreenHandle || (fullScreenHandle && !fullScreenHandle.active)) {
        canvasStyle['height'] = isMobile ? 300 : 450;
        isFullScreenActive = false;
    }

    const showStats = false;

    const previewAttributes = useMemo(() => {
        return {
            name: name,
            isFullScreenActive: isFullScreenActive,
            isVR: vr,
            isAR: ar,
            showSelected: (!vr && !ar && !isFullScreenActive),
        }
    }, [name, isFullScreenActive, vr, ar]);

    if (vr) {
        return (
            <VRCanvas>
                <group position-y={-100} position-x={-100} position-z={-200}>
                    <PreviewContent previewAttributes={previewAttributes} vr={true} ar={false}/>
                </group>
            </VRCanvas>
        );
    } else if (ar) {
        return (
            <ARCanvas>
                <Box position={[0, 0, 0]}/>
                <group position-y={-250} position-x={-250} position-z={-450} scale={[2, 2, 2]}>
                    <PreviewContent previewAttributes={previewAttributes} vr={false} ar={true}/>
                </group>
            </ARCanvas>
        );
    } else {
        return (
            <Canvas style={canvasStyle} gl={{antialias: true}}>
                {showStats && <Stats/>}
                <PreviewContent previewAttributes={previewAttributes} vr={false} fp={fp} cameraType={cameraType} cameraZ={cameraZ}/>
            </Canvas>
        );
    }
}