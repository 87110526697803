export const BUTTONS_LANG = {
    roomType: "Výběr typu půdorysu",
    setWardrobeParameters: "Zadání parametrů",
    selectCorpusesSetSpacesCount: "Výběr počtu dveří",
    selectCorpuses: "Skládání vnitřku",
    selectDTD: "Výběr dřevodekoru",
    selectDTDSecond: "Výběr dřevodekoru bočnice",
    selectDoorSpacesCount: "Výběr typu dveří",
    selectProfiles: "Výběr typ a barvu profilu",
    selectDoorsFill: "Výběr výplně dveří",
    order: "Objednávkový formulář",
    accessories: "Upravit doplňky",
    priceCalculation: "Cenová kalkulace",
    showPriceCalculation: "Zobrazit celkovou kalkulaci včetně doplňků a všech poplatků",
    resetHeights: "Původní nastavení",
};