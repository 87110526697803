import {PROFILES_LANG} from './components/profiles';
import {INFO_LANG} from './components/info';
import {NAVIGATION_LANG} from './components/navigation';
import {INPUTS_LANG} from './components/inputs';
import {BUTTONS_LANG} from './components/buttons';
import {PRODUCTS_LANG} from './components/products';
import {ACCESSORIES_LANG} from './components/accessories';
import {TABS_LANG} from './components/tabs';

export const LANG = {
    "roomTypeDescription":
        "V prvním kroku vytvoření Vaší vestavěné skříně si kliknutím na obrázek vyberte typ půdorysu(typ A, B, C nebo D) vzhledem k tomu, kde má být Vaše vestavěná skříň umístěna.",
    "roomTitle": "Zadání parametrů",
    "selectCorpusesSetSpacesCountTitle": "Počet prostorů",
    "selectCorpusesSetSpacesCountTitleDescription":
        "Vyberte si počet prostorů, které bude mít Vaše vestavěná skříň. Systém Vám automaticky přepočítá optimální počet dveří na počet prostorů dle Vámi zadaných rozměrů, avšak máte možnost si tento počet snížit nebo zvýšit. Optimální rozměr prostor je kolem 80 cm. Prostor nad 100 cm se rozdělí na polovinu z důvodu možnosti prohnutí polic. Po vyplnění všech údajů pokračujte dále kliknutím na tlačítko Pokračovat",

    "BasicSettingsTitle": "Základní nastavení ",
    "selectDoorsSetSpacesCountTitle": "Počet prostorů",
    "selectCorpusesTitle": "Skládání vnitřku",
    "selectCorpusesModalTitle": "Skládání vnitřku",
    "selectCorpusesDescription": "Skládání vnitřku",
    "selectCorpusesVariantsTitle": "Výběr z předdefinovaných korpusů",
    "SelectCorpusTooltipTitle": "Kliknutím se zobrazí",
    "SelectCorpusTooltipText": " seznam pro vyber korpusu do daného prostoru",
    "selectDoorsVariantsTitle": "Výběr z předdefinovaných kombinací dveří",
    "selectDoorSpacesCountTitle": "Výběr typu dveří",
    "selectDoorsTitle": "Výběr typu dveří",
    "selectDoorsDescription":
        "V tomto kroku si vyberte pro každý prostor typ posuvných dveří. Na výběr jsou plné dveře, ale i dveře dělené (na polovinu, na třetiny, dokonce i na menší části). Prostor pro který se aktuálně definuje typ je označen zelenou barvou.",
    "roomTypeTitle": "Výběr typu půdorysu",
    "Attributes": "Parametry",
    "Corpuses": "Vnitřek",
    "Doors": "Dveře",
    "Order": "Objednat",
    "SubmitOrder": "Objednat",
    "Next": "Další",
    "Preview": "Zpět",
    "NextStep": "Pokračovat",
    "PreviewStep": "Zpět",
    "SaveOrder": "Uložit",
    "Close": "Zavřít",
    "Type": "Typ",
    "TypeA": "Typ A",
    "TypeB": "Typ B",
    "TypeC": "Typ C",
    "TypeD": "Typ D",
    "LongHanging": 'Dlouhé věšení',
    "ShortHanging": 'Krátké věšení',
    "CombHanging": 'Kombinované věšení',
    "Shoes": 'Obuv',
    "Shelf": 'Police',
    "Drawers": 'Zásuvky',
    "Glasses": 'Skla',
    "Mirrors": 'Zrcadla',
    "profiles": PROFILES_LANG,
    "info": INFO_LANG,
    "navigation": NAVIGATION_LANG,
    "inputs": INPUTS_LANG,
    "buttons": BUTTONS_LANG,
    "products": PRODUCTS_LANG,
    "accessories": ACCESSORIES_LANG,
    "tabs": TABS_LANG,
    "egeer_standard": 'Egeer standard',
    "egeer_exclusive": 'Egeer exclusive',
    "HideDoor": 'Dveře nezobrazeny',
    "ShowDoor": 'dveře zobrazeny',
    "Width": 'Šířka',
    "Height": 'Výška',
    "Depth": 'Hloubka',
    "LeftHeight": 'Levá výška stropu',
    "RightHeight": 'Pravá výška stropu',
    "LeftBackHeight": 'Levá zadn výška stropu',
    "LeftFrontHeight": 'Levá přední výška stropu',
    "RightBackHeight": 'Pravá zadní výška stropu',
    "RightFrontHeight": 'Pravá přední výška stropu',
    "HideDimensions": 'Kóty nezobrazené',
    "ShowDimensions": 'Kóty zobrazené',
    "SiteTitle": 'Konfigurátor vestavěných skříní',
    "SelectTypeInfo": 'Konfigurátor vestavěných skříní',
    "TypeVDName": 'Vestavěná skříň<br />komplet',
    "TypeDName": 'Posuvné dveře<br />samostatné',
    "TypeVName": 'Vnitřek vestavěné skříně<br />samostatné',
    "BackOnHomeSite": 'Zpět na domovskou stránku',
    "SelectSpacesCountTitle": 'Výběr počtu prostorů',
    "SelectDoorsCountTitle": 'Výběr počtu dveří',
    "CorpusesVariantsTabName": 'Předdefinovány',
    "ButtonShowCorpusVariantsModal": 'Zobrazit seznam předdefinovaných variant',
    "SelectVariantsDoorsTabName": 'Předdefinovány',
    "SelectOwnDoorsTabName": 'Vlastní',
    "SelectCorpusesTitle": 'Skládání vnitřku',
    "SelectDTDTitle": 'Výběr dřevodekoru',
    "DTDMainTabName": 'Vnitřek',
    "DTDSecondTabName": 'Bočnice',
    "SelectDoorSpacesCountTitle": 'Výběr typu dveří',
    "SelectProfilesTitle": "Vyběr typu a barvy profilu",
    "SelectDoorsFillTitle": "Vyběr výplní dveří",
    "DoorFillsAreEmptyError": "{0}. dveře nemají zadanou {1} výplň",
    "DoorFillsAreEmptyErrorAnd": "{0} a {1}",
    "RoomTypeTitle": "Výběr typu půdorysu",
    "DivideOn": 'Rozdělit prostor',
    "DivideOff": 'Sloučit prostor',
    "OrderForm": "Objednávkový formulář",
    "FirstName": "Jméno",
    "LastName": "Příjmení",
    "Description": "Poznámka",
    "Phone": "Telefon",
    "Email": "Email",
    "Street": "Ulice",
    "City": "Město",
    "ZIP": "PSČ",
    "BillAddress": "Fakturační adresa",
    "DeliveryAddress": "Dodací adresa",
    "isOrgOrder": "Nakupuji na firmu",
    "Organization": "Firma",
    "ICO": "IČO",
    "DIC": "DIČ",
    "IC DPH": "IČ DPH",
    "TermsAndConditions": "Přečetl jsem si a souhlasím s obchodními podmínkami",
    "OrderInfo": "Pro dokončení objednávky a závazné objednání, prosím klikněte na tlačítko Objednat.",
    "SavePhoneInfo": "V případě, že si přejete aby Vás kontaktoval náš odborný konzultant, zanechte nám na Vás telefonní kontakt",
    "SaveEmailInfo": "Pokud si přejete uložit rozpočet, zadejte prosím emailovou adresu.",
    "SelectCorpuse": "Vybrat korpus",
    "ChangeCorpuse": "Změnit korpus",
    "CorpusesSpaceIsEmptyError": "{0}. vnitřek je prázdný",
    "AllSpacesMustHaveCorpusesInfo": "Pro pokračování musí mít všechny prostory vybrané vnitřek.",
    "DivideSpaceOn": "Rozdělit prostor",
    "DivideSpaceOff": "Sloučit prostor",
    "DivideButtonTooltipTitle": 'Kliknutím máte možnost si jednotlivé prostory předělit',
    "DivideButtonTooltipText": 'a následně si do předěleného prostoru zkombinovat libovolnou sestavu',
    "ChangeDoorType": "Změnit typ",
    "SelectDoorType": "Vybrat typ",
    "Icon3DRotationTooltipTitle": "Rotace pohledu",
    "Icon3DRotationText": "Přidržením levého tlačítka myši můžete měnit úhel pohledu",
    "EditInputLabelwidth": 'Šírka',
    "EditInputLabelheight": 'Výška',
    "EditInputLabeldepth": 'Hloubka',
    "BackDoor": 'Předešlé',
    "NextDoor": 'Další',
    "EditDoorSpacesHeight": "Upravit rozměry výplní",
    "BadMinValueFillHeight": 'Minimální výška výplně je 10cm',
    "ThisHeightIsAutomaticCalculated": 'Výška je vypočtena na základě zadaných parametrů ',
    "TotalHeightEditError": 'Zadaná výška nesmí přesáhnout celkovou výšku dveří',
    "ActivateFullScreen": 'Zvětšit na celou obrazovku ',
    'BadHeightValuesInfo': 'Rozdíl výšek nesmí být větší než {0} cm, zadejte nejmenší naměřený rozměr.',
    "AttributeTitleDTDMain": "Dřevodekoru",
    "AttributeTitleABSMain": "ABS",
    "AttributeTitleDTDSecondA": "Dřevodekor dorazové lišty",
    "AttributeTitleABSSecondA": "Hranovací páska dorazové lišty",
    "AttributeTitleDTDSecondB": "Dřevodekor dorazové lišty a bočnice",
    "AttributeTitleABSSecondB": "Hranovací páska dorazové lišty a bočnice",
    "AttributeTitleDTDSecondC": "Dřevodekor bočnice a dorazové lišty",
    "AttributeTitleABSSecondC": "Hranovací páska bočnice a dorazové lišty",
    "AttributeTitleDTDSecondD": "Dřevodekor bočnice",
    "AttributeTitleABSSecondD": "Hranovací páska bočnice",
    "Profile": "Profil",
    "white": "Bílý",
    "sampan": "Šampaň",
    "silver": "Stříbrný",
    "gold": "Zlatý",
    "silverMinimax": "Stříbrný Minimax",
    "AllAttributesMustHaveBeenCorrectInfo": "Pro pokračování musí být všechny zadané rozměry korektní.",
    "WallColorBtnLabel": "Barva stěn",
    "SpaceWidthDoorInfo": "Šířka jednoho prostoru: {0} cm ",
    "Visible": "Viditelné",
    "Dimensions": 'Kóty',
    "SearchDTD": 'Vyhledat dřevodekor ',
    "SearchDoorFill": 'Vyhledat výplň',
    "SpacesCountIsEmptyErrorV": 'Pro pokračování musíte vybrat počet prostorů',
    "SpacesCountIsEmptyError": 'Pro pokračování musíte vybrat počet dveří',
    "Accessories": 'Doplňky',
    "WardrobePriceWithoutAccessories": 'Cena vestavné skříně bez doplňků',
    "PriceCalculation": 'Cenová kalkulace',
    "CalculationItemNameVD": 'Vestavěná skříň',
    "CalculationItemNameD": 'Posuvné dveře',
    "CalculationItemNameV": 'Vnitřek vestavěné skříně',
    "Delivery": 'Doprava',
    "Packing": 'Balné',
    "TotalPrice": 'Cena celkem',
    "Skip": 'Přeskočit',
    "Save": 'Uložit',
    "selectDTDInTitle": 'Výběr barvy vnitřku',
    "selectDTDOutTitle": 'Výběr barvy pro viditelné části',
    "selectDTDIn": 'Výběr barvy vnitřku',
    "selectDTDOut": 'Výběr barvy pro viditelné části',
    "TotalPriceWithVat": 'Cena celkem s DPH ({0}%)',
    "OrderWasSuccessfullyCreated": 'Objednávka byla úspěšně vytvořena',
    "OrderWasSuccessfullySaved": 'Váš rozpočet byl úspěšně uložen',
    "OrderWasNotSuccessfullySaved": 'Váš rozpočet se nepodařilo uložit',
};
