import React from "react";
import "semantic-ui-css/semantic.min.css";
import {apiStore, useStore} from "./store";
import {connect} from 'react-redux';
import {LOAD_DATA} from './constants/actionTypes';
import agent from "./agent";

function Save({loadData}) {
    const saveSpacesCount = useStore(state => state.saveSpacesCount);
    const setObjType = useStore(state => state.setObjType);
    const setCorpusType = useStore(state => state.setCorpusType);

    if (loadData && loadData.data) {
        apiStore.getState().setRoomHeight(loadData.data.height);
        apiStore.getState().setRoomWidth(loadData.data.width);
        apiStore.getState().setRoomDepth(loadData.data.depth);
        saveSpacesCount(loadData.data.spacesCount);
        setObjType(loadData.data.objType);

        for (let i = 1; i <= loadData.data.spacesCount; i++) {
            setCorpusType(i, loadData.data.selectedCorpuses[i]);
        }
    }

    return (
        <>
            {JSON.stringify(loadData)}
        </>
    );
}

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
        dispatch({type: LOAD_DATA, payload}),
});

class Load extends React.Component {

    componentDidMount() {
        this.props.onLoad(agent.Data.get());
    }

    render() {
        return (
            <Save loadData={this.props.loadData}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Load);