import React, {useCallback, useMemo} from "react";
import {useStore} from "../../../store";
import {Sticky} from "semantic-ui-react";
import {Card, Paper} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import DoorStepper from "../Components/Molecules/DoorStepper";
import {Lang} from "../../../Utils";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    buttons: {
        padding: 10,
        display: 'flex',
        flexDirection: 'columns',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));


const useCardStyles = makeStyles({
    root: {
        width: 50,
        margin: 10,

    },
    media: {
        width: 50,
    },
    badge: {
        top: '85%',
    }
});

function FillImgCard({type, woodDecor, productImages}) {
    const classes = useCardStyles();
    const setDoorFill = useStore(state => state.setDoorFill);
    const selectNextDoorFill = useStore(state => state.selectNextDoorFill);
    const setPriceVersion = useStore(state => state.setPriceVersion);

    const onClickSelectFill = useCallback(
        (e) => {
            e.stopPropagation();
            setDoorFill(type, woodDecor.id, productImages[woodDecor.id]);
            setPriceVersion();
            selectNextDoorFill();
        },
        [setDoorFill, type, woodDecor, productImages, setPriceVersion, selectNextDoorFill]
    );

    return (
        <GridListTile key={'GridListTile' + woodDecor.id}>
            <Card
                className={classes.root}
                key={woodDecor.id}
                onPointerDown={(e) => onClickSelectFill(e)}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        className={classes.media}
                        alt={woodDecor.code + ' ' + Lang(woodDecor.name, 'products')}
                        height="50"
                        image={process.env.PUBLIC_URL + '/' + woodDecor.img_road}
                        title={woodDecor.code + ' ' + Lang(woodDecor.name, 'products')}
                    />
                </CardActionArea>
            </Card>
        </GridListTile>
    );
}

/**
 * @param contextRef
 * @param doorsFillsProducts
 * @param productImages
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDoorsFill = ({contextRef, doorsFillsProducts, productImages}) => {
    const classes = useStyles();
    let selectedSpace = useStore(state => state.selectedSpace);
    const setSpace = useStore(state => state.setSpace);
    const setSelectedDoorFillId = useStore(state => state.setSelectedDoorFillId);
    const doorFillsIds = useStore(state => state.doorFillsIds);
    if (!selectedSpace) selectedSpace = 1;

    const handleNext = useCallback(
        (e) => {
            e.stopPropagation();
            const newSpaceId = (selectedSpace * 1) + 1;
            setSpace(newSpaceId);
            setSelectedDoorFillId(newSpaceId + '-' + 1);
        },
        [setSpace, selectedSpace, setSelectedDoorFillId]
    );

    const handleBack = useCallback(
        (e) => {
            e.stopPropagation();
            const newSpaceId = selectedSpace - 1;
            setSpace(newSpaceId);
            setSelectedDoorFillId(newSpaceId + '-' + 1);
        },
        [selectedSpace, setSpace, setSelectedDoorFillId]
    );

    const productImagesLarge = productImages.large ?? [];
    const selectedSpaceId = selectedSpace - 1;

    const woodDecors = useMemo(() => {
        const woodDecors = [];

        if (doorsFillsProducts) {
            Object.keys(doorsFillsProducts).forEach(function (productType) {
                const doorsFillsProductsList = doorsFillsProducts[productType];

                if (productType === 'dtd') {
                    Object.keys(doorsFillsProductsList).forEach(function (sectionId) {
                        const section = doorsFillsProductsList[sectionId];

                        Object.keys(section.types).forEach(function (typeName) {
                            if (section.types[typeName] && Object.values(section.types[typeName].product).length) {
                                section.types[typeName].product.map(product => {
                                    if (doorFillsIds.includes(product.id.toString())) {
                                        woodDecors.push({type: 'dtd', data: product});
                                    }

                                    return true;
                                })
                            }
                        })
                    });
                } else {
                    doorsFillsProductsList.map(product => {
                        if (doorFillsIds.includes(product.id.toString())) {
                            woodDecors.push({type: 'dtd', data: product});
                        }

                        return true;
                    })
                }
            });
        }

        return woodDecors;
    }, [doorFillsIds, doorsFillsProducts]);

    if (contextRef) {
        return (
            <>
                <Sticky context={contextRef} offset={50}>
                    <div className={classes.root}>
                        <Paper>
                            <DoorStepper
                                selectedSpaceId={selectedSpaceId}
                                handleNext={handleNext}
                                handleBack={handleBack}
                                showDoorsFill={true}
                            />
                            <div className={classes.buttons}>
                                <GridList className={classes.gridList} cols={2.5}>
                                    {woodDecors.map(woodDecor => (
                                        <FillImgCard type={woodDecor.type} woodDecor={woodDecor.data}
                                                     productImages={productImagesLarge}/>
                                    ))}
                                </GridList>
                            </div>
                        </Paper>
                    </div>
                </Sticky>
            </>
        );
    } else {
        return (
            <div className={classes.root}>
                <Paper>
                    <DoorStepper
                        selectedSpaceId={selectedSpaceId}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        showDoorsFill={true}
                    />
                    <div className={classes.buttons}>
                        <GridList className={classes.gridList} cols={2.5}>
                            {woodDecors.map(woodDecor => (
                                <FillImgCard type={woodDecor.type} woodDecor={woodDecor.data}
                                             productImages={productImagesLarge}/>
                            ))}
                        </GridList>
                    </div>
                </Paper>
            </div>
        );
    }
};

export default SelectDoorsFill;