import React, {useMemo} from "react";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import TopMenu from "../Menu/TopMenu";
import SelectProfilesMenu from "../Menu/SelectProfiles";
import {useStore} from "../../../store";
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {Lang} from "../../../Utils";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";

const useStyles = makeStyles((theme) => ({
    boxMain: {
        backgroundColor: '#ffffff',
    },
}));

/**
 * @returns {*}
 * @constructor
 */
const SelectProfiles = ({fullScreenHandle, onClickSteps}) => {
    const classes = useStyles();
    const mainSize = useStore(state => state.mainSize);
    const objType = useStore(state => state.objType);

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
    }

    return (
        <>
            <TopMenu mainStep="selectDoors"/>
            <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
            <Paper className={classes.boxMain}>
                <SelectProfilesMenu mainSize={mainSize} onClickSteps={onClickSteps}/>
            </Paper>
            <VerticalSidebar onClickSteps={onClickSteps}/>
        </>
    );
};

export default SelectProfiles;