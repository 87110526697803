
export const BAIL_WIDTH = 7.5;

export const BOTTOM_STRIP_HEIGHT = 4;
export const STRIP_HEIGHT = 2;

export const TYPES = {
    '1': {
        color: 'silver'
    }
};

