import React, {useCallback} from "react";
import {Lang} from "../../../Utils";
import {makeStyles, Grid, Box, Button} from '@material-ui/core';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SelectColor from "./SelectColor";
import {useStore} from "../../../store";
import {withStyles} from '@material-ui/core/styles';
import {useSteps} from "../../../Stores/Steps";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        backgroundColor: "#000000",
    },
    mainGridCenter: {
        textAlign: 'center',
    },
    mainGridRight: {
        textAlign: 'right',
    },
    activateFullScreenBtn: {
        color: theme.palette.common.white,
    },
    switchLabel: {
        color: theme.palette.common.white,
    },
    button: {
        fontFamily: 'Poppins',
        fontSize: 12,
        color: theme.palette.common.white,
        '&$disabled': {
            color: theme.palette.grey[50],
        },
    },
    colorDiv: {
        width: 20,
        height: 20,
        marginLeft: 10,
    },
    leftGridCenter: {
        marginLeft: 5,
        paddingTop: 1.5,
        color: theme.palette.common.white,
    },
    leftBoxCenter: {
        paddingLeft: 5,
        color: theme.palette.common.white,
    },
    disabled: {
        color: theme.palette.grey[500],
    },
}));


const GreenCheckbox = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: 5,
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main,
        },
        '&$disabled': {
            color: theme.palette.primary.disabled,
        },
    },
    checked: {},
    label: {
        color: theme.palette.primary.main,
        '&$disabled': {
            // color: theme.palette.common.white,
        }
    },
    'disabled': {
        //color: theme.palette.common.white,
    }
}))((props) => <Checkbox color="default" {...props} />);

function SelectDoorVisible() {
    const classes = useStyles();
    const objType = useStore(state => state.objType);
    const actualStep = useSteps(state => state.actualStep);
    const getDoorVisibility = useStore(state => state.getDoorVisibility);
    const setDoorVisibility = useStore(state => state.setDoorVisibility);
    const selectedDoorsSpace = useStore(state => state.selectedDoorsSpace);
    const isDoorSetVisible = useStore(state => state.isDoorSetVisible);
    const doorVisible = getDoorVisibility(actualStep);

    let isDisabled = doorVisible.canBeChanged;

    if (!Object.keys(selectedDoorsSpace).length) {
        isDisabled = true;
    }

    const handleChange = useCallback(
        (event, iVisible) => {
            if (!isDisabled) {
                setDoorVisibility(!iVisible);
            }
        },
        [setDoorVisibility, isDisabled]
    );

    const isDimensionsSetVisible = useStore(state => state.isDimensionsSetVisible);
    const getDimensionsVisibility = useStore(state => state.getDimensionsVisibility);
    const setDimensionsVisibility = useStore(state => state.setDimensionsVisibility);

    const dimensionsVisible = getDimensionsVisibility(actualStep, isDimensionsSetVisible);
    let isDimensionsDisabled = dimensionsVisible.canBeChanged;

    const onClickVisibleDimensions = useCallback(
        (e, visible) => {
            e.stopPropagation();

            if (!isDimensionsDisabled) {
                setDimensionsVisibility(visible);
            }
        },
        [setDimensionsVisibility, isDimensionsDisabled]
    );

    return (
        <Box className={classes.leftBoxCenter} key={isDoorSetVisible ? '0' : '1'}>
            <Grid component="label" container alignItems="center" spacing={1} className={classes.leftGridCenter}>
                {objType === 'vd' && (
                    <Grid item>
                        <FormControlLabel
                            classes={{
                                label: classes.button,
                                disabled: classes.disabled,
                            }}
                            disabled={isDisabled}
                            control={
                                <GreenCheckbox
                                    checked={doorVisible.iVisible}
                                    onChange={e => handleChange(e, doorVisible.iVisible)}
                                    name="checkedC"
                                    color="primary"
                                    icon={<VisibilityOff/>}
                                    checkedIcon={<Visibility/>}
                                />
                            }
                            label={Lang("Doors")}
                        />
                    </Grid>
                )}
                <Grid item>
                    <FormControlLabel
                        classes={{
                            label: classes.button,
                            disabled: classes.disabled,
                        }}
                        disabled={isDimensionsDisabled}
                        control={
                            <GreenCheckbox
                                checked={dimensionsVisible.iVisible}
                                onChange={e => onClickVisibleDimensions(e, !isDimensionsSetVisible)}
                                name="checkedD"
                                color="primary"

                                icon={<VisibilityOff/>}
                                checkedIcon={<Visibility/>}
                            />
                        }
                        label={Lang("Dimensions")}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

/*
<Typography component="div">
<Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{Lang(doorVisible.iVisible ? 'ShowDoor' : 'HideDoor')}</Grid>
                    <Grid item>
                        <PurpleSwitch checked={doorVisible.iVisible} onChange={handleChange} name="checkedC" disabled={isDisabled}/>
                    </Grid>
                </Grid>
                </Typography>
 */

/**
 * @param fullScreenHandle
 * @returns {JSX.Element}
 * @constructor
 */
const MiddleMenu = ({fullScreenHandle}) => {
    const classes = useStyles();
    const wallColor = useStore(state => state.wallColor);
    const setWallColor = useStore(state => state.setWallColor);

    return (
        <Box className={classes.mainBox}>
            <Grid container spacing={3}>
                <Grid item xs>
                    <SelectDoorVisible/>
                </Grid>
                {!isMobile && (
                    <Grid item xs={6} className={classes.mainGridCenter}>
                        <Button
                            className={classes.activateFullScreenBtn}
                            onClick={fullScreenHandle.enter}
                            startIcon={<AspectRatioIcon/>}
                        >
                            {Lang("ActivateFullScreen")}
                        </Button>
                    </Grid>
                )}
                <Grid item xs className={classes.mainGridRight}>
                    <SelectColor setWallColor={setWallColor} classes={classes} wallColor={wallColor ?? '#ffffff'}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MiddleMenu;