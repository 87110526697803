import React, {useCallback} from "react";
import {useStore} from "../../../store";
import {getSpacesCounts, getSpaceWidth} from "../../../Space";
import {Badge, Button, Typography} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import Door from "../Components/Molecules/Door";
import Space from "../Components/Molecules/Space";
import {makeStyles} from "@material-ui/core/styles";
import {Lang} from "../../../Utils";
import {getType as getRoomType} from "../../../utils/room";

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        border: 5,
        marginBottom: 10,
    },
    test: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    background: {
        backgroundColor: '#F5F5F5',
    },
}));

/**
 * @param count
 * @param isSelected
 * @returns {*}
 * @constructor
 */
const SpaceCountButton = ({size, count, isSelected, objType, roomType}) => {
    const classes = useStyles();
    const width = parseInt(useStore(state => state.width));
    const smallerWidth = width / 1.2;
    const height = parseInt(useStore(state => state.height)) / 1.5;
    const saveSpacesCount = useStore(state => state.saveSpacesCount);
    const setPriceVersion = useStore(state => state.setPriceVersion);

    const onClickSelectSpace = useCallback(
        (e, value) => {
            e.stopPropagation(); // stop it at the first intersection
            saveSpacesCount(value);
            setPriceVersion();
        },
        [saveSpacesCount, setPriceVersion]
    );

    const doors = [];

    for (let i = 1; i <= count; i++) {
        doors.push({
            seq: i,
            id: i,
        });
    }

    const doorHeight = height;
    const lineWidth = (doorHeight / 100) * 2;
    const doorWidth = (smallerWidth - (lineWidth * (count + 2))) / count;
    const spaceWidth = Math.floor(getSpaceWidth(objType, width, count, roomType, 'corpuse') * 10) / 10;
    const spaceInfo = Lang('SpaceWidthDoorInfo').replace("{0}", spaceWidth);

    return (
        <Badge
            invisible={!isSelected}
            color="primary"
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={<CheckIcon fontSize="small"/>}
        >
            <Button onClick={e => onClickSelectSpace(e, count)}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    key={count + 'seq'}
                >
                    <Grid item>
                        <div className={classes.main}>
                            <div className={classes.test}>
                                {doors.map((door) => (
                                    <>
                                        {objType === 'v' && (
                                            <Space
                                                count={1}
                                                width={doorWidth}
                                                height={doorHeight}
                                                isLast={door.seq === count}
                                                classes={classes}
                                            />
                                        )}
                                        {objType !== 'v' && (
                                            <Door
                                                count={1}
                                                width={doorWidth}
                                                height={doorHeight}
                                            />
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">
                            {spaceInfo}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
        </Badge>
    );
};

const SelectSpacesCount = () => {
        const mainSize = useStore(state => state.mainSize);
        const spacesCountValue = useStore(state => state.spacesCount);
        const selectedRoomType = useStore(state => state.roomType);
        const width = useStore(state => state.width);
        const objType = useStore(state => state.objType);

        const roomType = getRoomType(selectedRoomType, objType);
        const spacesCounts = getSpacesCounts(width, roomType, objType);
        const spaces = [];

        spacesCounts.forEach(function (element) {
            const isSelected = element === spacesCountValue;

            spaces.push({count: element, isSelected: isSelected});
        });

        return (
            <Grid
                container
                justify="center"
                alignItems="center"
            >
                {spaces.map(space => (
                    <Grid key={space.count}>
                        <SpaceCountButton
                            size={mainSize}
                            count={space.count}
                            isSelected={space.isSelected}
                            objType={objType}
                            roomType={roomType}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
;

export default SelectSpacesCount;
