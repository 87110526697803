import {BOARD_DEPTH} from "./config";
import {getType as getRoomType} from "./utils/room";

export function getSpaceWidth(objType, roomWidth, spacesCount, roomType, forType, isDivided = false) {
    let woodStandCount = spacesCount - 1;

    if (roomType === "a") {
        roomWidth = roomWidth - 1;
    }

    if (forType === 'door') {
        return ((roomWidth - (BOARD_DEPTH * 2)) / spacesCount) + BOARD_DEPTH * 2;
    } else {

        if (objType === 'v') {
            roomWidth -= BOARD_DEPTH * 2;
        } else {
            if (roomType === "a") {
                woodStandCount += 2;
            }

            if (roomType === "b" || roomType === "c") {
                woodStandCount += 3;
            } else if (!roomType || roomType === "d") {
                woodStandCount += 4;
            }
        }

        let spaceWidth = (roomWidth - BOARD_DEPTH * woodStandCount) / spacesCount;

        if (isDivided) {
            spaceWidth = spaceWidth - BOARD_DEPTH / 2;
        }

        return spaceWidth;
    }
}

export function getSpacesCounts(roomWidth, roomType, objType) {
    const spaces = [];
    const selectedType = getRoomType(roomType, objType);
    let actualSpaceWidth = 0;
    let numSpace = (('v' === objType && roomWidth <= 100) ? 1 : 2);

    do {
        let maxWidth = 120
        actualSpaceWidth = getSpaceWidth(selectedType, roomWidth, numSpace, roomType);

        if (selectedType === 'v') {
            maxWidth = 100
        }

        if (actualSpaceWidth <= maxWidth) spaces.push(numSpace);

        numSpace++;
    } while (50 <= actualSpaceWidth);

    return spaces;
}