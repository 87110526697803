import React, {useCallback, useEffect, useState} from "react";
import {useStore} from "../../../store";
import {useSteps} from "../../../Stores/Steps";
import {makeStyles, Modal, Box, Grid, Button, TextField, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Lang} from "../../../Utils";
import Alert from '@material-ui/lab/Alert';
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import NumberFormat from 'react-number-format';
import {isEmailAddressValid} from "../../../utils/control";
import {apiOrder} from "../../../Stores/Order";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainModalBox: {
        paddingBottom: 20,
    },
    mainGridCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainGridRight: {
        textAlign: 'right',
    },
    buttonActiveText: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    buttonActiveDiv: {
        lineHeight: 0,
    },
    buttonActiveCaption: {
        margin: 0,
        padding: 0,
        fontSize: 12,
    },
    previewButtonActive: {
        textTransform: 'none',
    },
    nextButtonActive: {
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
}));

/**
 * @returns {*}
 * @constructor
 */
const Process = ({inProgress, result, showText=true}) => {
    const classes = useStyles();
    const isModalOpen = useSteps(state => state.isModalOpen['process']);
    const closeModal = useSteps(state => state.closeModal);

    const handleClose = () => {
        closeModal('process');
    };

    let labelKey = 'OrderWasNotSuccessfullySaved';

    if (result && result.success === "true") {
        labelKey = 'OrderWasSuccessfullySaved';
    }

    if (showText) {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                disableEnforceFocus
                disableBackdropClick
            >
                <>
                    {inProgress && (
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                    {!inProgress && (
                        <div className={classes.paper}>
                            {labelKey && <Box className={classes.mainModalBox}><Typography>{Lang(labelKey)}</Typography></Box>}
                            <Grid container spacing={3}>
                                <Grid item xs className={classes.mainGridCenter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClose}
                                        className={classes.nextButtonActive}
                                    >
                                        <Typography className={classes.buttonActiveText}>
                                            {Lang("Close")}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </>
            </Modal>
        );
    } else {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isModalOpen}
                onClose={handleClose}
                closeAfterTransition
                disableEnforceFocus
                disableBackdropClick
            >
                <>
                    {inProgress && (
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                </>
            </Modal>
        );
    }
};

export default Process;