export const INFO_LANG = {
    lbl_edit_width: 'Zadání šířky skříně',
    lbl_edit_width_info: 'Šířka Vaší vestavěné skříně nesmí být menší než 90 cm. Je zapotřebí zadávat vnější rozměry vestavěné skříně, protože vnitřní zástavba bude menší o tloušťku dřevotřísky. Pokud máte vestavěnou skříň umístěnou ve výklenku (půdorys typu A), doporučujeme od naměřené šířky odečíst 3-4 mm. Po vyplnění všech údajů pokračujte dále kliknutím na tlačítko Pokračovat.',
    lbl_edit_height: 'Zadání výšky skříně',
    lbl_edit_height_info: 'Vzhledem k nerovnosti stěn, resp. stropů, doporučujeme od naměřené výšky odečíst 3-4 mm, aby nedocházelo k problémům při montáži vestavěné skříně. Při Vámi zadaných rozdílných rozměrech budou bočnice nařezané na nejnižší zadanou výšku, proto je opravdu důležité si přeměřit, zda je výška akceptovatelná v celé hloubce vestavěné skříně. Maximální výška bez dělení dveří povolená je 270 cm. Po vyplnění všech údajů pokračujte dále kliknutím na tlačítko Pokračovat.',
    lbl_edit_depth: 'Zadání hloubky skříně',
    lbl_edit_depth_info: 'Hloubkou vestavěné skříně se rozumí hloubka celé sestavy i s posuvným systémem, což znamená, že vnitřek vestavěné skříně je o 10 cm menší. Z toho důvodu je námi doporučená hloubka vestavěné skříně 70 cm (vnitřek bude představovat 60 cm). Po vyplnění všech údajů pokračujte dále kliknutím na tlačítko Pokračovat.',
    selectProfilesTitle: 'VÝBĚR TYPU A BARVY PROFILU',
    selectProfilesContentVD: 'Profilem se rozumí rám dveří. Vyberte si z naší pestré nabídky profilů. Můžete si vybrat hliníkový rám otevřený, zavřený nebo Minimax. Všechny dveře jakož i horní a spodní kolejnice se orámují zvoleným typem rámu. Cena profilů je stejná.<br><br>Otevřený profil - Nejčastěji prodávaný profil pro vestavěné skříně. Madlo má tvar písmene C pro uchycení, což mu zaručuje snadnou manipulaci.<br><br>Zavřený profil - Tento profil má tvar písmene S a je nejvhodnější pro posuvné dveře na předělení místnosti nakolik má oboustranný tvar.<br><br>1.&nbsp;&nbsp; 1. V uvedené barvě, kterou si vyberete, budete mít horní i dolní kolejnici.<br>2.&nbsp;&nbsp; 2.	Pokud máte posuvné dveře předělené na více částí, pak dělící lišty budou ve stejné barvě.<br><span class="text_description">3.&nbsp;&nbsp; Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".</span>',
    selectProfilesContentD: 'Profilem se rozumí rám dveří. Vyberte si z naší pestré nabídky profilů. Můžete si vybrat hliníkový rám otevřený, zavřený nebo Minimax. Všechny dveře jakož i horní a spodní kolejnice se orámují zvoleným typem rámu. Cena profilů je stejná.<br><br>Otevřený profil - Nejčastěji prodávaný profil pro vestavěné skříně. Madlo má tvar písmene C pro uchycení, což mu zaručuje snadnou manipulaci.<br><br>Zavřený profil - Tento profil má tvar písmene S a je nejvhodnější pro posuvné dveře na předělení místnosti nakolik má oboustranný tvar.<br><br>1.&nbsp;&nbsp; 1.	V uvedené barvě, kterou si vyberete, budete mít horní i dolní kolejnici.<br>2.&nbsp;&nbsp; 2.	Pokud máte posuvné dveře předělené na více částí, pak dělící lišty budou ve stejné barvě.<br><span class="text_description">3.&nbsp;&nbsp; Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".</span>',
    roomTypeContentVD: "Vyberte typ půdorysu (typ A, B, C nebo D) vzhledem k tomu, kde má být Vaše vestavěná skříň umístěna",
    roomTypeContentV: "Vyberte typ půdorysu (typ A, B, C nebo D) vzhledem k tomu, kde má být Vaše vestavěná skříň umístěna",
    roomTypeContentD: "Vyberte typ půdorysu vzhledem k tomu, kde májí být dveře umístěny.",

    roomTypeInfoContentA: "Skříň bude od levé stěny po pravou.<br><br>délka vnitřní zástavby bude - 1cm",
    roomTypeInfoContentB: "Skříň bude od levé stěny po bočnici",
    roomTypeInfoContentC: "Skříň bude od pravé stěny po bočnici",
    roomTypeInfoContentD: "Skříň bude mít levý a pravý bok",

    selectDoorSpacesCountTitle: "VÝBĚR TYPU DVEŘÍ",
    selectDoorSpacesCountContentVD: 'V tomto kroku si vyberte pro každý prostor typ posuvných dveří. Na výběr jsou plné dveře, ale i dveře dělené (na polovinu, na třetiny, dokonce i na menší části). Prostor, pro který se aktuálně definuje typ, je označený červenou barvou.<br><br>TIP: U dveří dělených na polovinu, třetinu resp. na pětinu si můžete libovolně změnit výšku dělícího prostoru.<br><br>V následujících krocích budete mít možnost vybrat si barevné provedení dveří. <span class="text_description">Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".</span>',
    selectDoorSpacesCountContentD: 'V tomto kroku si vyberte pro každý prostor typ posuvných dveří. Na výběr jsou plné dveře, ale i dveře dělené (na polovinu, na třetiny, dokonce i na menší části). Prostor, pro který se aktuálně definuje typ, je označený červenou barvou.<br><br>TIP: U dveří dělených na polovinu, třetinu resp. na pětinu si můžete libovolně změnit výšku dělícího prostoru.<br><br>V následujících krocích budete mít možnost vybrat si barevné provedení dveří. <span class="text_description">Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".</span>',

    selectDTDContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    basicSettingsContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    selectCorpusesContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

    setWardrobeParametersWidthTitle: 'ZADÁNÍ ŠÍŘKY SKŘÍNĚ',
    setWardrobeParametersWidthContentVD: '1. Šířka vestavěné skříně nesmí být menší než 90 cm.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;-	V šířce vestavěné skříně zadejte přesný rozměr prostoru.<br>\n' +
        '2. Půdorys Typu „A“ zadejte přesně změřený rozměr šířky výklenku od stěny ke stěně.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp; -	Tento půdorys vnitřku Vaší vestavěné skříně systém automaticky přepočítá a zmenší o 1 až 1,5 cm, aby se vnitřek skříně vešel mezi dvě pevné stěny výklenku. V tomto případě budou mít posuvné dveře přesný rozměr od stěny ke stěně výklenku.<br>\n' +
        '3. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
    setWardrobeParametersWidthContentD: "Šířka výklenku nesmí být menší než 90 cm. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.",
    setWardrobeParametersWidthContentV: '1. Šířka vestavěné skříně nesmí být menší než 90 cm.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;-	V šířce vestavěné skříně zadejte přesný rozměr prostoru.<br>\n' +
        '2. Půdorys Typu „A“ zadejte přesně změřený rozměr šířky výklenku od stěny ke stěně.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp; -	Tento půdorys vnitřku Vaší vestavěné skříně systém automaticky přepočítá a zmenší o 1 až 1,5 cm, aby se vnitřek skříně vešel mezi dvě pevné stěny výklenku. V tomto případě budou mít posuvné dveře přesný rozměr od stěny ke stěně výklenku.<br>\n' +
        '3. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',

    setWardrobeParametersHeightTitle: 'ZADÁNÍ VÝŠKY SKŘÍNĚ',
    setWardrobeParametersHeightTitleV: 'Zadání výšky výklenku',

    setWardrobeParametersHeightContentVD: '1. Výšku vestavěné skříně je důležité změřit  (od podlahy po strop) v bodech, kde skříň začíná a také kde skříň končí (levá a pravá výška).<br>\n' +
        '2. Výšku skříně na levé a pravé straně nemusíte mít stejnou. Je nutné uvést přesné rozměry naměřených výšek.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;-	Posuvné dveře mají regulaci na výšku max. do 1,5 cm.<br>\n' +
        '3. V případě větších výškových rozdílů, uveďte menší naměřenou výšku a do poznámky v objednávce uveďte přesnou výšku levé a pravé strany skříně.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;-	Při konzultaci objednávky Vám upravíme správné rozměry.<br>\n' +
        '4.  Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
    setWardrobeParametersHeightContentD: 'Vzhledem k nerovnost stropů doporučujeme přeměřit výšku výklenku v místě kde se bude montovat přední posuvný systém. Při Vámi zadaných rozdílných rozměrů výšky se dveře budou vyrábět z nižší naměřené výšky. Horní kolejnici je nutné vypodkladať na nejnižší naměřenou výšku výklenku. Rozdíl výšek však nesmí být větší než 1,5 cm. V případě že je rozdíl větší, tak je nutné horní kolejnici vypodkladať a do systému zapsat novou výšku.Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
    setWardrobeParametersHeightContentV: "Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.",

    setWardrobeParametersDepthTitle: 'ZADÁNÍ HLOUBKY SKŘÍNĚ',
    setWardrobeParametersDepthContentVD: '1. Hloubka skříně se rozumí celková hloubka vestavěné skříně.<br>\n' +
        '2. Například, když je celková hloubka vestavěné skříně 60 cm, tak 10 cm zabírá posuvný systém a vnitřek skříně bude mít pouze 50 cm.<br>\n' +
        '3. Vnitřek vestavěné skříně má vždy o 10 cm menší hloubku.<br>\n' +
        '4. Posuvný systém zabírá 10 cm<br>\n' +
        '5. Doporučená celková hloubka skříně je 60-70 cm.<br>\n' +
        '6. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
    setWardrobeParametersDepthContentV: "Doporučená celková hloubka skříně je 60 cm. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.",
    setWardrobeParametersDepthContentD: "Doporučená celková hloubka skříně je 60 cm. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.",

    mainSelectDTDVDTitle: 'Barva DTD vnitřku',
    mainSelectDTDVDContent: 'V následujícím kroku si můžete vybrat barvu vnitřní zástavby své vestavěné skříně. Kliknutím si vyberte DTD z pestré palety barev. Kliknutím na kterýkoliv obrázek dřevodekoru si vyberete barvu podle svého přání z příslušného seznamu. Vzhledem k Vámi zvolené barvě dřevodekoru se přepočítává cena Vaší vestavěné skříně. Opětovným kliknutím na jiný obrázek dřevodekoru máte možnost změnit původně vybranou barvu. Vnitřek vestavěné skříně doporučujeme zhotovit z bílé hladké dřevotřísky (08681 - Bílá). Je to cenově výhodné a vzhledem k tomu, že vnitřní zástavba je zakrytá posuvnými dveřmi, i designově postačující.',

    mainSelectDTDVTitle: 'Barva DTD vnitřku',
    mainSelectDTDVContent: 'V následujícím kroku si můžete vybrat barvu vnitřní zástavby své vestavěné skříně. Kliknutím si vyberte DTD z pestré palety barev. Kliknutím na kterýkoliv obrázek dřevodekoru si vyberete barvu podle svého přání z příslušného seznamu. Vzhledem k Vámi zvolené barvě dřevodekoru se přepočítává cena Vaší vestavěné skříně. Opětovným kliknutím na jiný obrázek dřevodekoru máte možnost změnit původně vybranou barvu. Vnitřek vestavěné skříně doporučujeme zhotovit z bílé hladké dřevotřísky (08681 - Bílá). Je to cenově výhodné a vzhledem k tomu, že vnitřní zástavba je zakrytá posuvnými dveřmi, i designově postačující.',

    secondSelectDTDVDTitleA: "Dřevodekor dorazové lišty",
    secondSelectDTDVDTitleB: "Dřevodekor dorazové lišty a bočnice",
    secondSelectDTDVDTitleC: "Dřevodekor bočnice a dorazové lišty",
    secondSelectDTDVDTitleD: "Dřevodekor bočnice",

    secondSelectDTDVDContent: 'Podobně jako při předcházejícím kroku si ze seznamu barev dřevotřísky od více výrobců vyberte barvu dorazové lišty, která bude tvořit viditelnou část Vaší vestavěné skříně. Hranovací páska dorazové lišty bude v barvě dorazové lišty.',

    mainSelectDTDDTitleA: "Dřevodekor dorazové lišty",
    mainSelectDTDDTitleB: "Dřevodekor dorazové lišty a bočnice",
    mainSelectDTDDTitleC: "Dřevodekor bočnice a dorazové lišty",
    mainSelectDTDDTitleD: "Drevodekor bočnice",
    mainSelectDTDDContent: 'V následujícím kroku si můžete vybrat barvu vnější zástavby. Kliknutím na jakýkoliv obrázek dřevodekoru si vyberete barvu dle Vašeho přání z příslušného seznamu. Vzhledem na vámi zvolenou barvu dřevodekoru se přepočítává cena vaší vestavěné skříně. Opětovným kliknutím na jiný obrázek dřevodekoru máte možnost změnit původně vybranou barvu.',

    controlAndSetHeights: 'Pro pokračování zkontrolujte prosím zadané parametry výšek',

    selectDoorsFillTitle: "VÝBĚR VÝPLNĚ DVEŘÍ",
    selectDoorsFillContentVD: '1.&nbsp;Na základě Vámi zvolené výplně se automaticky přepočítá cena, protože ceny se v jednotivých kategoriích výplně liší.'
        + '<div style="margin-left:40px;">1)&nbsp;&nbsp;Kategorie DŘEVODEKOR</div>'
        + '<div style="margin-left:40px;">2)&nbsp;&nbsp;Kategorie ZRCADLA</div>'
        + '<div style="margin-left:40px;">3)&nbsp;&nbsp;Kategorie DŘEVODEKOR – EXCLUSIVE</div>'
        + '<div style="margin-left:40px;">4)&nbsp;&nbsp;Kategorie LACOBEL – BAREVNÉ SKLA</div>'
        + '2.&nbsp;Nejlevnější jsou první dvě kategorie (Dřevodekor a Zrcadla)<br>'
        + '3.&nbsp;Kategorie 3) a 4) (Lacobel, Dřevodekor – Exclusive) jsou zase nejluxusnější výplně, které se používají na výrobu posuvných dveří.<br>'
        + '<span class="text_description">4.&nbsp;Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.</span>',
    selectDoorsFillContentD: '1.&nbsp;Na základě Vámi zvolené výplně se automaticky přepočítá cena, protože ceny se v jednotivých kategoriích výplně liší.'
        + '<div style="margin-left:40px;">1)&nbsp;&nbsp;Kategorie DŘEVODEKOR</div>'
        + '<div style="margin-left:40px;">2)&nbsp;&nbsp;Kategorie ZRCADLA</div>'
        + '<div style="margin-left:40px;">3)&nbsp;&nbsp;Kategorie DŘEVODEKOR – EXCLUSIVE</div>'
        + '<div style="margin-left:40px;">4)&nbsp;&nbsp;Kategorie LACOBEL – BAREVNÉ SKLA</div>'
        + '2.&nbsp;Nejlevnější jsou první dvě kategorie (Dřevodekor a Zrcadla)<br>'
        + '3.&nbsp;Kategorie 3) a 4) (Lacobel, Dřevodekor – Exclusive) jsou zase nejluxusnější výplně, které se používají na výrobu posuvných dveří.<br>'
        + '<span class="text_description">4.&nbsp;Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.</span>',
    selectCorpusesSetSpacesCountTitle: "Výběr počtu dveří",
    selectCorpusesSetSpacesCountTitleV: "Výběr počtu prostorů ",
    selectCorpusesSetSpacesCountContent: 'Ve spodní části stránky vyberte počet dveří vaší budoucí vestavěné skříně. Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".',
    selectCorpusesSetSpacesCountContentV: 'Ve spodní části stránky vyberte počet dveří vaší budoucí vestavěné skříně. Po vyplnění pokračujte dále, kliknutím na tlačítko "Pokračovat".',

    selectCorpusesTitle: "SKLADANIE VNÚNTRA SKRINE",
    selectCorpusesContent: '1.&nbsp;Vnitřek skříně si můžete vybrat podle námi předdefinovaných kombinací modulů nebo si můžete vytvořit vlastní uspořádání  vnitřku skříně.<br>2.&nbsp;Všechny rozměry vnitřních modulů jsou uváděné po odečtení materiálu (čisté rozměry).<br>3.&nbsp;Označením prostoru – políčko „dělený“ si můžete prostor ještě rozdělit na ½.<br>4.&nbsp;Vnitřek skříně je vyrobený ze síly materiálu 1,8 cm.<br>5.&nbsp;Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.<br><br>LIMIT: Zásuvky se dají použít pouze v prostoru od 45 cm<br><br>POSTUP: Označením (vyznačí se zelenou barvou) jednotlivého prostoru si můžete vybrat z následujícího seznamu vnitřek vestavěné skříně. Stejně se postupuje i v případě všech dalších prostorů. Opětovným označením kteréhokoliv prostoru máte možnost udělat změnu podle svých potřeb. Pokud si přejete rozdělit prostor klikněte na "Rozdělit prostor" a následně si zkombinujte Vaši vestavěnou skříň.',

    basicInformationTitle: 'ZÁKLADNÍ INFORMACE',
    basicInformationContent: '1. Před objednáním vestavěné skříně je důležité si prohlédnout VIDENÁVOD-MONTÁŽ VESTAVĚNÉ SKŘÍNĚ (https://levne-skrine.cz/videonavody/)), kde najdete přesný postup montáže vestavěné skříně.<br>'
        + '2. Jednotlivé díly skříně jsou předvrtané dle Vámi vybraných modulů a připraveny na samotnou montáž.<br>'
        + '3. Posuvné dveře Vám budou doručeny ve finální podobě.',

    setOrderTitle: 'ZADÁNÍ OBJEDNÁVKY – POSTUP',
    setOrderContent: '1. Jakmile si vytvoříte objednávku na vestavěnou skříň, tak Vám bude doručen potvrzující e-mail, který obsahuje přesnou cenu a nákres Vaší vestavěné skříně.<br>'
        + '2. Na základě Vaší objednávky Vás bude nejprve kontaktovat technický pracovník, který si s Vámi potvrdí všechny technické údaje, kterými jsou šířka, hloubka, výška a zvolené použité materiály.<br>'
        + '3. Do výroby Vaší objednávku na vestavěnou skříň předáváme až po telefonické konzultaci objednávky.',

    deliveryAndDispatchTitle: 'DODÁNÍ A EXPEDICE VESTAVĚNÉ SKŘÍNĚ – POSTUP',
    deliveryAndDispatchContent: '1. Po konzultaci a odsouhlasení  Vaší objednávky vestavěné skříně, trvá výroba cca 10 pracovních dní.'
        + '2. 3-4 dny před dodáním objednávky Vás bude kontaktovat náš pracovník z expedice, který Vám oznámí den a předpokládaný čas doručení Vaší vestavěné skříně.<br>'
        + '3. Řidič Vás bude kontaktovat 1 den před  dovezením skříně a pak ještě 1 hodinu před samotným doručením, aby jste měli dostatek času se dostavit na místo doručení skříně.<br>'
        + '4. Výnos vestavěné skříně si zajistí každý zákazník sám.<br>'
        + '-  Z tohotu důvodu Vás kontaktujeme 3-4 dny předem, aby jste si mohli zajistit výnos Vaší objednávky.<br>'
        + '5. Přeprava je zabezpečená našimi dodávkami, nezasíláme přepravními společnostmi.',
    selectDTDInTitle: 'VÝBĚR BARVY DEKORU PRO VNITŘEK VESTAVĚNÉ SKŘÍNĚ',
    selectDTDInContent: '1.	Vnitřek vestavěné skříně doporučujeme zhotovit v barvě „08681 Briliantově bílá“<br>' +
        ' - Je to matná sněhově bílá barva s jemnou povrchovou strukturou lamina.<br>' +
        ' -	Je cenově výhodná a protože je vnitřek skříně zakrytý posuvnými dveřmi, tak je designově dostačující.<br>' +
        '2.	Kliknutím na jakýkoliv obrázek dekoru Vám systém automticky přepočítá cenu skříně ve Vámi vybraném dekoru DTD. Cena jednotlivých dekorů se liší.<br>' +
        '3.	Síla materiálu  vnitřku skříně  je 1,8 cm.<br>' +
        '4. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
    selectDTDOutTitle: 'VÝBĚR BARVY DEKORU PRO VIDITELNÉ ČÁSTI VESTAVNÉ SKŘÍNĚ ',
    selectDTDOutContent: '1. Podobně jako při předchozím kroku si vyberte ze seznamu barev dřevotřísky od více výrobců barvu dorazové lišty, která bude tvořit viditelnou část Vaší vestavěné skříně.<br>' +
        '2.	Kliknutím na jakýkoliv obrázek dekoru Vám systém automticky přepočítá cenu skříně ve Vámi vybraném dekoru DTD. Cena jednotlivých dekorů se liší.<br>' +
        '3.	Síla materiálu  vnitřku skříně  je 1,8 cm.<br>' +
        '4. Po vyplnění údajů pokračujte dále, kliknutím na tlačítko „Pokračovat“.',
};
