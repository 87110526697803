import React, {useCallback} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Lang} from "../../Utils";
import SelectCorpusesSetSpacesCount from "./Steps/SelectCorpusesSetSpacesCount";
import Order from "./Steps/Order";
import SetWardrobeParameters from "./Steps/SetWardrobeParameters";
import RoomType from "./Steps/RoomType";
import SelectDoorsFill from "./Steps/SelectDoorsFill";
import SelectProfiles from "./Steps/SelectProfiles";
import SelectDoorSpacesCount from "./Steps/SelectDoorSpacesCount";
import SelectDTDStep from "./Steps/SelectDTD";
import SelectCorpuses from "./Steps/SelectCorpuses";
import SelectDTDIn from "./Steps/SelectDTDIn";
import SelectDTDOut from "./Steps/SelectDTDOut";
import LoadStep from "./Steps/LoadStep";
import Accessories from "./Steps/Accessories";
import PriceCalculation from "./Steps/PriceCalculation";

export default function Steps({onClickSteps, fullScreenHandle, hideMiddleMenu = false}) {

    return (
        <div style={{paddingBottom: 50}}>
            <Switch>
                <Route path="/load/:objId/:APIUUId">
                    <LoadStep fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path="/dev">
                    <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} isDevSet={true}/>
                </Route>
                <Route path={'/' + Lang('selectCorpusesSetSpacesCount', 'navigation')}>
                    <SelectCorpusesSetSpacesCount fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('selectCorpuses', 'navigation')}>
                    <SelectCorpuses fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}
                                    hideMiddleMenu={hideMiddleMenu}/>
                </Route>
                <Route path={'/' + Lang('selectDTD', 'navigation')}>
                    <SelectDTDStep fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('selectDTDIn', 'navigation')}>
                    <SelectDTDIn fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('selectDTDOut', 'navigation')}>
                    <SelectDTDOut fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('selectDoorSpacesCount', 'navigation')}>
                    <SelectDoorSpacesCount fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}
                                           hideMiddleMenu={hideMiddleMenu}/>
                </Route>
                <Route path={'/' + Lang('selectProfiles', 'navigation')}>
                    <SelectProfiles fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('selectDoorsFill', 'navigation')}>
                    <SelectDoorsFill fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}
                                     hideMiddleMenu={hideMiddleMenu}/>
                </Route>
                <Route path={'/' + Lang('selectDoorsFill', 'navigation') + '/:doorFillId'}>
                    <SelectDoorsFill fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}
                                     hideMiddleMenu={hideMiddleMenu}/>
                </Route>
                <Route path={'/' + Lang('roomType', 'navigation')}>
                    <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route exact path={'/'}>
                    <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('setWardrobeParameters', 'navigation')}>
                    <SetWardrobeParameters fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('accessories', 'navigation')}>
                    <Accessories fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('priceCalculation', 'navigation')}>
                    <PriceCalculation fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
                <Route path={'/' + Lang('order', 'navigation')}>
                    <Order fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
                </Route>
            </Switch>
        </div>
    );
}