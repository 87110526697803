import {LANG as SK_LANG} from "./sk/lang";
import {LANG as CZ_LANG} from "./cz/lang";

export default function get(label, type) {
    let LANG = SK_LANG;

    if (process.env.REACT_APP_WEBSITE_LANG === 'cz') {
        LANG = CZ_LANG;
    }

    if (type && LANG[type][label]) {
        label = LANG[type][label];
    } else if (LANG[label]) {
        label = LANG[label];
    }

    return label;
}
