export const NAVIGATION_LANG = {
    basicSettings: "zakladniNastaveni",
    selectCorpusesSetSpacesCount: "vyberPoctuDveri",
    selectCorpuses: "skladaniVnitra",
    selectDTD: "vyberDrevodekor",
    selectDoorSpacesCount: "vyberPoctuProstoru",
    selectProfiles: "vyberProfilu",
    selectDoorsFill: "vyberVyplneDveri",
    roomType: "vyberTypuPudorysu",
    setWardrobeParameters: "zadavaniParametru",
    accessories: "doplnky",
    priceCalculation: "cenovaKalkulace",
    order: "objednani",
    selectDTDIn: 'vyberFarbyVnutra',
    selectDTDOut: 'vyberFarbyPreViditelneCasti',
}