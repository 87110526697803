import React from "react";
import Strip from "./Strip";

/**
 * @param {Number} posY
 * @param {Number} height
 * @param {Array} doorAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const BottomStrip = ({posY, height, doorAttributes}) => {
    return (
        <Strip posY={posY} height={height} doorAttributes={doorAttributes}/>
    );
};

export default BottomStrip;