import React, {useCallback} from "react";
import {useStore} from "../../../../store";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {connect} from "react-redux";
import {getDoorFillId} from '../../../../utils/door';

const mapStateToProps = state => ({
    ...state.todos
});

const mapDispatchToProps = dispatch => ({});

const useStyles = makeStyles((theme) => ({
    leftGrid: {
        paddingRight: 20,
    },
    rightGrid: {
        //paddingTop: 10,
    },
    boxMain: {
        backgroundColor: '#ffffff',
    },
    mainContent: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    leftBorder: {
        width: 10,
        background: 'linear-gradient(90deg, #6a6a6a, #cccccc 50%, #dbdbdb 100%)',
        border: '1px solid ' + theme.palette.grey[500],
    },
    rightBorder: {
        width: 10,
        background: 'linear-gradient(90deg, #dbdbdb, #cccccc 50%, #6a6a6a 100%)',
        border: '1px solid ' + theme.palette.grey[500],
    },
    mainPadding: {
        paddingTop: 20,
    },
    mainCenter: {
        width: 200,
    },
    topLine: {
        zIndex: 1,
        width: '100%',
        background: 'linear-gradient(90deg, ' + theme.palette.grey[500] + ', ' + theme.palette.grey[400] + '  5%, ' + theme.palette.grey[300] + ' 50%, ' + theme.palette.grey[400] + ' 95%, ' + theme.palette.grey[500] + ')',
        borderTop: '1px solid ' + theme.palette.grey[500],
        borderBottom: '1px solid ' + theme.palette.grey[500],
    },
    bottomLine: {
        zIndex: 1,
        width: '100%',
        background: 'linear-gradient(90deg, ' + theme.palette.grey[500] + ', ' + theme.palette.grey[400] + '  5%, ' + theme.palette.grey[300] + ' 50%, ' + theme.palette.grey[400] + ' 95%, ' + theme.palette.grey[500] + ')',
        borderTop: '1px solid ' + theme.palette.grey[500],
        borderBottom: '1px solid ' + theme.palette.grey[500],
    },
    content: {
        background: 'linear-gradient(90deg, ' + theme.palette.grey[100] + ', ' + theme.palette.grey[300] + ')',
    },
    mainGrid: {
        justifyContent: 'center',
    },
    selectedSpace: {
        width: '100%',
        height: '100%',
        zIndex: 2,
        '&:hover': {
            backgroundColor: '#80FF80',
        },
    },
}));


function getDoorFillBorderStyle(doorFillsCount, type, style, showDoorsFill, selectedDoorFillId, doorFills, selectedSpaceId, index) {
    let className = '';

    if (showDoorsFill || type === 'Bottom') {
        className = style;
    }

    const doorFillId = getDoorFillId(selectedSpaceId, doorFillsCount, index);

    if (selectedDoorFillId === doorFillId && showDoorsFill) {
        className += ' blinkBorders' + type;

        if (!doorFills[doorFillId]) {
            className += 'Empty';
        }
    }

    return className;
}

function a11yProps(onSelectSpace, onClickSelectSpace) {
    if (onSelectSpace) {
        return {
            onPointerDown: onClickSelectSpace,
        };
    } else {
        return {};
    }
}

const SpaceContent = ({productImages, showDoorsFill, selectedSpaceId, count, height, lineWidth, onSelectSpace, width = null}) => {
    const doorFills = useStore(state => state.doorFills);
    const selectedDoorFillId = useStore(state => state.selectedDoorFillId);
    const classes = useStyles();
    const spaces = [];
    const topLineHeight = lineWidth;
    const bottomLineHeight = topLineHeight * 2.5;
    const contentHeight = height - bottomLineHeight;
    const mainCenterWidth = width ?? height / 3;

    if (!count) count = 1;

    for (let num = 0; num < count; num++) {
        spaces.push({id: num, height: contentHeight / count, topLineHeight: topLineHeight});
    }

    const doorFillsCount = count;

    const onClickSelectSpace = useCallback(
        (e, doorFillId) => {
            e.stopPropagation();

            if (onSelectSpace) {
                onSelectSpace(doorFillId);
            }
        },
        [onSelectSpace]
    );

    return (
        <div className={classes.mainCenter} style={{width: mainCenterWidth}}>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {spaces.map((space, index) => (
                    <Grid item key={'contentDoor' + space.id}
                        className={getDoorFillBorderStyle(doorFillsCount, 'Bottom', classes.content, showDoorsFill, selectedDoorFillId, doorFills, selectedSpaceId, index)}
                        {...a11yProps(onSelectSpace, e => onClickSelectSpace(e, getDoorFillId(selectedSpaceId, doorFillsCount, space.id)))}
                        style={{width: mainCenterWidth}}
                    >
                        <div className={classes.topLine} style={{height: space.topLineHeight}}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
};

const LeftBorder = ({lineWidth, height}) => {
    const classes = useStyles();

    return (
        <Grid item>
            <div className={classes.leftBorder} style={{width: lineWidth, height: height}}/>
        </Grid>
    )
};

const RightBorder = ({lineWidth, height}) => {
    const classes = useStyles();

    return (
        <Grid item>
            <div className={classes.rightBorder} style={{width: lineWidth, height: height}}/>
        </Grid>
    )
};

class Space extends React.Component {
    render() {
        let paddingTop = 0;
        let height = this.props.height;

        if (this.props.isBehind) {
            const onePercent = this.props.height / 200;
            height = height - (onePercent * 2);
            paddingTop = onePercent / 2;
        }

        const lineWidth = (height / 100) * 2;

        return (
            <Grid
                wrap='nowrap'
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                style={{paddingTop: paddingTop, justifyContent: 'center'}}
                className={this.props.classes.background}
            >
                <LeftBorder lineWidth={lineWidth} height={height}/>
                <Grid item>
                    <SpaceContent
                        productImages={this.props.productImages.large}
                        showDoorsFill={this.props.showDoorsFill}
                        selectedSpaceId={this.props.selectedSpaceId}
                        count={this.props.count}
                        height={this.props.height}
                        width={this.props.width}
                        lineWidth={lineWidth}
                        onSelectSpace={this.props.onSelectSpace}
                    />
                </Grid>
                {this.props.isLast && <RightBorder lineWidth={lineWidth} height={height}/>}
            </Grid>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Space)