import React, {useCallback, useMemo} from 'react';
import {useStore} from '../../../store';
import {Lang} from '../../../Utils';
import {Badge, Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import ButtonBase from '@material-ui/core/ButtonBase';
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from "@material-ui/icons/Check";
import {getType as getRoomType} from "../../../utils/room";
import parse from 'html-react-parser';
import {v4 as uuid} from 'uuid';
import {useOrder} from "../../../Stores/Order";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        //width: 200,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    card: {
        margin: 10,

    },
    badge: {
        top: '80%',
    },
    cardHeader: {
        textAlign: "center",
        padding: 5,
    },
    cardMedia: {
        padding: 0,
    },
    cardContent: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        margin: 0,
        '&:last-child': {
            paddingBottom: 5,
        },
    },
}));

const CardRoomType = ({type, isSelected, onClickSelectType}) => {
    const classes = useStyles();
    const upperType = type.toUpperCase();

    return (
        <Badge
            invisible={!isSelected}
            color="primary"
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={<CheckIcon fontSize="small"/>}
            classes={{badge: classes.badge}}
        >
            <Card className={classes.card}>
                <CardHeader title={upperType} className={classes.cardHeader}/>
                <ButtonBase className={classes.image} onClick={(e) => onClickSelectType(e, type)}>
                    <CardMedia
                        className={classes.cardMedia}
                        component="img"
                        alt={Lang("Type" + upperType)}
                        image={process.env.PUBLIC_URL + "/images/typ_" + type + ".png"}
                    />
                </ButtonBase>
                <CardContent className={classes.cardContent}>
                    <Typography variant="body2" color="textSecondary">
                        {parse(Lang('roomTypeInfoContent' + upperType, 'info'))}
                    </Typography>
                </CardContent>
            </Card>
        </Badge>
    );
};

const SelectRoomType = () => {
    const selectedRoomType = useStore(state => state.roomType);
    const selectedObjType = useStore(state => state.objType);
    const setRoomType = useStore(state => state.setRoomType);
    const setUUId = useOrder(state => state.setUUId);

    const onClickSelectType = useCallback(
        (e, step) => {
            e.stopPropagation(); // stop it at the first intersection
            setUUId(uuid());
            setRoomType(step);
        },
        [setUUId, setRoomType, uuid]
    );

    let roomType = useMemo(() => {
        return getRoomType(selectedRoomType, selectedObjType);
    }, [selectedRoomType, selectedObjType]);

    const types = ['a', 'b', 'c', 'd'];

    return (
        <Grid container>
            {types.map(type => (
                <Grid item lg="3" md="3">
                    <CardRoomType type={type} isSelected={type === roomType}
                                  onClickSelectType={onClickSelectType}/>
                </Grid>
            ))}
        </Grid>
    );
};

export default SelectRoomType;