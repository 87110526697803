/**
 * @param {String} currencyCode
 * @returns {Object}
 */
export function getCurrency(currencyCode) {
    let currency = '';

    if (currencyCode === 'EUR') {
        currency = '€';
    } else if (currencyCode === 'CZK') {
        currency = 'Kč';
    }

    return currency;
}

/**
 * @param {String} currencyCode
 * @returns {Object}
 */
export function getFormattedPrice(value, currencyCode = '') {

    return value.toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + (currencyCode ? ' ' + currencyCode : '');
}