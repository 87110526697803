import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux'
import store from './storeRedux'
import "./styles.css";
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import App from "./App";
import {PRIMARY_COLOR} from "./config";
import blue from "@material-ui/core/colors/blue";
import TagManager from "react-gtm-module";

if (process.env.REACT_APP_GOOGLE_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_ID,
        dataLayer: {}
    }

    TagManager.initialize(tagManagerArgs);
}

const theme = createMuiTheme({
    props: {
        body: {
            paddingBottom: 100,
            fontFamily: 'Poppins',
        },
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            disabled: '#A27037',
        },
        secondary: {
            main: blue[300],
        },
    },
    overrides: {
        MuiBadge: {
            root: {},
            colorPrimary: {
                backgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
            }
        },
        Mui: {
            selected: {
                color: PRIMARY_COLOR,
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "black",
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: PRIMARY_COLOR,
            }
        },
        MuiButton: {
            containedPrimary: {
                color: 'white',
            }
        },
        MuiStepLabel: {
            active: {
                color: PRIMARY_COLOR,
            }
        },
        MuiAlert: {
            message: {
                fontFamily: 'Raleway',
            }
        },
        MuiGridList: {
            root: {
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }
        }
    },
    typography: {
        h2: {
            color: '#A27037',
            fontSize: 20,
            fontWeight: "bold",
        },
        h6: {
            fontFamily: 'Raleway',
        },
        subtitle1: {
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontSize: 10,
            textTransform: 'none',
        },
        body1: {
            fontFamily: 'Poppins',
        },
        fontFamily: [
            'Raleway',
            'Poppins',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

const rootElement = document.getElementById("root");

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </Provider>
    , rootElement);