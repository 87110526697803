import {get as getWidth} from "./width";
import {getSpacesCounts} from "../Space";

/**
 * @param {String} roomType
 * @returns {Object}
 */
export function getParametersHeightInputs(roomType) {
    const heightInputs = [{type: "LeftFrontHeight"}, {type: "RightFrontHeight"}];

    if (roomType === "c" || roomType === "d") {
        heightInputs.push({type: "LeftBackHeight"});
    }

    if (roomType === "b" || roomType === "d") {
        heightInputs.push({type: "RightBackHeight"});
    }

    return heightInputs;
}

/**
 * @param {Number} height
 * @param {Number} spacesCount
 * @param setHeights
 * @returns {*}
 */
export function getParametersDoorSpaceHeights(height, spacesCount, setHeights) {
    let emptySpacesCount = spacesCount;
    const heights = {}
    //top
    height -= 4.5;

    //bottom
    height -= 6.2;

    //processing sheet
    height -= (spacesCount - 1) * 1.5;

    Object.values(setHeights).forEach(spaceHeight => {
        height -= spaceHeight;
        emptySpacesCount -= 1;
    });

    if (height > 0) {
        const otherHeight = Math.floor((height / emptySpacesCount) * 10) / 10;

        for (let num = 0; num < spacesCount; num++) {
            if (setHeights[num]) {
                heights[num] = setHeights[num];
            } else {
                heights[num] = otherHeight;
            }
        }
    }

    return heights;
}

export function getWardrobeAttributes(wardrobeData) {

    wardrobeData.isDepthSet = (wardrobeData.depth);
    wardrobeData.isHeightSet = (wardrobeData.height);
    wardrobeData.isWidthSet = (wardrobeData.width);

    if (!wardrobeData.depth) wardrobeData.depth = 35;
    if (!wardrobeData.height) wardrobeData.height = 235;
    if (!wardrobeData.width) wardrobeData.width = 120;

    if (!wardrobeData.spacesCount) {
        wardrobeData.spacesCount = Math.ceil(wardrobeData.width / 120);
    }

    wardrobeData.width = getWidth(wardrobeData.width, wardrobeData.spacesCount);

    return wardrobeData;
}