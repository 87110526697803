import React, {useEffect, useState} from 'react';
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import {Lang} from '../../../Utils';
import TopMenu from "../Menu/TopMenu";
import {Box, Paper} from "@material-ui/core";
import SelectRoomType from "../Menu/SelectRoomType";
import {makeStyles} from "@material-ui/core/styles";
import MiddleMenu from "../Menu/MiddleMenu";
import SiteAppBar from "../Menu/SiteAppBar";
import SelectTypeModal from "../SelectTypeModal";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import {useSteps} from "../../../Stores/Steps";

const useStyles = makeStyles((theme) => ({
    mainBox: {
        paddingTop: 20,
        paddingBottom: 20,
    },
}));

/**
 * @returns {*}
 * @constructor
 */
const RoomType = ({fullScreenHandle, onClickSteps, isDevSet = false}) => {
    const classes = useStyles();
    const setIsDev = useSteps(state => state.setIsDev);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isDevSet) {
            if (process.env.REACT_APP_API_DEV_URL) {
                window.isSiteDev = true;
                setIsDev(isDevSet);
            } else {
                setError('DevURLisNotSet')
            }
        }
    }, [isDevSet]);

    return (
        <>
            <TopMenu mainStep="roomType"/>
            <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
            <Paper>
                <SiteAppBar title={Lang('RoomTypeTitle')} onClickSteps={onClickSteps}/>
                {error && (
                    <Box>
                        <Alert severity="error">
                            {Lang(error)}
                        </Alert>
                    </Box>
                )}
                <Box className={classes.mainBox}>
                    <SelectRoomType/>
                </Box>
            </Paper>
            <VerticalSidebar onClickSteps={onClickSteps}/>
            <SelectTypeModal/>
        </>
    );
};

export default RoomType;