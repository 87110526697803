import * as THREE from "three";
import React from "react";
import getMaterial from "./material";

/**
 * @param {Number} posY
 * @param {Array} doorAttributes
 * @param {Number} height
 * @returns {JSX.Element}
 * @constructor
 */
const Strip = ({posY, doorAttributes, height = 2}) => {
    const objectMaterial = getMaterial(doorAttributes['profile']);
    const width = doorAttributes['width'];

    let posX = 0;
    let posZ = 0.5;

    posY += height / 2;

    const geometry = new THREE.BoxGeometry(width, height, 1);

    const topObject = new THREE.Mesh(
        geometry,
        objectMaterial
    );

    return (
        <group position-y={posY} position-x={posX} position-z={posZ}>
            <mesh>
                <primitive object={topObject}/>
            </mesh>
        </group>
    );
};

export default Strip;