import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superAgent = superagentPromise(_superagent, Promise);
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
    if (token) {
        req.set('authorization', `Token ${token}`);
    }
}

const isDev = (typeof window.isSiteDev !== "undefined" && window.isSiteDev);

const requests = {
    del: url =>
        superAgent.del(`${isDev ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL}${url}`).use(tokenPlugin).then(responseBody),
    get: url =>
        superAgent.get(`${isDev ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL}${url}`).use(tokenPlugin).then(responseBody),
    put: (url, body) =>
        superAgent.put(`${isDev ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL}${url}`, body).use(tokenPlugin).then(responseBody),
    post: (url, body) =>
        superAgent.post(`${isDev ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL}${url}`, body).use(tokenPlugin).then(responseBody)
};

const DTD = {
    get: () =>
        requests.get(`?dtd`),
    getImages: () =>
        requests.get(`?dtd/images`),
};

const Corpuses = {
    getVariants: () =>
        requests.get(`?corpuses/variants`),
};

const Doors = {
    getVariants: () =>
        requests.get(`?doors/variants`),
    getFills: () =>
        requests.get(`?doors/fills`),
};

const Price = {
    get: (data) =>
        requests.post(`?price/get`, data),
    put: (data) =>
        requests.put(`?price/get`, data),
};

const Data = {
    get: () =>
        requests.get(`?data/get`),
};

const Order = {
    submit: (data) =>
        requests.post(`?order/submit`, data),
    email: (data) =>
        requests.post(`?order/email`, data),
    load: (objId, APIUUId) =>
        requests.get(`?order/${objId}/${APIUUId}`)
};

export default {
    DTD,
    Corpuses,
    Doors,
    Price,
    Data,
    Order,
};