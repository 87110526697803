export const INPUTS_LANG = {
    Width: 'Šírka',
    Height: 'Výška po strop',
    Depth: 'Celková hloubka skříně',
    MinSpaceWidthError: 'Minimální šířka prostoru je {0} cm',
    MaxSpaceWidthError: 'Maximální šířka prostoru je {0} cm',
    WrongWidthNumberValue: 'Neplatná šířka ',
    MinWardrobeWidthError: 'Šířka musí být rovna nebo větší než {0} cm',
    MaxWardrobeWidthError: 'Maximální velikost nesmí být větší než {0} cm',
    MinWardrobeDepthError: 'Celková hloubka skříně nesmí být menší než {0} cm',
    MaxWardrobeDepthError: 'Celková hloubka skříně nesmí být větší než {0} cm',
    MinWardrobeHeightError: 'Výška nesmí být menší než {0} cm',
    MaxWardrobeHeightError: 'Výška nesmí být větší než {0} cm',
    WidthIsEmpty: 'Je nutné zadat šířku',
    DepthIsEmpty: 'Je nutné zadat celkovu hloubku skříně',
    LeftFrontHeightIsEmpty: 'Je třeba zadat levou přední výšku stropu',
    RightFrontHeightIsEmpty: 'Je třeba zadat pravou přední výšku stropu',
    LeftBackHeightIsEmpty: 'Je třeba zadat levou zadní výšku stropu',
    RightBackHeightIsEmpty: 'Je třeba zadat pravou zadní výšku stropu',
    InputCanNotBeEmpty: '{0} nemůže být prázdná',
    MinWardrobeError: '{0} nesmí být menší než {1}cm',
    MaxWardrobeError: '{0} nesmí být větší než {1}cm',
    drawers: 'Příplatek za zásuvku s dotahem {0} {1}',
    d_stetiny: 'Dorazové kartáčky ({0}{1})',
    p_stetiny: 'Prachové kartáčky ({0}{1})',
    pozicioner: 'Pozicionéru',
    BadEmailFormatError: 'Nesprávný formát e-mailové adresy',
};