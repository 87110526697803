export const INFO_LANG = {
    lbl_edit_width: 'Zadanie šírky skrine',
    lbl_edit_width_info: 'Šírka Vašej vstavanej skrine nesmie byť menšia ako 90 cm. Je potrebné zadávať vonkajšie rozmery vstavanej skrine, pretože vnútorná zástavba bude menšia o hrúbku drevotriesky. Ak máte vstavanú skriňu umiestnenú vo výklenku (pôdorys typu A), doporučujeme od nameranej šírky odpočítať 3-4mm.',
    lbl_edit_height: 'Zadanie výšky skrine',
    lbl_edit_height_info: 'Vzhľadom na nerovnosť stien, resp. stropov, odporúčame od nameranej výšky odrátať 3-4 mm, aby nedochádzalo k problémom pri montáži vstavanej skrine. Pri Vami zadaných rozdielnych rozmeroch, budú bočnice narezané na najnižšiu zadanú výšku, preto je naozaj dôležité si premerať, či je výška akceptovateľná v celej hĺbke vstavanej skrine. Maximálna povolená výška bez delenia dvier je 270 cm.',
    lbl_edit_depth: 'Zadanie hĺbky skrine',
    lbl_edit_depth_info: 'Hĺbkou vstavanej skrine sa rozumie hĺbka celej zostavy aj s posuvným systémom, čo znamená, že vnútro vstavanej skrine je o 10 cm menšie. Z toho dôvodu nami odporúčaná hĺbka vstavanej skrine je 70 cm (vnútro bude predstavovať 60 cm).',
    selectProfilesTitle: 'VYBERTE SI TYP A FARBU PROFILU',
    selectProfilesContentVD: 'Profil, sa má na mysli rám dverí. Vyberte si z našej pestrej ponuky profilov. Môžete si vybrať hliníkový rám otvorený, zatvorený alebo Minimax. Všetky dvere ako aj horná a spodná koľajnica sa orámujú zvoleným typom rámu. Cena profilov je rovnaká.<br><br>Otvorený profil - Najčastejšie predávaný profil pre vstavané skrine. Madlo má tvar písmena C, pre uchytenie čo mu zaručuje jednoduchú manipuláciu.<br><br>Zatvorený profil - Tento profil má tvar písmena S a požíva sa na posuvné dvere vstavanej skrine, je najvhodnejší pre posuvné dvere na predelenie miestnosti nakoľko má obojstranný tvar.<br><br>1.&nbsp;&nbsp; V uvedenej farbe, ktorú si vyberiete, budete mať hornú a dolnú koľajnicu.<br>2.&nbsp;&nbsp; Ak máte posuvné dvere predelené na viac častí, tak deliace lišty budú v rovnakej farbe.<br><span class="text_description">3.&nbsp;&nbsp; Po vyplnení pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',
    selectProfilesContentD: 'Profil, sa má na mysli rám dverí. Vyberte si z našej pestrej ponuky profilov. Môžete si vybrať hliníkový rám otvorený, zatvorený alebo Minimax. Všetky dvere ako aj horná a spodná koľajnica sa orámujú zvoleným typom rámu. Cena profilov je rovnaká.<br><br>Otvorený profil - Najčastejšie predávaný profil pre vstavané skrine. Madlo má tvar písmena C, pre uchytenie čo mu zaručuje jednoduchú manipuláciu.<br><br>Zatvorený profil - Tento profil má tvar písmena S a požíva sa na posuvné dvere vstavanej skrine, je najvhodnejší pre posuvné dvere na predelenie miestnosti nakoľko má obojstranný tvar.<br><br>1.&nbsp;&nbsp; V uvedenej farbe, ktorú si vyberiete, budete mať hornú a dolnú koľajnicu.<br>2.&nbsp;&nbsp; Ak máte posuvné dvere predelené na viac častí, tak deliace lišty budú v rovnakej farbe.<br><span class="text_description">3.&nbsp;&nbsp; Po vyplnení pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',
    roomTypeContentVD: "Vyberte typ pôdorysu vzhľadom na to, kde má byť Vaša vstavaná skriňa umiestnená.",
    roomTypeContentV: "Vyberte typ pôdorysu vzhľadom na to, kde má byť Vaša vstavaná skriňa umiestnená.",
    roomTypeContentD: "Vyberte typ pôdorysu vzhľadom na to, kde majú byť dvere umiestnené.",

    roomTypeInfoContentA: "Skriňa bude od ľavej steny po pravú.<br><br>poznámka: dĺžka vnútornej zástavby bude - 1cm",
    roomTypeInfoContentB: "Skriňa bude od ľavej steny po bočnicu",
    roomTypeInfoContentC: "Skriňa bude od pravej steny po bočnicu",
    roomTypeInfoContentD: "Skriňa bude mať ľavú a pravú bočnicu",

    selectDoorSpacesCountTitle: "VÝBER TYPU DVIER",
    selectDoorSpacesCountContentVD: 'V tomto kroku si vyberte pre každý priestor typ posuvných dvier. Na výber sú plné dvere, ale aj dvere delené (na polovicu, na tretiny, dokonca aj na menšie časti). Priestor pre ktorý sa aktuálne definuje typ je označený zelenou farbou.<br><br>TIP: Pri dverách delených na polovicu, tretinu resp. na pätinu si môžete ľubovoľne zmeniť výšku deliaceho priestoru.<br><br>V nasledujúcich krokoch budete mať možnosť vybrať si farebné prevedenie dverí. <span class="text_description">Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',
    selectDoorSpacesCountContentD: 'V tomto kroku si vyberte pre každý priestor typ posuvných dvier. Na výber sú plné dvere, ale aj dvere delené (na polovicu, na tretiny, dokonca aj na menšie časti). Priestor pre ktorý sa aktuálne definuje typ je označený zelenou farbou.<br><br>TIP: Pri dverách delených na polovicu, tretinu resp. na pätinu si môžete ľubovoľne zmeniť výšku deliaceho priestoru.<br><br>V nasledujúcich krokoch budete mať možnosť vybrať si farebné prevedenie dverí. <span class="text_description">Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',

    selectDTDContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    basicSettingsContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    selectCorpusesContentVD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

    setWardrobeParametersWidthTitle: 'ZADANIE ŠÍRKY SKRINE',
    setWardrobeParametersWidthContentVD: '1. Šírka vstavanej skrine nesmie byť menšia ako 90cm.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;Pri šírke vstavanej skrine uvádzajte presný rozmer priestoru.<br>\n' +
        '2. Pri pôdoryse Typu „A“ zadávajte presný nameraný rozmer šírky výklenku od steny po stenu.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp; Pri tomto pôdoryse Vaše vnútro vstavanej skrine systém automaticky prepočíta menšie o&nbsp;1 až 1,5cm, aby sa vnútro skrine zmestilo medzi dve pevné steny. V&nbsp;tomto prípade budú mať posuvné dvere presný rozmer od steny po stenu.<br>\n' +
        '3. Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
    setWardrobeParametersWidthContentD: "Šírka výklenku nesmie byť menšia ako 90 cm. Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať",
    setWardrobeParametersWidthContentV: '1. Šírka vstavanej skrine nesmie byť menšia ako 90cm.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;Pri šírke vstavanej skrine uvádzajte presný rozmer priestoru.<br>\n' +
        '2. Pri pôdoryse Typu „A“ zadávajte presný nameraný rozmer šírky výklenku od steny po stenu.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp; Pri tomto pôdoryse Vaše vnútro vstavanej skrine systém automaticky prepočíta menšie o&nbsp;1 až 1,5cm, aby sa vnútro skrine zmestilo medzi dve pevné steny. V&nbsp;tomto prípade budú mať posuvné dvere presný rozmer od steny po stenu.<br>\n' +
        '3. Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',

    setWardrobeParametersHeightTitle: 'ZADANIE VYŠKY SKRINE',
    setWardrobeParametersHeightTitleV: 'Zadanie výšky výklenku',

    setWardrobeParametersHeightContentVD: '1. Výšku vstavanej skrine je dôležité pomerať (od zeme po strop) v bode, kde skriňa začína a kde končí (ľavá a pravá výška)<br>\n' +
        '2. Výšku skrine na ľavej a pravej strane nemusíte mať rovnakú. Je potrebné uviesť presný rozmer nameranej výšky.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;Posuvné dvere majú reguláciu na výšku max do 1,5cm.<br>\n' +
        '3. V prípade väčších výškových rozdielov uveďte menšiu nameranú výšku a do poznámky pri objednávke uveďte presnú výšku.<br>\n' +
        '&nbsp;&nbsp;&nbsp;-&nbsp;Pri konzultácii objednávky Vám upravíme správne rozmery.<br>\n' +
        '4.  Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
    setWardrobeParametersHeightContentD: 'Vzhľadom na nerovnosť stropov odporúčame premerať výšku výklenku v mieste kde sa bude montovať predný posuvný systém. Pri Vami zadaných rozdielnych rozmerov výšky sa dvere budú vyrábať z nižšej nameranej výšky. Hornú koľajnicu je nutné vypodkladať na najnižšie nameranú výšku výklenku. Rozdiel výšok však nesmie byť väčší ako 1,5 cm. V prípade že je rozdiel väčší, tak je nutné hornú koľajnicu vypodkladať a do systému zapísať novú výšku. Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať',
    setWardrobeParametersHeightContentV: "Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať",

    setWardrobeParametersDepthTitle: 'ZADANIE HĹBKY SKRINE',
    setWardrobeParametersDepthContentVD: '1. Hĺbkou skrine sa rozumie celková hĺbka vstavanej skrine.<br>\n' +
        '2. Napríklad, ak celková hĺbka vstavanej skrine je 60cm, tak 10cm bude zaberať posuvný systém a vnútro skrine bude mať 50cm.<br>\n' +
        '3. Samostatné vnútro vstavanej skrine má o 10cm menšiu hĺbku.<br>\n' +
        '4. Posuvný systém zaberá 10cm.<br>\n' +
        '5. Odporúčaná celková hĺbka skrine je 60-70cm.<br>\n' +
        '6. Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
    setWardrobeParametersDepthContentV: "Nami odporúčaná hĺbka vstavanej skrine je 60 cm. Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať",
    setWardrobeParametersDepthContentD: "Nami odporúčaná hĺbka vstavanej skrine je 60 cm. Po vyplnení všetkých údajov pokračujte ďalej kliknutím na tlačidlo Pokračovať",

    mainSelectDTDVDTitle: 'Farba DTD vnútra',
    mainSelectDTDVDContent: 'V nasledovnom kroku si môžete vybrať farbu vnútornej zástavby Vašej vstavanej skrine. Kliknutím na akýkoľvek obrázok drevodekoru si vyberiete farbu podľa Vášho želania z príslušného zoznamu. Vzhľadom na Vami zvolenú farbu drevodekoru sa prepočítava cena Vašej vstavanej skrine. Opätovným kliknutím na iný obrázok drevodekoru máte možnosť zmeniť pôvodne vybranú farbu. Vnútro vstavanej skrine odporúčame zhotoviť z bielej hladkej drevotriesky (Biela Briliantová). Je to cenovo výhodné a vzhľadom na to, že vnútorná zástavba je zakrytá posuvnými dverami aj dizajnovo postačujúce.',

    mainSelectDTDVTitle: 'Farba DTD vnútra',
    mainSelectDTDVContent: 'V nasledovnom kroku si môžete vybrať farbu vnútornej zástavby Vašej vstavanej skrine. Kliknutím na akýkoľvek obrázok drevodekoru si vyberiete farbu podľa Vášho želania z príslušného zoznamu. Vzhľadom na Vami zvolenú farbu drevodekoru sa prepočítava cena Vašej vstavanej skrine. Opätovným kliknutím na iný obrázok drevodekoru máte možnosť zmeniť pôvodne vybranú farbu.',

    secondSelectDTDVDTitleA: "Drevodekor dorazovej lišty",
    secondSelectDTDVDTitleB: "Drevodekor dorazovej lišty a bočnice",
    secondSelectDTDVDTitleC: "Drevodekor bočnice a dorazovej lišty",
    secondSelectDTDVDTitleD: "Drevodekor bočnice",

    secondSelectDTDVDContent: 'Podobne ako pri predchádzajúcom kroku si zo zoznamu farieb drevotriesky od viacerých výrobcov vyberte farbu dorazovej lišty, ktorá bude tvoriť viditeľnú časť Vašej vstavanej skrine. Hranovacia páska dorazovej lišty bude vo farbe zvoleného drevodekoru.',

    mainSelectDTDDTitleA: "Drevodekor dorazovej lišty",
    mainSelectDTDDTitleB: "Drevodekor dorazovej lišty a bočnice",
    mainSelectDTDDTitleC: "Drevodekor bočnice a dorazovej lišty",
    mainSelectDTDDTitleD: "Drevodekor bočnice",
    mainSelectDTDDContent: 'V nasledovnom kroku si môžete vybrať farbu vonkajšéj zástavby. Kliknutím na akýkoľvek obrázok drevodekoru si vyberiete farbu podľa Vášho želania z príslušného zoznamu. Vzhľadom na Vami zvolenú farbu drevodekoru sa prepočítava cena Vašej vstavanej skrine. Opätovným kliknutím na iný obrázok drevodekoru máte možnosť zmeniť pôvodne vybranú farbu.',

    controlAndSetHeights: 'Pre pokračovanie skontrolujte prosím zadané parametre výšok',

    selectDoorsFillTitle: "VÝBER VÝPLNE DVERÍ",
    selectDoorsFillContentVD: '1.&nbsp;&nbsp; &nbsp;Na základe Vami zvolenej výplne sa Vám automaticky prepočíta cena, pretože ceny sa v jednotlivých kategóriách výplne líšia.'
        + '<div style="margin-left:40px;">1)&nbsp;&nbsp;kategória DREVODEKOR</div>'
        + '<div style="margin-left:40px;">2)&nbsp;&nbsp;kategória ZRKADLÁ</div>'
        + '<div style="margin-left:40px;">3)&nbsp;&nbsp;kategória DREVODEKOR – EXCLUSIVE</div>'
        + '<div style="margin-left:40px;">4)&nbsp;&nbsp;kategória LACOBEL – FAREBNÉ SKLÁ</div>'
        + '2.&nbsp;&nbsp;Najlacnejšie je 1 a 2 kategória (drevodecor, zrkadlo)<br>'
        + '3.&nbsp;&nbsp;Kategórie 3 a 4 (lacobel, drevodecor exclusive) sú najluxusnejšie výplne, ktoré sa používajú na výrobu posuvných dverí.<br>'
        + '<span class="text_description">4.&nbsp; Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',
    selectDoorsFillContentD: '1.&nbsp;&nbsp; &nbsp;Na základe Vami zvolenej výplne sa Vám automaticky prepočíta cena, pretože ceny sa v jednotlivých kategóriách výplne líšia.'
        + '<div style="margin-left:40px;">1)&nbsp;&nbsp;kategória DREVODEKOR</div>'
        + '<div style="margin-left:40px;">2)&nbsp;&nbsp;kategória ZRKADLÁ</div>'
        + '<div style="margin-left:40px;">3)&nbsp;&nbsp;kategória DREVODEKOR – EXCLUSIVE</div>'
        + '<div style="margin-left:40px;">4)&nbsp;&nbsp;kategória LACOBEL – FAREBNÉ SKLÁ</div>'
        + '2.&nbsp;&nbsp;Najlacnejšie je 1 a 2 kategória (drevodecor, zrkadlo)<br>'
        + '3.&nbsp;&nbsp;Kategórie 3 a 4 (lacobel, drevodecor exclusive) sú najluxusnejšie výplne, ktoré sa používajú na výrobu posuvných dverí.<br>'
        + '<span class="text_description">4.&nbsp; Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.</span>',
    selectCorpusesSetSpacesCountTitle: "Výber počtu dvier",
    selectCorpusesSetSpacesCountTitleV: "Výber počtu priestorov",
    selectCorpusesSetSpacesCountContent: 'V spodnej časti stránky vyberte počet dvier vašej budúcej vstavanej skrine. Po vyplnení pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
    selectCorpusesSetSpacesCountContentV: 'V spodnej časti stránky vyberte počet priestorov vašej budúcej vstavanej skrine. Po vyplnení pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',

    selectCorpusesTitle: "SKLADANIE VNÚNTRA SKRINE",
    selectCorpusesContent: '1.&nbsp;&nbsp; Vnútro skrine si môžete vybrať podľa nami preddefinovaných kombinácii, alebo si viete vytvoriť vlastné vyskladanie vnútra skrine.<br>2.&nbsp;&nbsp; Všetky rozmery vnútorných modulov sú uvádzane už po odpočte materiálu (čisté rozmery).<br>3.&nbsp;&nbsp; Označením priestoru políčko „delený“ si môžete ešte priestor rozdeliť na ½.<br>4.&nbsp;&nbsp; Vnútro skrine je vyrobené z hrúbky materiálu 1,8cm.<br>5.&nbsp;&nbsp; Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.<br><br>LIMIT: Zásuvky sa dajú použiť iba v priestore od 45 cm<br><br>POSTUP: Označením jednotlivého priestoru (vyznačí sa zelenou farbou) si môžete vybrať z nasledovného zoznamu vnútro vstavanej skrine. Rovnako sa postupuje aj v prípade všetkých ďalších priestorov. Opätovným označením ktoréhokoľvek priestoru, máte možnosť urobiť zmenu podľa Vašich potrieb. Ak si želáte rozdeliť priestor kliknite na „Rozdeliť priestor“ a následne si skombinujte Vašu vstavanú skriňu.',

    basicInformationTitle: 'ZÁKLADNÉ INFORMÁCIE',
    basicInformationContent: '1.   Pred objednaním vstavanej skrine je najdôležitejšie pozrieť si VIDEONÁVOD – MONTÁŽ VSTAVANEJ SKRINE (https://lacne-skrine.sk/videonavody/), aby ste mali vedomosť o tom, že Vaša vstavaná skriňa Vám príde presne v takomto stave a presne rovnakým spôsobom je potrebné ju skladať.<br>'
        + '2. Kompletné dielce sú predpripravené a predvŕtané.<br>'
        + '3. Posuvné dvere Vám prídu v zhotovenom stave.',

    setOrderTitle: 'ZADANIE OBJEDNÁVKY – POSTUP',
    setOrderContent: '1. Ak si vytvoríte objednávku na vstavanú skriňu, tak Vám príde potvrdzujúci e-mail, ktorý obsahuje presnú cenu a nákres Vašej vstavanej skrine.<br>'
        + '2. Na základe Vašej objednávky Vás musí najprv kontaktovať technický pracovník, ktorý si s Vami odkonzultuje všetky technické údaje, ktorými sú šírka, hĺbka, výška a zvolené použité materiály.<br>'
        + '3. Do výroby Vašu objednávku na vstavanú skriňu posúvame až po telefonickej konzultácii objednávky.',

    deliveryAndDispatchTitle: 'DODANIE A EXPEDÍCIA VSTAVANEJ SKRINE - POSTUP',
    deliveryAndDispatchContent: '1. Po odkonzultovaní Vašej vstavanej skrine trvá výroba 10 pracovných dní.'
        + '2. 3-4 dni pred dodaním Vašej objednávky Vás bude kontaktovať kolega z expedície, ktorý Vám oznámi deň a predpokladaný čas doručenia Vašej vstavanej skrine.<br>'
        + '3. Vodič Vás bude kontaktovať 1 deň pred doručením a tiež aj 1 hodinu pred doručením, aby ste sa vedeli presunúť na miesto dodania Vašej vstavanej skrine.<br>'
        + '4. Vynášku vstavanej skrine si robí každý zákazník sám.<br>'
        + '-  Z tohto dôvodu Vás kontaktujeme 3-4 dni vopred, aby ste si vedeli zabezpečiť výnos Vašej objednávky.<br>'
        + '5. Preprava je zabezpečená našimi vozidlami, nie prepravnou spoločnosťou.',
    selectDTDInTitle: 'VÝBER FARBY DECORU PRE VNÚTRO VSTAVANEJ SKRINE',
    selectDTDInContent: '1. Vnútro vstavanej skrine odporúčame zhotoviť vo farbe „08681 Briliantová biela“<br>' +
        ' - Je to matná snehovo biela farba s jemne zamatovou povrchovou štruktúrou lamina.<br>' +
        ' - Je cenovo výhodná a vzhľadom na to, že je zakrytá posuvnými dverami aj dizajnovo postačujúca.<br>' +
        '2. Kliknutím na akýkoľvek obrázok decoru, Vám systém automaticky prepočítava cenu skrine v uvedenom decore DTD, kde sa cena jednotlivých decorov líši.<br>' +
        '3. Hrúbka materiálov vnútra vstavenej skrine je 1,8cm.<br>' +
        '4. Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
    selectDTDOutTitle: 'VÝBER FARBY DECORU PRE VIDITEĽNÉ ČASTI VSTAVANEJ SKRINE',
    selectDTDOutContent: '1. Podobne ako pri predchádzajúcom kroku si zo zoznamu farieb drevotriesky od viacerých výrobcov vyberte farbu dorazovej lišty, ktorá bude tvoriť viditeľnú časť Vašej vstavanej skrine.<br>' +
        '2. Kliknutím na akýkoľvek obrázok decoru, Vám systém automaticky prepočítava cenu skrine v uvedenom decore DTD, kde sa cena jednotlivých decorov líši.<br>' +
        '3. Hrúbka materiálov vstavenej skrine je 1,8cm.<br>' +
        '4. Po vyplnení údajov pokračujte ďalej, kliknutím na tlačidlo „Pokračovať“.',
};
