import React from "react";
import {apiStore, useStore} from "../../../store";
import {connect} from 'react-redux'
import {Badge, Button, Paper, Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Door from "../Components/Molecules/Door";
import {isMobile} from 'react-device-detect';

const mapStateToProps = state => ({
    ...state.todos
});

const mapDispatchToProps = dispatch => ({});

const Preview = ({doors}) => {
    return (<>
        {doors.map((spaces, index) => {
            const doorSeq = index + 1;
            const variant = doors.length === 3 ? 1 : 0;

            return <Door
                isBehind={(doorSeq%2 === variant)}
                count={spaces.length}
                isFirst={(doorSeq === 1)}
                isLast={(doors.length === doorSeq)}
                height={isMobile ? 100 : 150}
            />
        })}
    </>)
}

/*
const Preview = ({doors}) => {
    return (<Grid container>
        {doors.map(spaces => {
            return <DoorPreview spaces={spaces} hideEdit={true}/>
        })}
    </Grid>)
}*/

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    media: {
        width: 200,
    },
    badge: {
        top: '85%',
    },
    margin: {
        margin: 5,
    },
}));

const SMenu = ({variants, onSelect, num, spaceHeight, selected}) => {
    const classes = useStyles();
    const priceVersion = useStore(state => state.priceVersion);
    const selectedDoorsSpace = useStore(state => state.selectedDoorsSpace);

    return (
        <div key={priceVersion} className={classes.root}>
            {variants && variants.map(doors => {
                let isSelected = true;
                const doorsIds = [];

                doors.forEach(function (spacesCount, index) {
                    const spaces = [];

                    for (let space = 0; space < spacesCount; space++) {
                        spaces.push(space)
                    }

                    let selectedDoorsSpaceCount = 1;

                    if (selectedDoorsSpace[index + 1]) {
                        selectedDoorsSpaceCount = selectedDoorsSpace[index + 1];
                    }

                    if (selectedDoorsSpaceCount !== spacesCount) {
                        isSelected = false;
                    }

                    doorsIds.push(spaces)
                })

                return (
                    <Box className={classes.margin}>
                        <Badge
                            key={priceVersion}
                            invisible={!isSelected}
                            color="primary"
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            classes={{badge: classes.badge}}
                            badgeContent={<CheckIcon fontSize="small"/>}
                        >
                            <Paper>
                                <Button variant="paper" onClick={() => onSelect(Object.values(doorsIds))}>
                                    <Preview doors={doorsIds}/>
                                </Button>
                            </Paper>
                        </Badge>
                    </Box>
                )
            })}
        </div>
    );
}

class SelectDoorsVariantsMenu extends React.Component {

    state = {
        panes: [],
        selected: null,
    }

    onSelect(ids) {
        let i = 0;

        ids.forEach(function (spaces) {
            i++;
            apiStore.getState().setDoorType(i, spaces.length);
        })

        apiStore.getState().setPriceVersion();
    }

    render() {
        const self = this;
        let variants = [];

        if (this.props.doorsVariants.variants[this.props.spacesCount]) {
            variants = this.props.doorsVariants.variants[this.props.spacesCount];
        }

        return (
            <>
                <SMenu
                    onSelect={this.onSelect}
                    variants={variants}
                    num={this.props.spacesCount}
                    spaceHeight={this.props.spaceHeight}
                    selected={self.state.selected}
                />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDoorsVariantsMenu)