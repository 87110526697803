import create from "zustand";

export const [useLightStore, apiLightStore] = create((set, get) => {
    return {
        isSpotLightOn: false,
        isPointLightOn: true,

        setSpotLightOn(value) {
            const isSpotLightOn = value;
            set({isSpotLightOn});
        },
        setPointLightOn(value) {
            const isPointLightOn = value;
            set({isPointLightOn});
        },
    };
});
