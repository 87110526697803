export const INPUTS_LANG = {
    Width: 'Šírka',
    Height: 'Výška po strop',
    Depth: 'Celková hĺbka skrine',
    MinSpaceWidthError: 'Minimálna šírka priestoru je {0}cm',
    MaxSpaceWidthError: 'Maximálna šírka priestoru je {0}cm',
    WrongWidthNumberValue: 'Neplatná šírka',
    MinWardrobeWidthError: 'Šírka musí byť rovná alebo väčšia ako {0}cm',
    MaxWardrobeWidthError: 'Maximálna veľkošt nesmie byť väčšia ako {0}cm',
    MinWardrobeDepthError: 'Celková hĺbka skrine nesmie byť menšia ako {0}cm',
    MaxWardrobeDepthError: 'Celková hĺbka skrine nesmie byť väčšia ako {0}cm',
    MinWardrobeHeightError: 'Výška nesmie byť menšia ako {0}cm',
    MaxWardrobeHeightError: 'Výška nesmie byť väčšia ako {0}cm',
    WidthIsEmpty: 'Musíte zadať šírku',
    DepthIsEmpty: 'Musíte zadať celkovú hĺbku skrine',
    LeftFrontHeightIsEmpty: 'Musíte zadať ľavú prednú výšku stropu',
    RightFrontHeightIsEmpty: 'Musíte zadať pravú prednú výšku stropu',
    LeftBackHeightIsEmpty: 'Musíte zadať ľavú zadnú výšku stropu',
    RightBackHeightIsEmpty: 'Musíte zadať pravú zadnú výšku stropu',
    InputCanNotBeEmpty: '{0} nesmie byť prázdna',
    MinWardrobeError: '{0} nesmie byť menšia ako {1}cm',
    MaxWardrobeError: '{0} nesmie byť väčšia ako {1}cm',
    drawers: 'Príplatok za zásuvku s doťahom {0} {1}',
    d_stetiny: 'Dorazové štetiny ({0}{1})',
    p_stetiny: 'Protiprachové štetiny ({0}{1})',
    pozicioner: 'Pozicionerov',
    BadEmailFormatError: 'Nesprávny formát e-mailovej adresy',
    ALERT20:'Krstné meno nesmie byť prázdne.',
    ALERT21:'Priezvisko nesmie byť prázdne.',
    ALERT22:'Musíte zadať telefónne číslo.',
    ALERT23:'Musíte zadať emailovú adresu.',
    ALERT25:'Musíte zadať celú fakturačnú adresu.',
    ALERT26:'Musíte súhlasiť s obchodnymi podmienkami.',
    OrgDataCanNotBeEmpty:'Musíte zadať firemné údaje.',
};