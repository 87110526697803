export const TABS_LANG = {
    'EGEER Standard': "Drevodekor",
    'Mirrors': "Zrkadlá",
    'Glasses': "LACOBEL – FAREBNÉ SKLÁ",
    'EGEER Exclusive': "Drevodekor - exclusive",
    'EGEER StandardMobile': "Drevodekor",
    'MirrorsMobile': "Zrkadlá",
    'GlassesMobile': "LACOBEL <br/> FAREBNÉ SKLÁ",
    'EGEER ExclusiveMobile': "Drevodekor <br/> exclusive",
};
