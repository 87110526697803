import React, {useMemo, useState} from "react";
import {useStore} from "../store";
import {BOARD_DEPTH} from "../config";
import HorizontalBoard from "./Boards/HorizontalBoard";
import SideWoodStand from "./Boards/SideWoodStand";
import WoodStand from "./Boards/WoodStand";
import Corpuses from "./Corpuses";
import Doors from "./Doors";
import TopBail from "./Profile/TopBail";
import BottomBail from "./Profile/BottomBail";
import ExtAltitude from "./ExtAltitude";
import {useFrame} from "react-three-fiber";
import {Text} from "@react-three/drei/Text";

/**
 * @param {Object} spaceAttributes
 * @param {Object} wardrobeAttributes
 * @param {Object} roomAttributes
 * @param {Boolean} showAltitudes
 * @param {Boolean} showBoxHelper
 * @param {Object} previewAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const WardrobeCorpuses = ({
                              spaceAttributes,
                              wardrobeAttributes,
                              roomAttributes,
                              showAltitudes,
                              showBoxHelper = false,
                              previewAttributes,
                          }) => {
    const roomHeight = roomAttributes.height;
    const roomType = roomAttributes.type;
    const objType = roomAttributes.objType;
    const wardrobeWidth = wardrobeAttributes.width;
    const wardrobeDepth = wardrobeAttributes.depth;
    const spacesCount = wardrobeAttributes.spacesCount;
    const spaceWidth = spaceAttributes.width;
    const spaceHeight = spaceAttributes.height;
    const dividedSpaces = JSON.parse(useStore(state => state.dividedSpaces));
    const dividedSpacesWidth = JSON.parse(useStore(state => state.dividedSpacesWidth));

    let nextWoodStandPosition = BOARD_DEPTH;

    if ((!roomType || roomType === "c" || roomType === "d") && objType !== 'v') {
        nextWoodStandPosition += BOARD_DEPTH;
    }

    const WoodStands = [];

    for (let i = 1; i <= spacesCount; i++) {
        nextWoodStandPosition += spaceWidth;

        if (dividedSpaces[i] || spaceWidth > 100) {
            let calculateRemoveWidth = 0;

            if (dividedSpacesWidth[i + 'A']) {
                calculateRemoveWidth = (spaceWidth - (dividedSpacesWidth[i + 'A'] + BOARD_DEPTH)) + BOARD_DEPTH;
            } else if (dividedSpacesWidth[i + 'B']) {
                calculateRemoveWidth = (dividedSpacesWidth[i + 'B'] + BOARD_DEPTH);
            } else {
                calculateRemoveWidth = ((spaceWidth / 2) + (BOARD_DEPTH / 2))
            }

            WoodStands.push({id: i + 'C', positionX: nextWoodStandPosition - calculateRemoveWidth});
        }

        if (i !== spacesCount) {
            WoodStands.push({id: i, positionX: nextWoodStandPosition});
            nextWoodStandPosition += BOARD_DEPTH;
        }
    }

    let firstWoodStandPosition = 0;
    let lastWoodStandPosition = wardrobeWidth - BOARD_DEPTH;

    if (objType !== 'v') {
        if (!roomType || roomType === "c" || roomType === "d") {
            firstWoodStandPosition += BOARD_DEPTH;
        }

        if (!roomType || roomType === "b" || roomType === "d") {
            lastWoodStandPosition -= BOARD_DEPTH;
        }
    }

    return (
        <>
            <HorizontalBoard
                objType={objType}
                roomType={roomType}
                wardrobeAttributes={wardrobeAttributes}
                posY={spaceHeight}
                showBoxHelper={showBoxHelper}
            />
            <WoodStand
                wardrobeAttributes={wardrobeAttributes}
                type="in"
                posX={firstWoodStandPosition}
                showBoxHelper={showBoxHelper}
                spaceHeight={spaceHeight}
                roomHeight={roomHeight}
                boardWidth={wardrobeDepth}
            />
            {WoodStands.map(data => (
                <WoodStand
                    wardrobeAttributes={wardrobeAttributes}
                    key={data.id}
                    type="in"
                    posX={data.positionX}
                    showBoxHelper={showBoxHelper}
                    spaceHeight={spaceHeight}
                    roomHeight={roomHeight}
                    boardWidth={wardrobeDepth}
                />
            ))}
            <WoodStand
                wardrobeAttributes={wardrobeAttributes}
                type="in"
                posX={lastWoodStandPosition}
                showBoxHelper={showBoxHelper}
                spaceHeight={spaceHeight}
                roomHeight={roomHeight}
                boardWidth={wardrobeDepth}
            />
            <Corpuses
                dividedSpacesWidth={dividedSpacesWidth}
                showAltitudes={showAltitudes}
                showBoxHelper={showBoxHelper}
                spaceAttributes={spaceAttributes}
                wardrobeAttributes={wardrobeAttributes}
                roomAttributes={roomAttributes}
                previewAttributes={previewAttributes}
            />
        </>
    );
};

/**
 * @param {Object} spaceAttributes
 * @param {Object} doorAttributes
 * @param {Object} wardrobeAttributes
 * @param {Object} roomAttributes
 * @param {Object} previewAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const Wardrobe = ({spaceAttributes, doorAttributes, wardrobeAttributes, roomAttributes, previewAttributes}) => {
    const [showHelper, setShowHelper] = useState(false);
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const objType = roomAttributes.objType;
    const wardrobeDepth = wardrobeAttributes.depth;
    const wardrobeWidth = wardrobeAttributes.width;
    const wardrobeHeight = wardrobeAttributes.height;
    const actualStep = spaceAttributes.actualStep;
    const getDoorVisibility = useStore(state => state.getDoorVisibility);
    const doorVisible = getDoorVisibility(actualStep);
    let showDoors = doorVisible.iVisible;
    const isDoorSetVisible = useStore(state => state.isDoorSetVisible);
    const getDimensionsVisibility = useStore(state => state.getDimensionsVisibility);
    const isDimensionsSetVisible = useStore(state => state.isDimensionsSetVisible);
    const dimensionsVisible = useMemo(() => getDimensionsVisibility(actualStep, isDimensionsSetVisible, isDoorSetVisible), [isDoorSetVisible, isDimensionsSetVisible, getDimensionsVisibility, actualStep]);
    const sideWoodStandsVisible = ('v' !== objType);
    let corpusesVisible = true
    const spaceHeight = spaceAttributes.height;
    const freeSpaceHeightTop = useMemo(() => Math.floor((wardrobeHeight - spaceHeight - BOARD_DEPTH) * 10) / 10, [wardrobeHeight, spaceHeight]);

    if ('d' === objType) {
        showDoors = true;
        corpusesVisible = false;
    } else if ('v' === objType) {
        showDoors = false;
    }

    const showSecondDTDBoxHelper = useMemo(() => (selectedDTDType === 'second' && actualStep === 'selectDTD'), [selectedDTDType, actualStep]);
    const showMainDTDBoxHelper = useMemo(() => (selectedDTDType === 'main' && actualStep === 'selectDTD'), [selectedDTDType, actualStep]);

    let test = 0;

    useFrame(() => {
        if (showSecondDTDBoxHelper || showMainDTDBoxHelper) {
            if (test === 30) {
                //testRef.current.showBoxHelper = false;
                setShowHelper(false);
            } else if (test > 50) {
                setShowHelper(true);
                test = 0;
            }

            test += 1
        }
    })

    return (
        <>
            {sideWoodStandsVisible && (
                <>
                    <SideWoodStand
                        wardrobeAttributes={wardrobeAttributes}
                        roomAttributes={roomAttributes}
                        type="left"
                        posX={0}
                        showBoxHelper={(showSecondDTDBoxHelper && showHelper)}
                    />
                    <SideWoodStand
                        wardrobeAttributes={wardrobeAttributes}
                        roomAttributes={roomAttributes}
                        type="right"
                        posX={wardrobeWidth - BOARD_DEPTH}
                        showBoxHelper={(showSecondDTDBoxHelper && showHelper)}
                    />
                    <TopBail doorAttributes={doorAttributes} wardrobeAttributes={wardrobeAttributes}/>
                    <BottomBail doorAttributes={doorAttributes} wardrobeAttributes={wardrobeAttributes}/>
                </>
            )}
            {corpusesVisible && (
                <WardrobeCorpuses
                    showBoxHelper={(showMainDTDBoxHelper && showHelper)}
                    spaceAttributes={spaceAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    roomAttributes={roomAttributes}
                    showAltitudes={dimensionsVisible.iVisible && 'selectCorpuses' === actualStep}
                    previewAttributes={previewAttributes}
                />
            )}
            {showDoors && <Doors doorAttributes={doorAttributes} wardrobeAttributes={wardrobeAttributes}
                                 previewAttributes={previewAttributes}/>}
            {dimensionsVisible.iVisible && (
                <>
                    <group position-z={(wardrobeDepth / 2) + 0.1} position-x={(wardrobeWidth - BOARD_DEPTH)}>
                        {roomAttributes.isHeightSet && (
                            <ExtAltitude
                                type="right"
                                value={wardrobeHeight}
                            >
                                <Text color={'black'}
                                      fontSize={12}
                                      maxWidth={200}
                                      textAlign={"right"}
                                      anchorX="left"
                                      anchorY="middle"
                                >
                                    {Math.floor(wardrobeHeight * 10) / 10} cm
                                </Text>
                            </ExtAltitude>
                        )}
                    </group>
                    {objType !== 'd' && (
                        <group position-z={(wardrobeDepth / 2) + 0.1} position-x={0}>
                            {roomAttributes.isHeightSet && (
                                <ExtAltitude
                                    type="left"
                                    value={spaceHeight}
                                >
                                    <Text color={'black'}
                                          fontSize={12}
                                          maxWidth={200}
                                          textAlign={"left"}
                                          anchorX="left"
                                          anchorY="middle"
                                    >
                                        {Math.floor(spaceHeight * 10) / 10} cm
                                    </Text>
                                </ExtAltitude>
                            )}
                            <group position-y={spaceHeight + BOARD_DEPTH}>
                                {roomAttributes.isHeightSet && freeSpaceHeightTop && (
                                    <ExtAltitude
                                        type="left"
                                        value={freeSpaceHeightTop}
                                    >
                                        <Text color={'black'}
                                              fontSize={12}
                                              maxWidth={200}
                                              textAlign={"right"}
                                              anchorX="left"
                                              anchorY="middle"
                                        >
                                            {Math.floor(freeSpaceHeightTop * 10) / 10} cm
                                        </Text>
                                    </ExtAltitude>
                                )}
                            </group>
                        </group>
                    )}
                    <group position-z={(wardrobeDepth / 2)} position-y={1} rotation={[0 - Math.PI / 2, 0, 0]}>
                        {wardrobeAttributes.isWidthSet && (
                            <ExtAltitude
                                type="bottom"
                                value={wardrobeWidth}
                            >
                                <Text color={'black'}
                                      fontSize={12}
                                      maxWidth={wardrobeWidth}
                                      textAlign={"right"}
                                      anchorX="center"
                                      anchorY="middle"
                                >
                                    {Math.floor(wardrobeWidth * 10) / 10} cm
                                </Text>
                            </ExtAltitude>
                        )}
                    </group>
                    <group position-z={(wardrobeDepth / 2)} position-y={1} position-x={(wardrobeWidth)}
                           rotation={[0, 0, Math.PI / 2]} slowScale={true}>
                        {wardrobeAttributes.isDepthSet && (
                            <ExtAltitude
                                type="right_bottom"
                                value={wardrobeDepth}
                            >
                                <Text color={'black'}
                                      fontSize={12}
                                      maxWidth={200}
                                      textAlign={"left"}
                                      anchorX="center"
                                      anchorY="middle"
                                >
                                    {Math.floor(wardrobeDepth * 10) / 10} cm
                                </Text>
                            </ExtAltitude>
                        )}
                    </group>
                </>
            )}
        </>
    );
};

export default Wardrobe;