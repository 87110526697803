import React from "react";
import {useStore} from "../../../store";
import {connect} from 'react-redux'
import {Lang} from "../../../Utils";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SelectDTDType from "./SelectDTDType";
import {Badge, Grid, Paper} from "@material-ui/core";
import {Image} from "semantic-ui-react";
import CheckIcon from "@material-ui/icons/Check";
import SiteAppBar from "./SiteAppBar";
import SelectSingleDTDType from "./SelectSingleDTDType";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    ...state.todos
});

const mapDispatchToProps = dispatch => ({});

const useCardStyles = makeStyles({
    root: {
        maxWidth: 200,
        margin: 10,
    },
    media: {
        width: 200,
    },
    badge: {
        top: '85%',
    }
});

const useListStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        margin: 0,
    },
    gridList: {
        overflow: 'auto',
    },
}));

function DTDImgCard({woodDecor, productImages}) {
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const objType = useStore(state => state.objType);
    const mainDTDId = useStore(state => state.mainDTDId);
    const secondDTDId = useStore(state => state.secondDTDId);
    const selectedId = selectedDTDType === 'main' && objType !== 'd' ? mainDTDId : secondDTDId;
    const setDTDImage = useStore(state => state.setDTDImage);
    const classes = useCardStyles();

    return (
        <GridListTile key={'GridListTile' + woodDecor.productid}>
            <Badge
                invisible={selectedId.toString() !== woodDecor.productid.toString()}
                color="primary"
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={<CheckIcon fontSize="small"/>}
                classes={{badge: classes.badge}}
            >
                <Card
                    className={classes.root}
                    key={woodDecor.productid}
                    onPointerDown={(e) => {
                        setDTDImage('', woodDecor.productid, productImages[woodDecor.productid])
                    }}
                >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            alt={Lang(woodDecor.name, 'products')}
                            height="140"
                            image={woodDecor.img1}
                            title={Lang(woodDecor.name, 'products')}
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {woodDecor.code}
                            </Typography>
                            <Typography gutterBottom>
                                {Lang(woodDecor.name, 'products')}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Badge>
        </GridListTile>
    );
}

class SelectDTDTabContent extends React.Component {

    state = {
        woodDecors: [],
        selectedDTDType: null,
    }

    componentDidMount() {
        this._actualizeWoodDecors();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedDTDType !== this.props.selectedDTDType) {
            this._actualizeWoodDecors();
        }
    }

    _actualizeWoodDecors() {
        const mainWoodDecors = [];
        const otherWoodDecors = [];

        this.props.woodDecors.map(product => {
            if (this.props.mainDTDId.toString() === product.productid.toString() || this.props.secondDTDId.toString() === product.productid.toString()) {
                mainWoodDecors.push(product);
            } else {
                otherWoodDecors.push(product);
            }

            return true;
        })

        this.setState({woodDecors: [...mainWoodDecors, ...otherWoodDecors]});
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <GridList className={this.props.classes.gridList} cols={2.5}>
                    {this.state.woodDecors && this.state.woodDecors.map(woodDecor =>
                        <DTDImgCard woodDecor={woodDecor} productImages={this.props.productImages}/>
                    )}
                </GridList>
            </div>
        )
    }
}

function SelectDTDTab({woodDecors, productImages}) {
    const classes = useListStyles();
    const mainDTDId = useStore(state => state.mainDTDId);
    const secondDTDId = useStore(state => state.secondDTDId);
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const objType = useStore(state => state.objType);

    return (
        <SelectDTDTabContent classes={classes} productImages={productImages} woodDecors={woodDecors}
                             mainDTDId={objType !== 'v' ? mainDTDId : secondDTDId} secondDTDId={secondDTDId}
                             selectedDTDType={selectedDTDType}/>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderWidth: 2,
        padding: '0',
    },
    content: {
        padding: 10,
    },
    tabs: {
        backgroundColor: theme.palette.background.paper,
    },
    tabsBackground: {
        backgroundColor: theme.palette.grey[100],
    },
    divider: {
        height: 28,
        margin: 4,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}));

function SimpleTabs({
                        count,
                        searchText,
                        tabsContent,
                        objType,
                        mainSize,
                        handleTabChange,
                        selectedDTDType,
                        onClickSteps,
                        onChangeSearchText
                    }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeSearch = (event, newValue) => {
        onChangeSearchText(newValue);
    };

    if (Object.keys(tabsContent).length === 1) {
        return (
            <>
                <SiteAppBar title={Lang('SelectDTDTitle')} onClickSteps={onClickSteps}>
                    <Paper square>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                {'vd' === objType && <SelectSingleDTDType
                                    mainSize={mainSize}
                                    handleTabChange={handleTabChange}
                                />}
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Divider className={classes.divider} orientation="vertical"/>
                                    </Grid>
                                    <Grid item>
                                        <InputBase
                                            className={classes.input}
                                            placeholder={Lang('SearchDTD')}
                                            inputProps={{'aria-label': Lang('SearchDTD')}}
                                            startAdornment={
                                                <SearchIcon color="primary"/>
                                            }
                                            onChange={e => handleChangeSearch(e, e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </SiteAppBar>
                <div className={classes.content}>
                    {tabsContent.map((tab, index) => (
                        <div key={count + '-' + index + '-' + searchText}>
                            {tab.render}
                        </div>
                    ))}
                </div>
            </>
        );
    } else {
        return (
            <>
                <SiteAppBar title={Lang('SelectDTDTitle')} onClickSteps={onClickSteps}>
                    {'vd' === objType && <SelectDTDType
                        mainSize={mainSize}
                        handleTabChange={handleTabChange}
                        activeIndex={'main' === selectedDTDType ? 0 : 1}
                    />}
                    <Tabs
                        textColor="primary"
                        className={classes.tabs}
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabsContent.map((tab, index) => (
                            <Tab label={tab.menuItem} {...a11yProps(index)}
                                 classes={{
                                     root: classes.tab, // class name, e.g. `root-x`
                                     selected: classes.tabSelected, // class name, e.g. `disabled-x`
                                 }}/>
                        ))}
                    </Tabs>
                </SiteAppBar>
                <div className={classes.content}>
                    {tabsContent.map((tab, index) => (
                        <TabPanel value={value} index={index}>
                            {tab.render}
                        </TabPanel>
                    ))}
                </div>
            </>
        );
    }
}

class SelectDTD extends React.Component {

    state = {
        count: 0,
        searchText: null,
        tabsContent: [],
    }

    componentDidMount() {
        this.actualizeTabContents(this.state.searchText);
    }

    actualizeTabContents(searchText) {
        let count = 0;
        const self = this;
        const tabsContent = [];

        Object.keys(this.props.products.products).forEach(function (category) {
            const woodDecors = [];

            if (self.props.products.products[category] && Object.values(self.props.products.products[category]).length) {

                self.props.products.products[category].map(product => {
                    let skipProduct = false;

                    if (searchText) {
                        const productName = product.code + ' ' + Lang(product.name, 'products');

                        skipProduct = productName.toLowerCase().search(searchText.toLowerCase()) === -1;
                    }

                    if (!skipProduct) {
                        woodDecors.push(product);
                        count++;
                    }

                    return true;
                })

                let url = null;

                if (category === 'egeer_standard') {
                    url = process.env.PUBLIC_URL + '/images/DTD/egeer-standard.png';
                } else if (category === 'egeer_exclusive') {
                    url = process.env.PUBLIC_URL + '/images/DTD/egeer-exclusive.png';
                }

                tabsContent.push({
                    menuItem: url ? <Image src={url}/> : Lang(category),
                    render: <SelectDTDTab
                        woodDecors={woodDecors}
                        productImages={self.props.productImages.large}/>,
                });
            }
        });

        this.setState({tabsContent: tabsContent, count: count});
    }

    /**
     * @param {String} text
     * @private
     */
    _onChangeSearchText = (text) => {
        this.setState({searchText: text});

        this.actualizeTabContents(text);
    }

    render() {
        return (
            <SimpleTabs
                count={this.state.count}
                searchText={this.state.searchText}
                tabsContent={this.state.tabsContent}
                objType={this.props.objType}
                mainSize={this.props.mainSize}
                handleTabChange={this.props.handleTabChange} selectedDTDType={this.props.selectedDTDType}
                onClickSteps={this.props.onClickSteps}
                onChangeSearchText={this._onChangeSearchText}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDTD)