import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

export function getHtmlTooltip() {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
        arrow: {
            color: '#f5f5f9',
            fontSize: theme.typography.pxToRem(16),
        }
    }))(Tooltip);

    return HtmlTooltip;
}
