import React from 'react';
import PriceCalculationTemplate from "../Components/Templates/PriceCalculation";
import {connect} from "react-redux";
import PhoneSave from "../Modals/PhoneSave";
import {SAVE_PHONE_EMAIL} from "../../../constants/actionTypes";
import agent from "../../../agent";
import {getSendData} from "../../../utils/api";
import Process from "../Modals/Process";
import {apiSteps} from "../../../Stores/Steps";

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({
    savePhoneEmail: (payload, actualSendData) =>
        dispatch({type: SAVE_PHONE_EMAIL, payload, actualSendData}),
});

class PriceCalculation extends React.Component {

    _onSavePhoneEmail = () => {
        const newSendData = JSON.stringify(getSendData());
        this.props.savePhoneEmail(agent.Order.email(newSendData), newSendData);
        apiSteps.getState().openModal('process');
    }

    render() {
        let orContactUs = '';

        if (this.props.price && this.props.price.or_contact_us) {
            orContactUs = this.props.price.or_contact_us.replace(/<br \/>/g, "\r\n");
        }

        return (
            <>
                <PriceCalculationTemplate
                    fullScreenHandle={this.props.fullScreenHandle}
                    onClickSteps={this.props.onClickSteps}
                    price={this.props.price}
                />
                <PhoneSave orContactUs={orContactUs} onSavePhoneEmail={this._onSavePhoneEmail}/>
                <Process inProgress={this.props.saveInProgress} result={this.props.savedEmail}/>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculation)