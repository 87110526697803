import React, {useMemo, createRef, useCallback, useState} from "react";
import {Redirect} from "react-router-dom";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import {useStore} from "../../../store";
import {useSteps} from "../../../Stores/Steps";
import {CORPUSES_CATEGORIES} from "../../../config";
import {Lang} from "../../../Utils";
import TopMenu from "../Menu/TopMenu";
import '../../../App.css';
import CorpusesMenu from "../Components/Molecules/CorpusesMenu";
import SelectCorpusesVariants from "../Modals/SelectCorpusesVariants";
import {makeStyles} from "@material-ui/core/styles";
import {Card, Divider} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import {getCorpusesSpaces, getCorpusesSpaceWidth} from "../../../utils/corpuses";
import {getSpaceWidth} from "../../../Space";
import {get as getWidth} from "../../../utils/width";
import {getType as getRoomType} from "../../../utils/room";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import SiteAppBar from "../Menu/SiteAppBar";

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
        padding: 0,
        margin: 0,
    },
    sectionDivider: {
        marginTop: 10,
        marginBottom: 20,
    },
    sectionTitle: {
        paddingBottom: 20,
    },
}));

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param hideMiddleMenu
 * @returns {JSX.Element}
 * @constructor
 */
const SelectCorpuses = ({fullScreenHandle, onClickSteps, hideMiddleMenu = false}) => {
    const contextRef = createRef();
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorTexts, setErrorText] = useState([]);
    const setStep = useSteps(state => state.setStep);
    const classes = useStyles();
    let selectedSpace = useStore(state => state.selectedSpace);
    const setSpace = useStore(state => state.setSpace);
    const selected = useStore(state => state.selectedCorpuses[selectedSpace]);
    const spaceHeight = useStore(state => state.spaceHeight);
    const setCorpusType = useStore(state => state.setCorpusType);
    const setPriceVersion = useStore(state => state.setPriceVersion);
    const spacesCount = useStore(state => state.spacesCount);
    const closeModal = useSteps(state => state.closeModal);
    let isVariantsModalOpen = useSteps(state => state.isModalOpen['selectCorpusVariants']);
    const objType = useStore(state => state.objType);
    let activeTab = useSteps(state => state.activeTab['selectCorpuses']);

    if (spacesCount === 1) {
        activeTab = '2';
    }

    const setActiveTab = useSteps(state => state.setActiveTab);
    let dividedSpaces = JSON.parse(useStore(state => state.dividedSpaces));
    const dividedSpacesWidth = JSON.parse(useStore(state => state.dividedSpacesWidth));
    const width = useStore(state => state.width);
    const selectedRoomType = useStore(state => state.roomType);
    const selectedCorpuses = useStore(state => state.selectedCorpuses);

    const onClickStepsModified = onClickSteps;

    let roomType = useMemo(() => {
        return getRoomType(selectedRoomType, objType);
    }, [selectedRoomType, objType]);

    onClickStepsModified.next = useCallback(
        (history) => {
            const errorTexts = [];
            let seq = 1;

            const basicSpaceWidth = getSpaceWidth(objType, width, spacesCount, roomType);

            if (basicSpaceWidth > 100) {
                dividedSpaces = {}

                for (let spaceSeq = 1; spaceSeq <= spacesCount; spaceSeq++) {
                    dividedSpaces[spaceSeq] = true;
                }
            }

            for (let spaceId = 1; spaceId <= spacesCount; spaceId++) {
                if (dividedSpaces[spaceId]) {
                    if (!selectedCorpuses[spaceId + 'A']) {
                        errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace("{0}", seq));
                    }
                    seq++;

                    if (!selectedCorpuses[spaceId + 'B']) {
                        errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace("{0}", seq));
                    }
                } else {
                    if (!selectedCorpuses[spaceId]) {
                        errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace("{0}", seq));
                    }
                }

                seq++;
            }

            setErrorText(errorTexts);

            if (Object.keys(errorTexts).length) {
                setOpenErrorDialog(true);
            } else {
                setStep(onClickSteps.nextStep);
                history.push('/' + Lang(onClickSteps.nextStep, 'navigation'));
            }
        },
        [objType, roomType, onClickSteps, dividedSpaces, selectedCorpuses, setStep, setErrorText, setOpenErrorDialog, spacesCount]
    );

    const onHandleChange = (event, tabValue) => {
        setActiveTab('selectCorpuses', tabValue);
    }

    if (!selectedSpace) selectedSpace = 1;

    const basicSpaceWidth = getSpaceWidth(objType, width, spacesCount, roomType);

    const {actualId, nextId, selectedSpaceId} = useMemo(() => {
            return getCorpusesSpaces(basicSpaceWidth, spacesCount, dividedSpaces, selectedSpace);
        },
        [spacesCount, dividedSpaces, selectedSpace]
    );

    const defaultSpaceWidth = useMemo(() => {
            const wardrobeWidth = getWidth(width, spacesCount);

            return getSpaceWidth(objType, wardrobeWidth, spacesCount, roomType, 'corpuse');
        },
        [objType, width, spacesCount, roomType]
    );

    const spaceWidth = useMemo(() => {
            return getCorpusesSpaceWidth(selectedSpaceId, actualId, defaultSpaceWidth, dividedSpaces, dividedSpacesWidth);
        },
        [selectedSpaceId, actualId, defaultSpaceWidth, dividedSpaces, dividedSpacesWidth]
    );

    const panes2 = useMemo(() => {
        const newPanes = [];

        const onSelect = key => {
            setCorpusType(selectedSpace, key);
            setSpace(nextId);
            setPriceVersion();
        }

        let isFirst = true;

        CORPUSES_CATEGORIES.forEach(function (category, index) {
            newPanes.push({
                spacesCount: spacesCount,
                isFirst: isFirst,
                menuItem: Lang(category),
                render: () =>
                    <div id={index + '-' + spaceWidth}>
                        <CorpusesMenu
                            spaceWidth={spaceWidth}
                            spaceHeight={spaceHeight}
                            onSelect={onSelect}
                            category={category}
                            selected={selected}
                            actualCorpusesSpaceId={actualId}
                        />
                    </div>
            });

            isFirst = false;
        });

        return newPanes;
    }, [setCorpusType, setPriceVersion, spaceHeight, selectedSpace, selected, spacesCount, setSpace, actualId, nextId, spaceWidth]);

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    return (
        <div ref={contextRef}>
            <TopMenu mainStep="selectCorpuses"/>
            {!hideMiddleMenu && <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickStepsModified}/>}
            <Card>
                <TabContext value={activeTab}>
                    <SiteAppBar title={Lang('SelectCorpusesTitle')} onClickSteps={onClickSteps}>
                        <TabList textColor="primary" onChange={onHandleChange} className={classes.tabs}>
                            <Tab value="1" label={Lang('CorpusesVariantsTabName')}/>
                            <Tab value="2" label={Lang('SelectOwnDoorsTabName')}/>
                        </TabList>
                    </SiteAppBar>
                    <TabPanel value="1">
                        <SelectCorpusesVariants
                            isModalOpen={isVariantsModalOpen}
                            onCloseModal={() => closeModal('selectCorpusVariants')}
                            basicSpaceWidth={basicSpaceWidth}
                            spaceHeight={spaceHeight}
                            spacesCount={spacesCount}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        {panes2.map((section) => (
                            <>
                                {!section.isFirst && <Divider className={classes.sectionDivider}/>}
                                <Typography
                                    color="inherit"
                                    variant='h5'
                                    className={classes.sectionTitle}
                                >
                                    {section.menuItem}
                                </Typography>
                                {section.render()}
                            </>
                        ))}
                    </TabPanel>
                </TabContext>
            </Card>
            <VerticalSidebar onClickSteps={onClickStepsModified}/>
            <ErrorDialog
                openDialogErrors={errorTexts}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
                additionalStartText={Lang('AllSpacesMustHaveCorpusesInfo')}
            />
        </div>
    );
};

export default SelectCorpuses;