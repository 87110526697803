import React, {useEffect, useState} from "react";
import Preview from "../../../Preview/Preview";
import {Sticky} from "semantic-ui-react";
import {useStore} from "../../../store";

/**
 * @returns {*}
 * @constructor
 */
const SelectDTD = ({contextRef}) => {
    const [isVisible, setVisible] = useState(false);
    const objType = useStore(state => state.objType);
    const selectedDTDType = useStore(state => state.selectedDTDType);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    const scrollHandler = () => {
        setVisible((window.pageYOffset > 400))
    }

    if (isVisible && selectedDTDType === 'main' && objType !== 'd') {
        return (
            <Sticky context={contextRef} offset={50}>
                <Preview/>
            </Sticky>
        );
    } else {
        return (
            <>
            </>
        );
    }
};

export default SelectDTD;