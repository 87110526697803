import React, {useCallback, useState} from "react";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import AttributesForm from "../Menu/AttributesForm";
import TopMenu from "../Menu/TopMenu";
import {Container, Paper} from "@material-ui/core";
import {Lang} from "../../../Utils";
import {makeStyles} from "@material-ui/core/styles";
import MiddleMenu from "../Menu/MiddleMenu";
import {useStore} from "../../../store";
import {controlParametersInputs, getControlData, getMaxDifferenceHeightValue} from "../../../utils/control";
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import {useSteps} from "../../../Stores/Steps";
import {getParametersHeightInputs} from "../../../utils/parameters";
import SiteAppBar from "../Menu/SiteAppBar";
import {Redirect} from "react-router-dom";
import {START_STEP} from "../../../config";

const useStyles = makeStyles({
    mainContainer: {
        paddingTop: 20,
        paddingBottom: 20,
    },
});

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @returns {JSX.Element}
 * @constructor
 */
const SetWardrobeParameters = ({fullScreenHandle, onClickSteps}) => {
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorTexts, setErrorText] = useState({});
    const parameters = JSON.parse(useStore(state => state.parameters));
    const classes = useStyles();
    const selectedRoomType = useStore(state => state.roomType);
    const setStep = useSteps(state => state.setStep);
    const setShowAttributes = useStore(state => state.setShowAttributes);
    const objType = useStore(state => state.objType);
    const controlData = getControlData(null, objType);
    const maxDifferenceHeightValue = getMaxDifferenceHeightValue();

    const heightInputs = getParametersHeightInputs(selectedRoomType);

    const controlInputs = useCallback(() => {
            const errorTexts = controlParametersInputs(objType, parameters, heightInputs, controlData);

            setErrorText(errorTexts);
        }, [parameters, heightInputs, controlData, setErrorText]
    );

    const onClickStepsModified = onClickSteps;

    onClickStepsModified.next = useCallback(
        (history) => {
            const errorTexts = controlParametersInputs(objType, parameters, heightInputs, controlData, true);

            if (Object.keys(errorTexts).length) {
                setErrorText(errorTexts);
                setOpenErrorDialog(true);
            } else {
                setShowAttributes('parameters', true);
                setStep(onClickSteps.nextStep);
                history.push('/' + Lang(onClickSteps.nextStep, 'navigation'));
            }
        },
        [parameters, heightInputs, controlData, onClickSteps, setShowAttributes, setStep, setOpenErrorDialog, setErrorText]
    );

    const openDialogErrors = [];

    if (errorTexts) {
        const controlInputs = [{type: "Width"}];

        if (objType !== 'd') {
            controlInputs.push({type: "Depth"})
        }

        controlInputs.push({type: "Height"})

        controlInputs.map((inputData) => {
            if (errorTexts[inputData.type]) {
                const inputLabel = Lang(inputData.type);
                let label = '';

                if (errorTexts[inputData.type].type === 'IsEmpty') {
                    label = Lang('InputCanNotBeEmpty', 'inputs').replace("{0}", inputLabel);
                } else if (errorTexts[inputData.type].type === 'MinWardrobeError') {
                    label = Lang(errorTexts[inputData.type].type, 'inputs').replace("{0}", inputLabel).replace("{1}", controlData[inputData.type]['min']);
                } else if (errorTexts[inputData.type].type === 'MaxWardrobeError') {
                    label = Lang(errorTexts[inputData.type].type, 'inputs').replace("{0}", inputLabel).replace("{1}", controlData[inputData.type]['max']);
                } else if (errorTexts[inputData.type].label) {
                    label = errorTexts[inputData.type].label;
                }

                openDialogErrors.push(label);
            }

            return true;
        })
    }

    if (objType === '') {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    return (
        <>
            <TopMenu mainStep="setWardrobeParameters"/>
            <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickStepsModified}/>
            <Paper>
                <SiteAppBar title={Lang('roomTitle')} onClickSteps={onClickStepsModified}/>
                <Container className={classes.mainContainer}>
                    <AttributesForm
                        parameters={parameters}
                        heightInputs={heightInputs}
                        maxDifferenceHeightValue={maxDifferenceHeightValue}
                        controlInputs={controlInputs}
                        errorTexts={errorTexts}
                    />
                </Container>
            </Paper>
            <VerticalSidebar onClickSteps={onClickStepsModified}/>
            <ErrorDialog openDialogErrors={openDialogErrors} openErrorDialog={openErrorDialog}
                         setOpenErrorDialog={setOpenErrorDialog}
                         additionalStartText={Lang('AllAttributesMustHaveBeenCorrectInfo')}/>
        </>
    );
};

export default SetWardrobeParameters;