import React from 'react';
import {connect} from "react-redux";
import {Modal, CircularProgress, Typography} from "@material-ui/core";
import {Alert} from '@material-ui/lab';
import {Lang} from "../../../Utils";
import CheckIcon from '@material-ui/icons/Check';

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({});

class SubmitOrder extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.savedOrder && this.props.savedOrder.returnOrderPage) {
            window.location.replace(this.props.savedOrder.returnOrderPage);
        }
    }

    render() {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={this.props.classes.modal}
                open={this.props.isModalOpen ?? false}
                closeAfterTransition
                disableEnforceFocus
                disableBackdropClick
            >
                <>
                    {!this.props.savedOrder && (
                        <div>
                            <CircularProgress/>
                        </div>
                    )}
                    {this.props.savedOrder && (
                        <div className={this.props.classes.paper}>
                            <Alert icon={<CheckIcon fontSize="large"/>} severity="success">
                                <Typography variant="h6">
                                    {Lang('OrderWasSuccessfullyCreated')}
                                </Typography>
                            </Alert>
                        </div>
                    )}
                </>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitOrder)