export const NAVIGATION_LANG = {
    basicSettings: "zakladneNastavenie",
    selectCorpusesSetSpacesCount: "vyberPoctuDvier",
    selectCorpuses: "skladanieVnutra",
    selectDTD: "vyberDrevodekoru",
    selectDoorSpacesCount: "vyberPoctuPriestorov",
    selectProfiles: "vyberProfilu",
    selectDoorsFill: "vyberVyplniDvier",
    roomType: "vyberTypuPodorisu",
    setWardrobeParameters: "zadavanieParametrov",
    accessories: "doplnky",
    priceCalculation: "cenovaKalkulacia",
    order: "objednanie",
    selectDTDIn: 'vyberFarbyVnutra',
    selectDTDOut: 'vyberFarbyPreViditelneCasti',
}