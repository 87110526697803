export const PROFILES_LANG = {
  alOpen: "Profil AL otevřený",
  alClosed: "Profil AL zavřený",
  alMinimax: "Profil AL MINIMAX",
  white: "Profil bílý",
  sampan: "Profil šampaň",
  silver: "Profil stříbrný",
  gold: "Profil zlatý",
  silverMinimax: "Profil stříbrný Minimax",
  PleaseSelectProfile: "Prosím vyberte typ a barvu profilu",
  alOpenMobile: "Otevřený",
  alClosedMobile: "Zavřený",
  alMinimaxMobile: "MINIMAX",
};
