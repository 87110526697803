export const TABS_LANG = {
    'EGEER Standard': "DŘEVODEKOR",
    'Mirrors': "ZRCADLA",
    'Glasses': "LACOBEL – FAREBNÉ SKLÁ",
    'EGEER Exclusive': "DŘEVODEKOR – EXCLUSIVE",
    'EGEER StandardMobile': "DŘEVODEKOR",
    'MirrorsMobile': "ZRCADLA",
    'GlassesMobile': "LACOBEL <br/> FAREBNÉ SKLÁ",
    'EGEER ExclusiveMobile': "DŘEVODEKOR <br/> EXCLUSIVE",
};
