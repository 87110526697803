import create from "zustand";
import {MUST_SET_SPACES_COUNT_BEFORE, NEXT_STEP} from "../config";
import {apiStore} from "../store";
import ReactPixel from "react-facebook-pixel";
import {START_STEP} from "../config";

export const [useSteps, apiSteps] = create((set, get) => {
    return {
        actualStep: START_STEP,
        isAnyModalOpen: false,
        isModalOpen: {selectCorpuses: false, selectCorpusVariants: false},
        openCorpusVariants: true,
        isDev: false,
        activeTab: {
            'selectCorpuses': '1',
            'selectDoorSpacesCount' : '1',
        },
        setIsDev(isDevValue) {
            set({isDev: isDevValue});
        },
        setNextStep() {
            set({actualStep: "setSpacesCount"});
        },
        setRoomStep() {
            set({actualStep: "room"});
        },
        setOpenCorpusVariants(value) {
            set({openCorpusVariants: value});
        },
        openModal(type) {
            const {isModalOpen} = get();

            isModalOpen[type] = true;

            set({isModalOpen, isAnyModalOpen: true});
        },
        closeModal(type) {
            const {isModalOpen} = get();

            isModalOpen[type] = false;

            let isAnyModalOpen = false;

            Object.values(isModalOpen).forEach(function (status) {
                if (status) {
                    isAnyModalOpen = true;
                }
            })

            set({isModalOpen, isAnyModalOpen});
        },
        setStep(step) {
            let {actualStep} = get();

            if (actualStep !== step) {
                const priceValue = apiStore.getState().priceValue;
                const spacesCount = apiStore.getState().spacesCount;
                const selectedSpace = apiStore.getState().selectedSpace;
                const selectedDoorsSpace = apiStore.getState().selectedDoorsSpace;

                if (spacesCount < 2 && MUST_SET_SPACES_COUNT_BEFORE[step]) {
                    step = MUST_SET_SPACES_COUNT_BEFORE[step];
                }

                if (step === 'selectDoors') {
                    let count = 0;
                    if (typeof selectedDoorsSpace[selectedSpace] !== "undefined") {
                        count = selectedDoorsSpace[selectedSpace];
                    }

                    if (!count && selectedSpace) {
                        step = 'selectDoorSpacesCount';
                    }
                }

                if (actualStep === 'setWardrobeParameters') {
                    //ReactPixel.track('InitiateCheckout', {value: priceValue, currency: 'EUR',});
                }
            } else {
                step = null;
            }


            set({
                actualStep: step
            });
        },

        getNextStep(objType) {
            let response = false;
            let disabled = false;
            let {actualStep} = get();

            if (NEXT_STEP[objType]) {
                let nextStep = NEXT_STEP[objType][actualStep];

                if (nextStep) {
                    if ('selectCorpuses' === actualStep) {
                        const isAllCorpusesSet = apiStore.getState().isAllCorpusesSet();

                        if (!isAllCorpusesSet) {
                            //disabled = true;
                        }
                    }

                    if ('selectDTD' === actualStep) {
                        const mainDTDId = apiStore.getState().mainDTDId;

                        if (!mainDTDId) {
                            // disabled = true;
                        }
                    }

                    if ('selectSecondDTD' === actualStep) {
                        const secondDTDId = apiStore.getState().secondDTDId;

                        if (!secondDTDId) {
                            //disabled = true;
                        }
                    }

                    response = {disabled: disabled, key: nextStep}
                }
            }
            return response;
        },
        setActiveTab(tabsName, tabId) {
            const {activeTab} = get();

            activeTab[tabsName] = tabId;

            set({activeTab});
        },
    };
});
