import * as THREE from "three";
import React, {useMemo} from "react";

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @param {Boolean} useBackWalls
 * @returns {JSX.Element}
 * @constructor
 */
const Floor = ({roomAttributes, wardrobeAttributes, useBackWalls}) => {
    const roomType = roomAttributes.type;
    const roomWidth = roomAttributes.width;
    const roomHeight = roomAttributes.height;
    const roomDepth = roomAttributes.depth;
    const wardrobeDepth = wardrobeAttributes.depth;

    const mapTexture = useMemo(() => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_col.jpg'), []);
    const lightMapTexture = useMemo(() => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_rgh.jpg'), []);
    const bumpMapTexture = useMemo(() => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_AO.jpg'), []);

    const repeatTexture = (texture, repeatNum) => {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(repeatNum, 4);

        return texture;
    };

    const repeatNum = useMemo(() => 0.5 + Math.abs(Math.floor(roomWidth / 100)), [roomWidth]);

    const map = useMemo(() => repeatTexture(mapTexture, repeatNum), [mapTexture, repeatNum]);
    const lightMap = useMemo(() => repeatTexture(lightMapTexture, repeatNum), [lightMapTexture, repeatNum]);
    const bumpMap = useMemo(() => repeatTexture(bumpMapTexture, repeatNum), [bumpMapTexture, repeatNum]);

    const positionX = useMemo(() => (roomType === "c" || roomType === "d" || roomType === "a" || roomAttributes.backWallType === "b") ? -100 : 0, [roomType, roomAttributes.backWallType]);
    const positionY = useMemo(() => 0 - roomHeight / 2, [roomHeight]);
    let positionZ = useMemo(() => roomDepth / 2 - wardrobeDepth / 2, [roomDepth, wardrobeDepth]);

    let floorHeight = roomDepth;

    if (useBackWalls) {
        floorHeight -= wardrobeDepth;
        positionZ += wardrobeDepth / 2;
    }

    return (
        <mesh position={[positionX, positionY, positionZ]} rotation={[0 + Math.PI / 2, Math.PI, 0]}>
            <planeGeometry attach="geometry" args={[roomWidth, floorHeight]}/>
            <meshPhongMaterial
                attach="material"
                map={map}
                bumpMap={bumpMap}
                bumpScale={3}
                lightMap={lightMap}
                lightMapIntensity={0.5}
            />
        </mesh>
    );
}

export default React.memo(Floor);