import React from 'react';
import OrderTemplate from "../Components/Templates/Order";
import {connect} from "react-redux";
import {APP_SAVE_ORDER} from "../../../constants/actionTypes";
import agent from "../../../agent";
import TagManager from "react-gtm-module";

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({
    onSaveOrder: (payload) =>
        dispatch({type: APP_SAVE_ORDER, payload}),
});

const tagManagerArgs = {
    dataLayer: {
        event: 'formSend',
        type: 'objednavka'
    },
}

class Order extends React.Component {

    _onSubmitOrder = (saveData) => {
        TagManager.dataLayer(tagManagerArgs);

        this.props.onSaveOrder(agent.Order.submit(JSON.stringify(saveData)))
    }

    render() {
        return (
            <OrderTemplate
                fullScreenHandle={this.props.fullScreenHandle}
                onClickSteps={this.props.onClickSteps}
                onSubmitOrder={this._onSubmitOrder}
                savedOrder={this.props.savedOrder}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)