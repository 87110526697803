import {apiOrder} from "../Stores/Order";
import {apiStore} from "../store";
import {getType as getRoomType} from "./room";
import {getParametersDoorSpaceHeights, getWardrobeAttributes} from "./parameters";
import {getDoorFillHeightId} from "./door";
import {MIN_DOOR_FILL_HEIGHT} from "../config";
import {apiSteps} from "../Stores/Steps";
import {getSpaceWidth} from "../Space";

export function getSendData() {
    const insert = apiOrder.getState().insert;
    const actualStep = apiSteps.getState().actualStep;
    const objId = apiOrder.getState().objId;
    const webUUId = apiOrder.getState().getUUId();
    const uuid = apiStore.getState().uuid;
    const width = apiStore.getState().width;
    const height = apiStore.getState().height;
    const depth = apiStore.getState().depth;
    const priceVersion = apiStore.getState().priceVersion;
    const selectedRoomType = apiStore.getState().roomType;
    const selectedObjType = apiStore.getState().objType;
    const spacesCount = apiStore.getState().spacesCount;
    const mainDTDId = apiStore.getState().mainDTDId;
    const secondDTDId = apiStore.getState().secondDTDId;
    const doorFillsIds = apiStore.getState().doorFillsIds;
    const selectedCorpuses = apiStore.getState().selectedCorpuses;
    let dividedSpaces = JSON.parse(apiStore.getState().dividedSpaces);
    let dividedSpacesWidth = JSON.parse(apiStore.getState().dividedSpacesWidth);
    const additionalOptionData = JSON.parse(apiStore.getState().additionalOption);
    const selectedDoorsSpace = apiStore.getState().selectedDoorsSpace;
    const selectedDoorsSpaceAttributes = apiStore.getState().selectedDoorsSpaceAttributes;
    const selectedProfile = apiStore.getState().selectedProfile;
    const selectedProfileId = apiStore.getState().selectedProfileId;
    const doorFillsAttributes = JSON.parse(apiStore.getState().doorFillsAttributes);
    const doorFills = apiStore.getState().doorFills;
    const doorFillsData = apiStore.getState().doorFillsData;
    const phoneNumber = apiStore.getState().phoneNumber;
    const emailAddress = apiStore.getState().emailAddress;
    const parameters = apiStore.getState().parameters;

    const roomType = getRoomType(selectedRoomType, selectedObjType);

    const basicSpaceWidth = getSpaceWidth(selectedObjType, width, spacesCount, roomType);

    if (basicSpaceWidth > 100) {
        dividedSpaces = {}

        for (let spaceSeq = 1; spaceSeq <= spacesCount; spaceSeq++) {
            dividedSpaces[spaceSeq] = true;
        }

        dividedSpacesWidth = {}
    }

    const wardrobeAttributes = getWardrobeAttributes({
        width: width,
        height: height,
        depth: depth,
        type: roomType,
        spacesCount: spacesCount,
    });

    const doorSpacesHeights = {};

    for (let doorId = 0; doorId < wardrobeAttributes.spacesCount; doorId++) {
        const doorSpacesHeight = {};
        const doorFillsCount = selectedDoorsSpace[doorId + 1];

        for (let spaceId = 0; spaceId < wardrobeAttributes.spacesCount; spaceId++) {
            if (doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)] && 0 < doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)]) {
                const spaceHeight = doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)];

                if (spaceHeight && spaceHeight >= MIN_DOOR_FILL_HEIGHT) {
                    doorSpacesHeight[spaceId] = spaceHeight;
                }
            }
        }

        doorSpacesHeights[doorId] = getParametersDoorSpaceHeights(wardrobeAttributes.height, wardrobeAttributes.spacesCount, doorSpacesHeight);
    }

    const sendData = {
        step: actualStep,
        insert: insert,
        objId: objId,
        webUUId: webUUId,
        uuid: uuid,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        width: wardrobeAttributes.width,
        height: wardrobeAttributes.height,
        depth: wardrobeAttributes.depth,
        roomType: wardrobeAttributes.type,
        objType: selectedObjType,
        spacesCount: wardrobeAttributes.spacesCount,
        isSpacesCountSet: (spacesCount && spacesCount > 0),
        doorSpacesHeights: doorSpacesHeights,
        doorFillsAttributes: doorFillsAttributes,
        doorFillsData,
        priceVersion,
        doorFillsIds,
        mainDTDId,
        secondDTDId,
        selectedCorpuses: selectedObjType !== 'd' ? selectedCorpuses : {},
        dividedSpaces,
        dividedSpacesWidth,
        doorsSpace: selectedDoorsSpace,
        selectedDoorsSpaceAttributes: selectedDoorsSpaceAttributes,
        selectedProfile,
        selectedProfileId,
        additionalOptionData,
        parameters,
    }

    if (doorFills) {
        const doorFillsProduct = {};

        Object.keys(doorFills).map((doorFillId) => {
            doorFillsProduct[doorFillId] = doorFills[doorFillId].productId;
        });

        sendData['doorFills'] = doorFillsProduct;
    }

    if (doorFillsAttributes) {
        sendData['doorFillsAttributes'] = doorFillsAttributes;
    }

    return sendData;
}