import React, {createRef, useCallback, useMemo, useState} from "react";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import SelectFill from "../Menu/SelectFill";
import TopMenu from "../Menu/TopMenu";
import {useStore} from "../../../store";
import {Card} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {Lang} from "../../../Utils";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import {useSteps} from "../../../Stores/Steps";

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param hideMiddleMenu
 * @param productImages
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDoorsFill = ({fullScreenHandle, onClickSteps, hideMiddleMenu, productImages}) => {
    const contextRef = createRef();
    const mainSize = useStore(state => state.mainSize);
    const objType = useStore(state => state.objType);
    const spacesCount = useStore(state => state.spacesCount);
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const setSelectedDTDType = useStore(state => state.setSelectedDTDType);
    const handleTabChange = (e, {activeIndex}) => setSelectedDTDType(!activeIndex ? 'main' : 'second');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorTexts, setErrorText] = useState([]);
    const setStep = useSteps(state => state.setStep);
    const getDoorFill = useStore(state => state.getDoorFill);
    const selectedDoorsSpace = useStore(state => state.selectedDoorsSpace);

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    const onClickStepsModified = onClickSteps;

    onClickStepsModified.next = useCallback(
        (history) => {
            const errorTexts = [];

            for (let doorId = 1; doorId <= spacesCount; doorId++) {
                const badDoorFills = [];
                let doorFillsCount = 1;

                if (selectedDoorsSpace[doorId]) {
                    doorFillsCount = selectedDoorsSpace[doorId];
                }

                for (let num = 0; num < doorFillsCount; num++) {

                    const doorFillId = num + 1;
                    const doorFill = getDoorFill(doorId + '-' + doorFillId);

                    if (!doorFill) {
                        badDoorFills.push(doorFillId + '.');
                    }
                }

                const badDoorFillsCount = Object.values(badDoorFills).length;

                if (badDoorFillsCount) {
                    let doorFillText = '';

                    if (badDoorFillsCount === 1) {
                        if (doorFillsCount !== 1) {
                            doorFillText = badDoorFills[0];
                        }
                    } else if (badDoorFillsCount === 2) {
                        doorFillText = Lang('DoorFillsAreEmptyErrorAnd').replace("{0}", badDoorFills[0]).replace("{1}", badDoorFills[1]);
                    } else {
                        const lastDoorFillId = badDoorFills[badDoorFillsCount - 1];
                        delete badDoorFills[badDoorFillsCount - 1];
                        const doorFills = Object.values(badDoorFills).join(", ");

                        doorFillText = Lang('DoorFillsAreEmptyErrorAnd').replace("{0}", doorFills).replace("{1}", lastDoorFillId);
                    }

                    errorTexts.push(Lang('DoorFillsAreEmptyError').replace("{0}", doorId).replace("{1}", doorFillText));
                }
            }

            setErrorText(errorTexts);

            if (Object.keys(errorTexts).length) {
                setOpenErrorDialog(true);
            } else {
                setStep(onClickSteps.nextStep);
                history.push('/' + Lang(onClickSteps.nextStep, 'navigation'));
            }
        },
        [onClickSteps, getDoorFill, setStep, selectedDoorsSpace, spacesCount]
    );

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
    }

    return (
        <div ref={contextRef}>
            <TopMenu mainStep="selectDoors"/>
            {!hideMiddleMenu && <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickStepsModified}/>}
            <Card>
                <SelectFill
                    contextRef={contextRef}
                    mainSize={mainSize}
                    objType={objType}
                    handleTabChange={handleTabChange}
                    selectedDTDType={selectedDTDType}
                    onClickSteps={onClickStepsModified}
                    productImages={productImages}
                />
            </Card>
            <VerticalSidebar onClickSteps={onClickStepsModified}/>
            <ErrorDialog
                openDialogErrors={errorTexts}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
        </div>
    );
};

export default SelectDoorsFill;