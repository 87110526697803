import React, {useMemo} from "react";
import {useThree, useFrame} from "react-three-fiber";
import Floor from "./Room/Floor";
import SmallFloor from "./Room/SmallFloor";
import BackWalls from "./Room/BackWalls";
import Wall from "./Room/Wall";
import {CubeCamera, WebGLCubeRenderTarget, RGBFormat, LinearMipmapLinearFilter} from "three";
import * as THREE from "three";
import {isMobile} from "react-device-detect";

/**
 * @param {Array} roomAttributes
 * @param {Number} windowWidth
 * @returns {JSX.Element}
 * @constructor
 */
function Scenery({roomAttributes, windowWidth}) {
    const {scene, gl} = useThree();

    const cubeRenderTarget = new WebGLCubeRenderTarget(256, {
        format: RGBFormat,
        generateMipmaps: true,
        minFilter: LinearMipmapLinearFilter,
    });
    const cubeCamera = new CubeCamera(3, 1000, cubeRenderTarget);
    cubeCamera.position.set(100, 140, 1600);
    scene.add(cubeCamera);
    // Update the cubeCamera with current renderer and scene.
    useFrame(() => cubeCamera.update(gl, scene));

    return (
        <mesh visible position={[0, 0, -7]} rotation={[0, 0, 0]} castShadow>
            <planeGeometry attach="geometry" args={[windowWidth, roomAttributes.height]}/>
            <meshBasicMaterial
                attach="material"
                envMap={cubeCamera.renderTarget.texture} color="white"
                roughness={0.1}
                metalness={1}
            />
        </mesh>
    );
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
function Enviroment() {
    const geometry = new THREE.BoxGeometry(400, 400, 400);

    const loader = new THREE.TextureLoader();

    const materials = [
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/px.png'),
            side: THREE.DoubleSide
        }),
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/nx.png'),
            side: THREE.DoubleSide
        }),
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/py.png'),
            side: THREE.DoubleSide
        }),
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/ny.png'),
            side: THREE.DoubleSide
        }),
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/pz.png'),
            side: THREE.DoubleSide
        }),
        new THREE.MeshBasicMaterial({
            map: loader.load(process.env.PUBLIC_URL + '/images/textures/cube/nz.png'),
            side: THREE.DoubleSide
        }),
    ];
    const topObject = new THREE.Mesh(geometry, materials);

    return (
        <group position-y={100} position-x={300} position-z={1500}>
            <mesh>
                <primitive object={topObject}/>
            </mesh>
        </group>
    );
}

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const LivingRoom = ({roomAttributes, wardrobeAttributes}) => {
    const roomWidth = roomAttributes.width;
    const roomHeight = roomAttributes.height;
    const roomType = roomAttributes.type;
    const posX = useMemo(() => roomWidth / 2, [roomWidth]);
    const posY = useMemo(() => roomHeight / 2, [roomHeight]);
    let useBackWalls = true;

    const backWallType = roomAttributes.backWallType;

    useBackWalls = useMemo(() => {
        if (backWallType === 'a') {
            return (roomType === 'a')
        } else {
            return (roomType !== 'd')
        }
    } , [backWallType, roomType]);

    let useLeftBackWall = useBackWalls;
    let useRightBackWall = useBackWalls;

    if (backWallType !== 'a') {
        useLeftBackWall = (roomType === 'a' || roomType === 'b');
        useRightBackWall = (roomType === 'a' || roomType === 'c');
    }

    const backWall = {
        type: backWallType,
        useLeftBackWall,
        useRightBackWall,
    }

    return (
        <>
            <Enviroment/>
            <group position-x={posX} position-y={posY}>
                {useBackWalls && (
                    <SmallFloor
                        roomAttributes={roomAttributes}
                        wardrobeAttributes={wardrobeAttributes}
                    />
                )}
                <Floor
                    roomAttributes={roomAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    useBackWalls={useBackWalls}
                    backWall={backWall}
                />
                {useBackWalls && (
                    <BackWalls
                        roomAttributes={roomAttributes}
                        wardrobeAttributes={wardrobeAttributes}
                        wallPosition="back"
                        useLeftBackWall={useLeftBackWall}
                        useRightBackWall={useRightBackWall}
                    />
                )}
                {!useBackWalls && (
                    <Wall
                        roomAttributes={roomAttributes}
                        wardrobeAttributes={wardrobeAttributes}
                        wallPosition="back"
                    />
                )}
                <Wall
                    roomAttributes={roomAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    wallPosition="left"
                    windowWidth={isMobile ? 0 :120}
                    useBackWalls={useLeftBackWall}
                >
                    <>
                    {!isMobile && <Scenery roomAttributes={roomAttributes} windowWidth={120} />})
                    </>
                </Wall>
                <Wall
                    roomAttributes={roomAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    wallPosition="right"
                    useBackWalls={useRightBackWall}
                />
                <Wall
                    roomAttributes={roomAttributes}
                    wardrobeAttributes={wardrobeAttributes}
                    wallPosition="front"
                    useBackWalls={useBackWalls}
                />
            </group>
        </>
    );
};

export default LivingRoom;
