import React, {useMemo} from 'react';
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import SelectDoorSpacesCountMenu from '../Menu/SelectDoorSpacesCount';
import TopMenu from "../Menu/TopMenu";
import {useStore} from "../../../store";
import {Lang} from "../../../Utils";
import SelectDoorsVariantsMenu from "../Menu/SelectDoorsVariantsMenu";
import {makeStyles} from '@material-ui/core/styles';
import {useSteps} from "../../../Stores/Steps";
import {Paper} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {Redirect} from "react-router-dom";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";
import SiteAppBar from "../Menu/SiteAppBar";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
    },
    tabs: {
        backgroundColor: theme.palette.background.paper,
    },
}));

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param hideMiddleMenu
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDoorSpacesCount = ({fullScreenHandle, onClickSteps, hideMiddleMenu = false}) => {
    const classes = useStyles();
    const spaceHeight = useStore(state => state.spaceHeight);
    const spacesCount = useStore(state => state.spacesCount);
    const activeTab = useSteps(state => state.activeTab['selectDoorSpacesCount']);
    const setActiveTab = useSteps(state => state.setActiveTab);
    let selectedSpace = useStore(state => state.selectedSpace);
    const objType = useStore(state => state.objType);
    const setSpace = useStore(state => state.setSpace);

    const handleChange = (event, newValue) => {
        setSpace(1);
        setActiveTab('selectDoorSpacesCount', newValue);
    }

    if (!selectedSpace) selectedSpace = 1;

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    return (
        <>
            <TopMenu mainStep="selectDoors"/>
            {!hideMiddleMenu && <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>}
            <Paper>
                <TabContext value={activeTab}>
                    <SiteAppBar title={Lang('SelectDoorSpacesCountTitle')} onClickSteps={onClickSteps}>
                        <TabList textColor="primary" onChange={handleChange} className={classes.tabs}>
                            <Tab label={Lang('SelectVariantsDoorsTabName')} value="1"/>
                            <Tab label={Lang('SelectOwnDoorsTabName')} value="2"/>
                        </TabList>
                    </SiteAppBar>
                    <TabPanel value="1">
                        <SelectDoorsVariantsMenu spaceHeight={spaceHeight} spacesCount={spacesCount}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <SelectDoorSpacesCountMenu selectedSpace={selectedSpace}/>
                    </TabPanel>
                </TabContext>
            </Paper>
            <VerticalSidebar onClickSteps={onClickSteps}/>
        </>
    );
};

export default SelectDoorSpacesCount;