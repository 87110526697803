import React, {useCallback, useMemo, useState} from "react";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import SelectSpacesCount from "../Menu/SelectSpacesCount";
import TopMenu from "../Menu/TopMenu";
import {Lang} from "../../../Utils";
import {makeStyles} from "@material-ui/core/styles";
import {useStore} from "../../../store";
import {Redirect} from "react-router-dom";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";
import {Container, Paper} from "@material-ui/core";
import SiteAppBar from "../Menu/SiteAppBar";
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import {useSteps} from "../../../Stores/Steps";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingTop: 20,
    },
}));

/**
 * @returns {*}
 * @constructor
 */
const SelectCorpusesSetSpacesCount = ({fullScreenHandle, onClickSteps}) => {
    const classes = useStyles();
    const objType = useStore(state => state.objType);
    const spacesCount = useStore(state => state.spacesCount);
    const setStep = useSteps(state => state.setStep);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorTexts, setErrorText] = useState([]);

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    const onClickStepsModified = onClickSteps;

    onClickStepsModified.next = useCallback(
        (history) => {
            const errorTexts = [];

            if (!spacesCount) {
                let errorKey = 'SpacesCountIsEmptyError';

                if (objType === 'v') errorKey += 'V';

                errorTexts.push(Lang(errorKey));
            }

            setErrorText(errorTexts);

            if (Object.keys(errorTexts).length) {
                setOpenErrorDialog(true);
            } else {
                setStep(onClickSteps.nextStep);
                history.push('/' + Lang(onClickSteps.nextStep, 'navigation'));
            }
        },
        [onClickSteps, setStep, spacesCount, objType]
    );

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    return (
        <>
            <TopMenu mainStep="selectCorpuses"/>
            <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickStepsModified}/>
            <Paper>
                <SiteAppBar title={Lang(objType === 'v' ? 'SelectSpacesCountTitle' : 'SelectDoorsCountTitle')} onClickSteps={onClickSteps}/>
                <Container className={classes.mainContainer}>
                    <SelectSpacesCount/>
                </Container>
            </Paper>
            <VerticalSidebar onClickSteps={onClickSteps}/>
            <ErrorDialog
                openDialogErrors={errorTexts}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
        </>
    );
};

export default SelectCorpusesSetSpacesCount;