import React, {useMemo} from "react";
import {useStore} from "../../../../store";
import {Button, Container} from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import {Lang} from "../../../../Utils";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import {makeStyles} from "@material-ui/core/styles";
import Preview from "../../../../Preview/Preview";
import {getCorpusesSpaces} from "../../../../utils/corpuses";
import {getSpaceWidth} from "../../../../Space";

const useStyles = makeStyles((theme) => ({
    rootRight: {
        flexGrow: 1,
    },
    mainDoorContainer: {
        padding: 10,
    },
}));

/**
 * @param selectedSpace
 * @param handleNext
 * @param handleBack
 * @param cameraZ
 * @returns {JSX.Element}
 * @constructor
 */
const CorpusesStepper = ({selectedSpace, handleNext, handleBack, cameraZ = null}) => {
    const classes = useStyles();
    const priceVersion = useStore(state => state.priceVersion);
    const spacesCount = useStore(state => state.spacesCount);
    const dividedSpaces = JSON.parse(useStore(state => state.dividedSpaces));
    const roomWidth = useStore(state => state.width);
    const roomType = useStore(state => state.roomType);
    const objType = useStore(state => state.objType);
    const spaceWidth = getSpaceWidth(objType, roomWidth, spacesCount, roomType);

    const {totalSteps, backId, actualSeq, nextId} = useMemo(() => {
            return getCorpusesSpaces(spaceWidth, spacesCount, dividedSpaces, selectedSpace)
        },
        [spacesCount, dividedSpaces, selectedSpace]
    );

    return (
        <div className={classes.rootRight}>
            <MobileStepper
                key={'MobileStepper' + priceVersion}
                steps={totalSteps}
                position="static"
                variant="text"
                activeStep={actualSeq}
                nextButton={
                    <Button
                        size="small"
                        onPointerDown={e => handleNext(e, nextId)}
                        disabled={nextId === null}
                    >
                        {Lang('NextDoor')}
                        <KeyboardArrowRight/>
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onPointerDown={e => handleBack(e, backId)}
                        disabled={backId === null}
                    >
                        <KeyboardArrowLeft/>
                        {Lang('BackDoor')}
                    </Button>
                }
            />
            <Container className={classes.mainDoorContainer}>
                <Preview cameraType='orthographic' name='corpuses' cameraZ={cameraZ}/>
            </Container>
        </div>
    );
};

export default CorpusesStepper;