import create from "zustand";

export const [useOrder, apiOrder] = create((set, get) => {
    return {
        webUUId: '',
        insert: false,
        objId: null,
        objNr: null,
        setInsert(insert) {
            set({insert});
        },
        setObjId(objId) {
            set({objId});
        },
        setUUId(newWebUUId) {
            set({webUUId: newWebUUId});
        },
        getUUId() {
            const {webUUId} = get();

            return webUUId;
        },
    };
});
