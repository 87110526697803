import {applyMiddleware, createStore} from 'redux';
//import {createLogger} from 'redux-logger'
//import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {promiseMiddleware, localStorageMiddleware} from './middleware';
import reducers from './reducers';

const getMiddleware = () => {
    //if (false) {
        return applyMiddleware(promiseMiddleware, localStorageMiddleware);
    //} else {
    //    return applyMiddleware(promiseMiddleware, localStorageMiddleware, createLogger())
    //}
}

const store = createStore(reducers, getMiddleware())

export default store;