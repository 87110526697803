import React, {useMemo} from "react";
import {BOARD_DEPTH} from "../../config";
import Board from "./Board";

/**
 * @param {String} objType
 * @param {String}  roomType
 * @param {Object} wardrobeAttributes
 * @param {Object} roomAttributes
 * @param {Number} posY
 * @param {Boolean} showBoxHelper
 * @returns {JSX.Element}
 * @constructor
 */
const HorizontalBoard = ({objType, roomType, wardrobeAttributes, roomAttributes, posY, showBoxHelper = false}) => {
    const width = useMemo(() => wardrobeAttributes.depth - wardrobeAttributes.freeSpace, [wardrobeAttributes.depth, wardrobeAttributes.freeSpace]);

    const height = useMemo(() => {
        let height = wardrobeAttributes.width;

        if ('v' !== objType) {
            if (roomType === "b" || roomType === "c") {
                height -= BOARD_DEPTH;
            } else if (!roomType || roomType === "d") {
                height -= BOARD_DEPTH * 2;
            }
        }

        return height;
    }, [objType, roomType, wardrobeAttributes.width]);

    const positionX = useMemo(() => {
        let positionX = 0;

        if ((!roomType || roomType === "c" || roomType === "d") && objType !== 'v') {
            positionX = BOARD_DEPTH;
        }

        return positionX;
    }, [roomType, objType]);

    return (
        <group position-y={posY} position-x={positionX} position-z={0 - wardrobeAttributes.freeSpace / 2}>
            <Board
                showBoxHelper={showBoxHelper}
                type="horizontal"
                width={width}
                height={height}
                depth={BOARD_DEPTH}
            />
        </group>
    );
};

export default HorizontalBoard;
