export function get(width, spaces) {

    if (!spaces) {
        spaces = 2;
    }

    if (!width) {
        width = spaces * 70
    }

    return width;
}
