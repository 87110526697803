import React from "react";

/**
 * @returns {*}
 * @constructor
 */
const Modals = () => {

    return (
        <>
        </>
    );
};

export default Modals;
