import React, {useCallback, useState} from "react";
import {Segment, Modal, Image, Button, Header} from "semantic-ui-react";
import {Lang} from "../../Utils";
import {useStore} from "../../store";
import {useSteps} from "../../Stores/Steps";
import {useHistory} from "react-router-dom";
import {useOrder} from "../../Stores/Order";
import {v4 as uuid} from 'uuid';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {isMobile} from "react-device-detect";
import parse from "html-react-parser";

let dataLayer = window.dataLayer = window.dataLayer || [];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 100,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const SelectTypeCard = ({typeName, imageName, onClick}) => {
    const classes = useStyles();
    const [isSelected, setSelected] = useState(false);

    return (
        <Card className={classes.root}
              onPointerDown={onClick}
              onPointerOver={() => setSelected(true)}
              onPointerOut={() => setSelected(false)}
        >
            <CardMedia
                className={classes.cover}
                image={process.env.PUBLIC_URL + '/images/Types/' + imageName}
                title={Lang(typeName)}
            />
            <div className={classes.details}>
                <CardContent>
                    <Typography component="h6" variant="h6">
                        {parse(Lang(typeName))}
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}

const SelectTypeSegment = ({typeName, imageName, onClick}) => {
    const [isSelected, setSelected] = useState(false);

    return (
        <Segment
            as="a"
            color={isSelected ? 'green' : 'grey'}
            onPointerDown={onClick}
            className="selectObjType"
            onPointerOver={() => setSelected(true)}
            onPointerOut={() => setSelected(false)}
        >
            <Image size="medium" src={process.env.PUBLIC_URL + '/images/Types/' + imageName}/>
            <Header color={isSelected ? 'green' : 'black'}>
                {parse(Lang(typeName))}
            </Header>
        </Segment>
    )
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SelectTypeModal = () => {
    const history = useHistory();
    const actualStep = useSteps(state => state.actualStep);
    const [isOpen, setOpen] = useState(actualStep !== 'load');
    const setObjType = useStore(state => state.setObjType);
    const setRoomType = useStore(state => state.setRoomType);
    const setStep = useSteps(state => state.setStep);
    const setUUId = useOrder(state => state.setUUId);

    const onClickModal = useCallback(
        (e, type) => {

            e.stopPropagation(); // stop it at the first intersection
            setOpen(false);
            setObjType(type);

            if (type === 'd') {
                setUUId(uuid());
                setRoomType('a');
                setStep('setWardrobeParameters');
                history.push('/' + Lang('setWardrobeParameters', 'navigation'));
            }

            const data = {
                event: 'objTypeSelected',
                yourData: {
                    type: type
                }
            };

            dataLayer.push(data);

            let event = '';

            switch (type) {
                case 'vd':
                    event = event + ' Komplet'
                    break;
                case 'v':
                    event = event + ' Vnutro'
                    break;
                case 'd':
                    event = event + ' Posuv'
                    break;
                default:
                    event = 'Lead'
            }
        },
        [setOpen, setObjType, setRoomType, setStep, Lang, setUUId, uuid]
    );

    if (isMobile) {
        return (
            <Modal open={isOpen}>
                <Modal.Header>{Lang("SelectTypeInfo")}</Modal.Header>
                <Modal.Content>
                    <SelectTypeCard
                        type="vd"
                        typeName="TypeVDName"
                        imageName="skrina640_470.jpg"
                        onClick={e => onClickModal(e, 'vd')}
                    />
                    <Divider />
                    <SelectTypeCard
                        type="d"
                        typeName="TypeDName"
                        imageName="posuv640x470.jpg"
                        onClick={e => onClickModal(e, 'd')}
                    />
                    <Divider />
                    <SelectTypeCard
                        type="v"
                        typeName="TypeVName"
                        imageName="vnutro640x470.jpg"
                        onClick={e => onClickModal(e, 'v')}
                    />
                </Modal.Content>
            </Modal>
        );
    } else {
        return (
            <Modal open={isOpen}>
                <Modal.Header>{Lang("SelectTypeInfo")}</Modal.Header>
                <Modal.Content>
                    <Segment.Group horizontal>
                        <SelectTypeSegment
                            type="vd"
                            typeName="TypeVDName"
                            imageName="skrina640_470.jpg"
                            onClick={e => onClickModal(e, 'vd')}
                        />
                        <SelectTypeSegment
                            type="d"
                            typeName="TypeDName"
                            imageName="posuv640x470.jpg"
                            onClick={e => onClickModal(e, 'd')}
                        />
                        <SelectTypeSegment
                            type="v"
                            typeName="TypeVName"
                            imageName="vnutro640x470.jpg"
                            onClick={e => onClickModal(e, 'v')}
                        />
                    </Segment.Group>
                </Modal.Content>
            </Modal>
        );
    }
};

export default SelectTypeModal;