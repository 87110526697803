import React, {useMemo} from "react";
import {Lang} from "../../Utils";
import {useStore} from "../../store";
import {useSteps} from "../../Stores/Steps";
import SelectCorpuses from "./Gui/SelectCorpuses";
import SelectDTD from "./Gui/SelectDTD";
import SelectDoorSpacesCount from "./Gui/SelectDoorSpacesCount";
import SelectDoorsFill from "./Gui/SelectDoorsFill";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Paper, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {connect} from "react-redux";
import {getType as getRoomType} from "../../utils/room";
import {HIDE_RIGHT_ATTRIBUTES_INFO_BLOCK} from "../../config";
import parse from 'html-react-parser';
import useInfo from "../../utils/useInfo";
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles(() => ({
    boxMainNormal: {},
    boxMainHidden: {
        display: "none",
    },
    mainList: {},
    attributeInfoPrimary: {
        color: "gray",
    },
    attributeInfoSecondary: {},
    alertInfo: {
        lineHeight: 1.8,
    }
}));

const ParametersBlockTemplate = ({contextRef, productsData, doorsFillsProducts, productImages}) => {
    const classes = useStyles();
    const objType = useStore(state => state.objType);
    const selectedRoomType = useStore(state => state.roomType);
    const actualStep = useSteps(state => state.actualStep);
    const activeTab = useSteps(state => state.activeTab[actualStep]);
    const wardrobeWidth = useStore(state => state.width);
    const wardrobeHeight = useStore(state => state.height);
    const wardrobeDepth = useStore(state => state.depth);
    const selectedProfile = useStore(state => state.selectedProfile);
    const showAttributes = useStore(state => state.showAttributes);
    const showAttributesData = JSON.parse(showAttributes);
    const showAttributesBlock = (HIDE_RIGHT_ATTRIBUTES_INFO_BLOCK.indexOf(actualStep) === -1);

    const roomType = useMemo(() => {
        return getRoomType(selectedRoomType, objType);
    }, [selectedRoomType, objType]);

    const infoBlocks = useInfo();

    let hideBlock = false;

    const stepsWithTabs = ["selectCorpuses"];
    const haveTabs = (stepsWithTabs.indexOf(actualStep) !== -1);

    if (haveTabs && activeTab && activeTab === '2') {
        hideBlock = true;
    }

    const parameters = [];

    if (showAttributesData['parameters']) {
        parameters.push({label: Lang('Width'), value: wardrobeWidth ?? ''});

        if (roomType !== 'd') {
            parameters.push({label: Lang('Depth'), value: wardrobeDepth ?? ''});
        }

        parameters.push({label: Lang('Height'), value: wardrobeHeight ?? ''});
    }

    const mainDTDId = useStore(state => state.mainDTDId);
    const secondDTDId = useStore(state => state.secondDTDId);

    const attributes = useMemo(
        () => {
            const attributesMainDTD = [];
            const attributesSecondDTD = [];
            const attributes = [];
            const attributeTitleDTDSecondLabelKey = 'AttributeTitleDTDSecond' + roomType.toUpperCase();
            const attributeTitleABSSecondLabelKey = 'AttributeTitleABSSecond' + roomType.toUpperCase();

            if (productsData && showAttributesData['dtd']) {
                Object.keys(productsData.products).forEach(function (category) {

                    productsData.products[category].map(product => {
                        if (mainDTDId.toString() === product.productid.toString() && objType !== 'd') {
                            attributesMainDTD.push({
                                label: Lang('AttributeTitleDTDMain'),
                                value: product.code + ' - ' + Lang(product.name, 'products'),
                            });

                            if (product.hrpaska) {
                                attributesMainDTD.push({
                                    label: Lang('AttributeTitleABSMain'),
                                    value: Lang(product.hrpaska.name, 'products'),
                                });
                            }
                        }

                        if (secondDTDId && secondDTDId.toString() === product.productid.toString() && objType !== 'v') {
                            attributesSecondDTD.push({
                                label: Lang(attributeTitleDTDSecondLabelKey),
                                value: product.code + ' - ' + Lang(product.name, 'products'),
                            });

                            if (product.hrpaska) {
                                attributesSecondDTD.push({
                                    label: Lang(attributeTitleABSSecondLabelKey),
                                    value: Lang(product.hrpaska.name, 'products'),
                                });
                            }
                        }

                        return true;
                    })
                })
            }

            if (Object.values(attributesMainDTD).length) {
                attributes.push(...attributesMainDTD);
            }

            if (Object.keys(attributesSecondDTD).length) {
                attributes.push(...attributesSecondDTD);
            }

            if (Object.keys(selectedProfile).length && objType !== 'v') {
                attributes.push({
                    label: Lang('Profile'),
                    value: Lang(selectedProfile.section, "profiles") + ' - ' + Lang(selectedProfile.type)
                });
            }

            return attributes;
        },
        [productsData, roomType, mainDTDId, secondDTDId, selectedProfile, showAttributesData, objType]
    );

    return (
        <>
            <Box className={hideBlock ? classes.boxMainHidden : classes.boxMainNormal}>
                {showAttributesBlock && (
                    <Paper>
                        {showAttributesData['parameters'] && (
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {parameters.map((data, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {Lang(data.label)}:</TableCell>
                                            <TableCell
                                                align="right">{data.value !== '' ? data.value + ' cm' : ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                        <List
                            className={classes.mainList}
                            dense={true}
                            disablePadding={true}
                        >
                            {attributes.map(data => (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography variant="caption"
                                                        className={classes.attributeInfoPrimary}>
                                                {Lang(data.label)}
                                            </Typography>
                                        }
                                        secondary={<Typography className={classes.attributeInfoSecondary}>
                                            {data.value ?? ''}
                                        </Typography>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                )}
                {actualStep === 'selectDoorsFill' && (
                    <SelectDoorsFill
                        contextRef={contextRef}
                        doorsFillsProducts={doorsFillsProducts}
                        productImages={productImages}
                    />
                )}
                {actualStep === 'selectDoorSpacesCount' && (
                    <SelectDoorSpacesCount contextRef={contextRef}/>
                )}
                {infoBlocks.map(infoBlock => (
                    <>
                        <br/>
                        <Alert icon={false} severity="info" className={classes.alertInfo}>
                            <AlertTitle>{Lang(infoBlock.title, 'info')}</AlertTitle>
                            {parse(Lang(infoBlock.content, 'info'))}
                        </Alert>
                    </>
                ))}
            </Box>
            {actualStep === 'selectCorpuses' && (
                <SelectCorpuses contextRef={contextRef}/>
            )}

            {actualStep === 'selectDTD' && (
                <SelectDTD contextRef={contextRef}/>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    ...state.todos,
});

const mapDispatchToProps = dispatch => ({});

class ParametersBlock extends React.Component {

    render() {

        if (isMobile) {
            return (
                <Box style={{paddingTop: 30}}>
                    {this.props.actualStep === 'selectDoorsFill' && (
                        <SelectDoorsFill
                            contextRef={null}
                            doorsFillsProducts={this.props.doorsFillsProducts}
                            productImages={this.props.productImages}
                        />
                    )}
                    {this.props.actualStep === 'selectDoorSpacesCount' && (
                        <SelectDoorSpacesCount contextRef={null}/>
                    )}
                    {this.props.actualStep === 'selectCorpuses' && (
                        <SelectCorpuses contextRef={null}/>
                    )}
                </Box>
            );
        } else {
            return (
                <ParametersBlockTemplate contextRef={this.props.contextRef} productsData={this.props.products}
                                         doorsFillsProducts={this.props.doorsFillsProducts}
                                         productImages={this.props.productImages}/>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParametersBlock)