/**
 * @param selectedSpaceId
 * @param doorFillsCount
 * @param index
 * @returns {string}
 */
export function getDoorFillId(selectedSpaceId, doorFillsCount, index) {
    return `${selectedSpaceId + 1}-${index + 1}`;
}

export function getDoorFillHeightId(selectedSpaceId, doorFillsCount, index) {
    return `${selectedSpaceId + 1}-${doorFillsCount}-${index + 1}`;
}

export function getDoorFillHeights(doorHeight, doorFillsAttributes, doorId, count) {
    let resDoorHeight = doorHeight;
    let resCount = count;
    const heights = [];

    if (doorFillsAttributes) {
        for (let num = 0; num < count; num++) {
            let doorFillHeight = 0;
            const selectedSpaceId = doorId - 1;
            const doorFillId = getDoorFillHeightId(selectedSpaceId, count, num);

            if (doorFillsAttributes[doorFillId] && doorFillsAttributes[doorFillId] >= 10) {
                doorFillHeight = doorFillsAttributes[doorFillId];
                resCount--;
                resDoorHeight -= doorFillsAttributes[doorFillId];
            }

            heights[num] = doorFillHeight;
        }

        let restDoorFill = resDoorHeight / resCount;

        if (restDoorFill < 10) restDoorFill = 10;

        for (let num = 0; num < count; num++) {
            if (heights[num] === 0) {
                heights[num] = restDoorFill;
            }
        }
    }

    return heights;
}