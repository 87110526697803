export const BUTTONS_LANG = {
    roomType: "Výber typu pôdorysu",
    setWardrobeParameters: "Zadanie parametrov",
    selectCorpusesSetSpacesCount: "Výber počtu dvier",
    selectCorpuses: "Skladanie vnútra",
    selectDTD: "Výber drevodekoru",
    selectDTDSecond: "Výber drevodekoru bočnice",
    selectDoorSpacesCount: "Výber typu dvier",
    selectProfiles: "Výber typu a farby profilu",
    selectDoorsFill: "Výber výplní dvier",
    order: "Objednávkový formulár",
    accessories: "Upraviť doplnky",
    priceCalculation: "Cenová kalkulácia",
    showPriceCalculation: "Zobraziť celkovú kalkuláciu vrátane doplnkov a všetkých poplatkov",
    resetHeights: "Pôvodné nastavenie",
};