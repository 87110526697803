import * as THREE from "three";
import React from "react";
import getMaterial from "./material";

/**
 * @param {String} type
 * @param {Array} doorAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const Type = ({type, doorAttributes}) => {
    const objectMaterial = getMaterial(doorAttributes['profile']);
    const height = doorAttributes['height'];

    let posY = 0 - height / 2;
    let posX = 0;
    const posZ = 1.8;

    let rotation = [Math.PI / 2, Math.PI, Math.PI];

    let handPositionY = 0.2;
    let handPositionX = -1.6;
    let handPositionZ = height / 2;
    let objectPositionZ = 0;

    if (type === 'right') {
        posX += doorAttributes['width'] / 2 - 1;
    } else if (type === 'left') {
        posX -= doorAttributes['width'] / 2 - 1;
        rotation = [-Math.PI / 2, Math.PI, 0];
        posY = height / 2 + 1;
        handPositionZ = 1 + height / 2
        objectPositionZ = 1;
    }

    const x = 0;
    const y = 0;
    const shape = new THREE.Shape();
    shape.moveTo(x + 20, y + 20);
    shape.bezierCurveTo(x + 25, y + 25, x + 20, y, x, y);
    shape.bezierCurveTo(x - 30, y, x - 30, y + 35, x - 30, y + 35);
    shape.bezierCurveTo(x, y, x, y + 25, x + 25, y + 60);

    const extrudeSettings = {
        steps: 1,
        depth: height,
        bevelEnabled: true,
        bevelThickness: 0,
        bevelSize: 1,
        bevelSegments: 2,
    };

    const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings);
    geometry.scale(0.05, 0.05, 1);

    const topObject = new THREE.Mesh(
        geometry,
        objectMaterial
    );

    const handObject = new THREE.Mesh(
        new THREE.BoxGeometry(3, 0.5, height),
        objectMaterial
    );

    return (
        <group position-y={posY} position-x={posX} position-z={posZ} rotation={rotation}>
            <mesh position-z={objectPositionZ}>
                <primitive object={topObject}/>
            </mesh>
            <mesh position-z={handPositionZ} position-y={handPositionY} position-x={handPositionX}>
                <primitive object={handObject}/>
            </mesh>
        </group>
    );
};

export default Type;