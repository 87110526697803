import * as THREE from "three";
import create from "zustand";
import {BOARD_DEPTH, HIDE_DOORS, SHOW_DOORS, HIDE_DIMENSIONS, SHOW_DIMENSIONS} from "./config";
import {getSpaceWidth, getSpacesCounts} from "./Space";
import loadable from '@loadable/component';
import {getCorpusesSpaces} from "./utils/corpuses";
import {isMobile} from 'react-device-detect';

export const [useStore, apiStore] = create((set, get) => {
    return {
        uuid: '',
        doorSpacesAttributes: {
            '2A1': 45,
            '3A1': 45,
            '3B0': 30,
            '3B2': 30,
            '5A1': 30,
            '5A3': 30,
        },
        inputFocused: null,
        doorFillsAttributes: '{}',
        priceValue: null,
        priceVersion: null,
        objType: '',
        cameraLocked: false,
        mainSize: isMobile ? 'mini' : 'big',
        dimensions: [],
        score: 0,
        points: 100,
        isDoorSetVisible: true,
        isDimensionsSetVisible: true,
        selectedSpace: false,
        doorFillsVersion: null,
        doorFills: {},
        doorFillsData: {},
        doorFillsIds: [],
        selectedDoorFillId: '1-1',
        selectedDoorFillIds: {},
        selectedProfile: {},
        selectedProfileId: null,
        selectedDTDType: 'main', //second
        showAttributes: '{}',
        additionalOption: '{}',
        mainDTDId: 0,
        mainDTD: [],
        secondDTDId: 0,
        secondDTD: [],
        amount: 0,
        boxes: [],
        coords: [],
        refreshCorpuses: false,
        selectedCorpuses: {1: 2, 2: 8},
        dividedSpaces: '{}',
        dividedSpacesWidth: '{}',
        selectedDoorsSpace: {},
        selectedDoorsSpaceAttributes: {},
        spacesCount: null,
        roomType: null,
        roomHeights: '{}',
        parameters: '{}',
        height: null,
        width: null,
        depth: null,
        bumpScale: 0.7,
        spaceHeight: 210,
        spaceWidth: 0,
        maxSpaces: 3,
        space: {
            selected: 3
        },
        selectDoorSpaceMode: 'simple',
        showModal: null,
        wallColor: '#cfd8dc',
        phoneNumber: null,
        emailAddress: null,
        setUUID(uuid) {
            set({uuid});
        },
        setPhoneNumber(phoneNumber) {
            set({phoneNumber});
        },
        setEmailAddress(emailAddress) {
            set({emailAddress});
        },
        setWallColor(color) {
            set({wallColor: color});
        },
        setSelectedDTDType(type) {
            set({selectedDTDType: type});
        },
        setObjType(type) {
            set({objType: type});
        },
        setShowModal(modalName) {
            set({showModal: modalName});
        },
        setCameraLocked(locked) {
            set({cameraLocked: locked});
        },
        getWidth() {
            let {width, spacesCount, roomType} = get();

            if (!roomType && !width) {
                if (!spacesCount) spacesCount = 2;

                width = spacesCount * 40;
            }

            return width;
        },
        getSpacesCount() {
            let {roomType, spacesCount} = get();

            if (!roomType && !spacesCount) {
                //spacesCount = 2;
            }

            return 2;
        },
        setMainDimensions(dimensions) {
            let size = 'big';

            if (dimensions.height < 600 || dimensions.width < 500) {
                size = 'mini';
            }

            set({mainSize: size, dimensions});
        },
        getDTD(type) {
            const {mainDTD, secondDTD} = get();

            return type === 'main' ? mainDTD : secondDTD;
        },
        setDoorFillForId(id, type, productId, doorFillImage) {
            let {doorFills, doorFillsData, doorFillsVersion} = get();

            doorFillsVersion = new Date().getTime();

            const doorFillTexture = new THREE.TextureLoader().load(
                process.env.PUBLIC_URL + "/images/products/" + doorFillImage
            );

            doorFills[id] = {type: type, texture: doorFillTexture, productId: productId};
            doorFillsData[id] = {id: id, type: type, doorFillImage: doorFillImage, productId: productId};

            const selectDoorSpaceMode = 'allSame';
            const doorFillsIds = [];

            Object.values(doorFills).forEach(function (doorFill) {
                const id = doorFill.productId.toString();

                if (!doorFillsIds.includes(id)) {
                    doorFillsIds.push(id);
                }
            })

            set({doorFills, doorFillsData, doorFillsIds, doorFillsVersion, selectDoorSpaceMode});
        },
        setDoorFill(type, productId, doorFillImage) {
            let {doorFills, doorFillsData, selectedDoorFillId, doorFillsVersion} = get();

            doorFillsVersion = new Date().getTime();

            const doorFillTexture = new THREE.TextureLoader().load(
                process.env.PUBLIC_URL + "/images/products/" + doorFillImage
            );
            /*
            if (selectedDoorFillIds) {
                Object.keys(selectedDoorFillIds).forEach(function (selectedDoorFillId) {
                    if (selectedDoorFillIds[selectedDoorFillId]) {
                        doorFills[selectedDoorFillId] = {type: type, texture: doorFillTexture, productId: productId};
                        doorFillsIds[selectedDoorFillId] = productId;
                    }
                })
            }*/
            doorFills[selectedDoorFillId] = {type: type, texture: doorFillTexture, productId: productId};
            doorFillsData[selectedDoorFillId] = {id: selectedDoorFillId, type: type, doorFillImage: doorFillImage, productId: productId};

            const selectDoorSpaceMode = 'allSame';
            const doorFillsIds = [];

            Object.values(doorFills).forEach(function (doorFill) {
                const id = doorFill.productId.toString();

                if (!doorFillsIds.includes(id)) {
                    doorFillsIds.push(id);
                }
            })

            set({doorFills, doorFillsData, doorFillsIds, doorFillsVersion, selectDoorSpaceMode});
        },
        selectNextDoorFill() {
            let {
                selectedDoorFillId,
                selectedDoorsSpace,
                setSelectedDoorFillId,
            } = get();

            const door = selectedDoorFillId.split('-');
            const doorId = door[0];
            let nextDoorFill = parseInt(door[1]) + 1;

            let selectedDoorsSpaceCount = 1;

            if (selectedDoorsSpace[doorId]) {
                selectedDoorsSpaceCount = selectedDoorsSpace[doorId];
            }

            if (selectedDoorsSpaceCount < nextDoorFill) {
                nextDoorFill = 1;
            }

            setSelectedDoorFillId(nextDoorFill);

            const doorFillId = doorId + '-' + nextDoorFill;

            set({selectedSpace: doorId, selectedDoorFillId: doorFillId});
        },
        getDoorFill(doorFillId) {
            const {doorFills} = get();

            if (doorFills[doorFillId]) {
                return doorFills[doorFillId];
            } else {
                return false;
            }
        },
        setDoorsProfile(section, type, id) {
            set({
                priceVersion: new Date().getTime(),
                selectedProfile: {section, type, id},
                selectedProfileId: id
            });
        },
        getDoorsProfileId() {
            const {selectedProfileId} = get();

            return selectedProfileId;
        },
        setDTDImage(type, DTDId, DTDImage) {
            const {selectedDTDType, objType} = get();

            if (type === '') {
                if ('v' === objType) {
                    type = 'main';
                } else if ('d' === objType) {
                    type = 'second';
                } else {
                    type = selectedDTDType;
                }
            }

            const dtdValue = new THREE.TextureLoader().load(
                process.env.PUBLIC_URL + "/images/products/" + DTDImage
            );

            dtdValue.wrapS = THREE.RepeatWrapping;
            dtdValue.wrapT = THREE.RepeatWrapping;

            if (type === 'main') {
                set({
                    priceVersion: new Date().getTime(),
                    mainDTDId: DTDId,
                    mainDTD: {
                        map: dtdValue,
                    },
                    secondDTDId: DTDId,
                    secondDTD: {
                        map: dtdValue,
                    }
                });
            } else {
                set({
                    priceVersion: new Date().getTime(),
                    secondDTDId: DTDId,
                    secondDTD: {
                        map: dtdValue,
                    }
                });
            }
        },
        setDTD(type, DTDId) {
            const dtdValue = new THREE.TextureLoader().load(
                "images/DTD/" + DTDId + "/Wood" + DTDId + "_col.jpg"
            );

            const dtdRGHValue = new THREE.TextureLoader().load(
                "images/DTD/" + DTDId + "/Wood" + DTDId + "_rgh.jpg"
            );

            const dtdDisplacementMapValue = new THREE.TextureLoader().load(
                "images/DTD/" + DTDId + "/Wood" + DTDId + "_disp.jpg"
            );

            if (DTDId !== "08") {
                dtdValue.rotation = Math.PI / 2;
                dtdRGHValue.rotation = Math.PI / 2;
                dtdDisplacementMapValue.rotation = Math.PI / 2;
            }
            //rotation={}

            dtdValue.wrapS = THREE.RepeatWrapping;
            dtdValue.wrapT = THREE.RepeatWrapping;

            dtdRGHValue.wrapS = THREE.RepeatWrapping;
            dtdRGHValue.wrapT = THREE.RepeatWrapping;

            dtdDisplacementMapValue.wrapS = THREE.RepeatWrapping;
            dtdDisplacementMapValue.wrapT = THREE.RepeatWrapping;

            if (type === 'main') {
                set({
                    priceVersion: new Date().getTime(),
                    mainDTDId: DTDId,
                    mainDTD: {
                        map: dtdValue,
                        rgh: dtdRGHValue,
                        displacement: dtdDisplacementMapValue
                    }
                });
            } else {
                set({
                    priceVersion: new Date().getTime(),
                    secondDTDId: DTDId,
                    secondDTD: {
                        map: dtdValue,
                        rgh: dtdRGHValue,
                        displacement: dtdDisplacementMapValue
                    }
                });
            }
        },
        /**
         * @param {String} type
         * @param {Boolean} value
         */
        setShowAttributes(type, value) {
            let {showAttributes} = get();

            const showAttributesData = JSON.parse(showAttributes);
            showAttributesData[type] = value;

            set({showAttributes: JSON.stringify(showAttributesData)});
        },
        setScore() {
            let {score} = get();
            score++;
            set({score});
        },
        setSpace(spaceId, disableSpace = false) {
            let {selectedSpace} = get();

            if (disableSpace && selectedSpace === spaceId) {
                spaceId = null;
            }

            set({selectedSpace: spaceId});
        },
        setNextSpace() {
            let {selectedSpace, spacesCount} = get();
            let spaceId = (selectedSpace * 1) + 1;

            if (spacesCount < spaceId) spaceId = 1

            set({selectedSpace: spaceId});
        },
        setNextCorpusesSpace() {
            let {selectedSpace, spacesCount, dividedSpaces, roomWidth, roomType, objType} = get();
            const spaceWidth = getSpaceWidth(objType, roomWidth, spacesCount, roomType);
            const {nextId, firstId} = getCorpusesSpaces(spaceWidth, spacesCount, JSON.parse(dividedSpaces), selectedSpace);

            set({selectedSpace: nextId ?? firstId});
        },
        setSelectedDoorFillId(doorFillId) {
            let {selectedDoorFillIds, selectDoorSpaceMode} = get();
            /*
            if (selectedDoorFillIds && selectedDoorFillIds[doorFillId]) {
                selectedDoorFillIds[doorFillId] = false;
            } else {

                if (selectDoorSpaceMode === 'allSame') {
                    let product = null;

                    if (doorFills[doorFillId]) {
                        product = doorFills[doorFillId].productId;
                    }

                    selectedDoorFillIds = {};

                    for (let i = 1; i <= spacesCount; i++) {
                        if (selectedDoorsSpace[i]) {
                            for (let v = 1; v <= selectedDoorsSpace[i]; v++) {
                                doorFillId = i + '-' + v;

                                if (doorFills[doorFillId]) {
                                    if (product === doorFills[doorFillId].productId) {
                                        selectedDoorFillIds[doorFillId] = true;
                                    }
                                } else if (product === null) {
                                    selectedDoorFillIds[doorFillId] = true;
                                }
                            }
                        }
                    }

                    selectDoorSpaceMode = 'simple';
                } else {
                    selectedDoorFillIds[doorFillId] = true;
                }
            }
*/
            set({selectedDoorFillId: doorFillId, selectedDoorFillIds, selectDoorSpaceMode});
        },
        isSelectedDoorFillId(doorFillId) {
            let {selectedDoorFillId} = get();

            return (doorFillId === selectedDoorFillId);
        },
        setRoomType(roomType) {
            set({roomType});
        },
        saveSpacesCount(spacesCount) {
            let {width} = get();

            let spaceWidth =
                (width - (spacesCount + 1) * BOARD_DEPTH) / spacesCount;

            set({
                spacesCount,
                spaceWidth
            });
        },
        saveDoorSpacesCount(spacesCount) {
            let {selectedSpace, selectedDoorsSpace} = get();

            if (!selectedSpace) selectedSpace = 1;
            selectedDoorsSpace[selectedSpace] = spacesCount;

            set({
                selectedDoorsSpace,
            });
        },
        saveDoorSpacesAttributes(spacesAttributes) {
            let {selectedSpace, selectedDoorsSpaceAttributes} = get();

            selectedDoorsSpaceAttributes[selectedSpace] = spacesAttributes;

            set({
                selectedDoorsSpaceAttributes,
            });
        },
        setRoomWidth(roomWidthValue) {
            roomWidthValue = parseFloat(roomWidthValue);

            let {width, spacesCount, roomType, objType} = get();

            if (roomWidthValue && 45 <= roomWidthValue && roomWidthValue <= 700) {
                width = roomWidthValue;

                set({
                    width: roomWidthValue
                });

                if (spacesCount > 1) {
                    const actualSpaceWidth = getSpaceWidth(
                        objType,
                        width,
                        spacesCount,
                        roomType
                    );

                    if (100 <= actualSpaceWidth || actualSpaceWidth <= 50) {
                        const spacesCounts = getSpacesCounts(width, roomType, objType);
                        const minNumCount = Math.min(...spacesCounts);
                        const maxNumCount = Math.max(...spacesCounts);
                        let saveCount = false;

                        if (spacesCount < minNumCount) {
                            spacesCount = minNumCount;
                            saveCount = true;
                        } else if (spacesCount > maxNumCount) {
                            spacesCount = maxNumCount;
                            saveCount = true;
                        }

                        if (saveCount) {
                            set({spacesCount});
                        }
                    }
                }
            }
        },
        getRoomHeight() {
            let {height} = get();

            return height;
        },
        setRoomHeights(type, height) {
            let {roomHeights} = get();

            const roomHeightsData = JSON.parse(roomHeights);
            roomHeightsData[type] = height;

            set({roomHeights: JSON.stringify(roomHeightsData)});
        },
        setParameters(type, value) {
            let {parameters} = get();

            const parametersData = JSON.parse(parameters);
            parametersData[type] = value;

            set({parameters: JSON.stringify(parametersData)});
        },
        setRoomHeight(roomHeightValue) {
            let {height, width, spacesCount, roomType, objType, roomHeights} = get();

            if (roomHeightValue === 'max') {
                roomHeightValue = Math.max(...Object.values(JSON.parse(roomHeights)));
            }

            roomHeightValue = parseFloat(roomHeightValue);

            let spaceHeight = 210;
            const minRoomHeight = objType === 'd' ? 70 : 230;

            if (roomHeightValue && minRoomHeight <= roomHeightValue && roomHeightValue <= 279) {
                height = roomHeightValue;

                if (roomHeightValue <= 279 && roomHeightValue >= 259.5){
                    spaceHeight = 220;
                } else if (roomHeightValue < 259.5 && roomHeightValue >= 239.5) {
                    spaceHeight = 210;
                } else if  (roomHeightValue < 239.5 && roomHeightValue >= 230) {
                    spaceHeight = 195;
                }

                if (spacesCount > 1) {
                    const actualSpaceWidth = getSpaceWidth(
                        objType,
                        width,
                        spacesCount,
                        roomType
                    );

                    if (100 <= actualSpaceWidth || actualSpaceWidth <= 50) {
                        const spacesCounts = getSpacesCounts(width, roomType, objType);
                        const minNumCount = Math.min(...spacesCounts);
                        const maxNumCount = Math.max(...spacesCounts);
                        let saveCount = false;

                        if (spacesCount < minNumCount) {
                            spacesCount = minNumCount;
                            saveCount = true;
                        } else if (spacesCount > maxNumCount) {
                            spacesCount = maxNumCount;
                            saveCount = true;
                        }

                        if (saveCount) {
                            set({spacesCount});
                        }
                    }
                }

                set({
                    height,
                    spaceHeight
                });
            }
        },

        setRoomDepth(depth) {
            depth = parseFloat(depth);

            if (depth && 35 <= depth && depth <= 90) {
                set({
                    depth
                });
            }
        },

        setInputFocus(inputType) {
            set({inputFocused: inputType});
        },

        create(amount, height, width, depth, spaceHeight) {
            set({
                amount,
                spaceHeight
            });
        },

        setCorpusType(actualSpace, corpusType) {
            const {selectedCorpuses} = get();

            selectedCorpuses[actualSpace] = corpusType;

            set({selectedCorpuses});
        },

        setCorpusTypeForSelectedSpace(corpusType) {
            const {selectedCorpuses} = get();

            selectedCorpuses[1] = corpusType;

            set({selectedCorpuses});
        },

        setDoorSpacesAttributes(doorSpaceId, value) {
            const {doorSpacesAttributes} = get();

            doorSpacesAttributes[doorSpaceId] = value;

            set({doorSpacesAttributes});
        },

        setDoorFillsAttributes(doorSpaceId, value) {
            const {doorFillsAttributes} = get();
            const doorFillsAttributesData = JSON.parse(doorFillsAttributes);

            doorFillsAttributesData[doorSpaceId] = value;

            set({doorFillsAttributes: JSON.stringify(doorFillsAttributesData)});
        },

        deleteDoorFillsAttributes() {
            set({doorFillsAttributes: '{}'});
        },

        setDoorType(actualSpace, spacesCount) {
            let {selectedDoorsSpace} = get();

            selectedDoorsSpace[actualSpace] = spacesCount;

            set({
                selectedDoorsSpace,
            });
        },

        isAllCorpusesSet() {
            const {selectedCorpuses, spacesCount} = get();

            return (Object.values(selectedCorpuses).length === spacesCount);
        },

        getCorpuseElement(id) {
            const {selectedCorpuses} = get();

            const corpuseType = selectedCorpuses[id] ? selectedCorpuses[id] : 1;

            return loadable(props => import(`./Preview/Corpuses/Type${corpuseType}`));
        },
        setDoorVisibility(iVisible) {
            set({
                isDoorSetVisible: iVisible,
            });
        },
        getDoorVisibility(step) {
            const {isDoorSetVisible, spacesCount} = get();

            let canBeChanged = false;
            let iVisible = isDoorSetVisible && spacesCount;

            if (0 <= HIDE_DOORS.indexOf(step)) {
                if (step !== 'roomType') {
                    canBeChanged = true;
                }
                iVisible = false;
            }

            if (0 < SHOW_DOORS.indexOf(step)) {
                canBeChanged = true;
                iVisible = true;
            }

            return {iVisible, canBeChanged}
        },
        setSpaceDivided(spaceSeq, isDivided) {
            let {dividedSpaces} = get();

            dividedSpaces = JSON.parse(dividedSpaces);
            dividedSpaces[spaceSeq] = isDivided;

            set({dividedSpaces: JSON.stringify(dividedSpaces)});
        },
        setSpaceDividedWidth(spaceSeq, spaceId, width) {
            let {dividedSpacesWidth} = get();
            let convertedDividedSpacesWidth = JSON.parse(dividedSpacesWidth);
            const otherId = spaceId === spaceSeq + 'A' ? spaceSeq + 'B' : spaceSeq + 'A';

            if (width) {
                if (convertedDividedSpacesWidth[otherId]) {
                    delete convertedDividedSpacesWidth[otherId];
                }

                convertedDividedSpacesWidth[spaceId] = width;
            } else if (convertedDividedSpacesWidth[spaceId]) {
                delete convertedDividedSpacesWidth[spaceId];
            }

            set({dividedSpacesWidth: JSON.stringify(convertedDividedSpacesWidth)});
        },
        setDimensionsVisibility(iVisible) {
            set({
                isDimensionsSetVisible: iVisible,
            });
        },
        /**
         * @param {String} step
         * @param {Boolean} isDimensionsSetVisible
         * @param {Boolean} isDoorSetVisible
         * @returns {{canBeChanged: boolean, iVisible: boolean}}
         */
        /**
         * @param step
         * @param isDimensionsSetVisible
         * @param isDoorSetVisible
         * @returns {{canBeChanged: boolean, iVisible: any}}
         */
        getDimensionsVisibility(step, isDimensionsSetVisible, isDoorSetVisible) {
            let canBeChanged = false;
            let iVisible = isDimensionsSetVisible;

            if (HIDE_DIMENSIONS.indexOf(step) !== -1) {
                canBeChanged = true;
                iVisible = false;
            }

            if (SHOW_DIMENSIONS.indexOf(step) !== -1) {
                canBeChanged = true;
                iVisible = true;
            }

            return {iVisible, canBeChanged}
        },
        /**
         * @param {Number} value
         */
        setPrice(value) {
            set({
                priceValue: value,
            });
        },
        /**
         * @returns {Number}
         */
        getPrice() {
            const {priceValue} = get();

            return priceValue;
        },
        setPriceVersion() {
            set({
                priceVersion: new Date().getTime(),
            });
        },

        /**
         * @param {string} type
         * @param value
         */
        setAdditionalOption(type, value) {
            let {additionalOption} = get();

            const additionalOptionData = JSON.parse(additionalOption);
            additionalOptionData[type] = value;

            set({additionalOption: JSON.stringify(additionalOptionData)});
        },
        setDividedSpacesData(dividedSpacesData) {
            set({dividedSpaces: JSON.stringify(dividedSpacesData)});
        },
        setDividedSpacesWidthData(dividedSpacesWidthData) {
            set({dividedSpacesWidth: JSON.stringify(dividedSpacesWidthData)});
        },
        setSelectedProfileData(selectedProfileData) {
            set({selectedProfile: selectedProfileData});
        },
        setSelectedProfileIdData(selectedProfileIdData) {
            set({selectedProfileId: selectedProfileIdData});
        },
        setDoorFillsAttributesData(doorFillsAttributesData) {
            set({doorFillsAttributes: JSON.stringify(doorFillsAttributesData)});
        },
        setSelectedDoorsSpaceData(selectedDoorsSpaceData) {
            set({selectedDoorsSpace: selectedDoorsSpaceData});
        },
        setSelectedDoorsSpaceAttributesData(selectedDoorsSpaceAttributesData) {
            set({selectedDoorsSpaceAttributes: selectedDoorsSpaceAttributesData});
        },
        setDoorFillsIdsData(doorFillsIdsData) {
            set({doorFillsIds: doorFillsIdsData});
        },
        setSelectedCorpusesData(selectedCorpusesData) {
            set({selectedCorpuses: selectedCorpusesData});
        },
        setParametersData(parametersData) {
            set({parameters: parametersData});
        },
    };
});