import React, {useRef, useEffect} from "react";
import {useLightStore} from "./LightStore";
import {useFrame} from 'react-three-fiber'
import {useStore} from "../store";

/**
 * @returns {*}
 * @constructor
 */
const Lights = ({roomAttributes}) => {
    const main = useRef();
    const isSpotLightOn = useLightStore(state => state.isSpotLightOn);
    const isPointLightOn = useLightStore(state => state.isPointLightOn);
    const spacesCount = useStore(state => state.spacesCount);
    const roomWidth = roomAttributes.width;
    const roomHeight = roomAttributes.height;
    const roomDepth = roomAttributes.depth;
    const selectedSpace = useStore(state => state.selectedSpace);
    const spaceHeight = useStore(state => state.spaceHeight);

    let actualXPosition = 0;
    const defX = roomWidth / spacesCount;
    if (selectedSpace > 0) {
        actualXPosition = Math.floor((selectedSpace * defX) - defX / 2);
    }

    let different = 0;
    const speed = 8;

    useFrame(() => {
        if (main.current.target.position.x > actualXPosition && actualXPosition) {
            main.current.target.position.x -= speed;
            main.current.target.updateMatrixWorld();
        } else if (main.current.target.position.x < actualXPosition && actualXPosition) {
            main.current.target.position.x += speed;
            main.current.target.updateMatrixWorld();
        }
        different = Math.abs(main.current.target.position.x - actualXPosition);

        if (different < speed) {
            main.current.target.position.x = actualXPosition;
        }
    });

    useEffect(() => {
        void main.current.target.position.set(roomWidth / 2, spaceHeight / 2, 0)
        main.current.target.updateMatrixWorld();
    }, [roomWidth, spaceHeight])

    return (<>
        <ambientLight intensity={0.5}/>
        <pointLight position={[roomWidth / 2, roomHeight, roomDepth + 150]} intensity={(0.4)} visible={isPointLightOn}/>
        <spotLight position={[roomWidth / 2, roomHeight, roomDepth + 300]}
                   ref={main}
                   angle={0.4}
                   penumbra={0.5}
                   decay={2}
                   intensity={0.1}
                   color='#ffffff'
                   visible={(isSpotLightOn && selectedSpace)}/>
    </>)
};

export default Lights;