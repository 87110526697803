import React, {useMemo} from "react";
import VerticalSidebar from "../Components/Molecules/VerticalSidebar";
import TopMenu from "../Menu/TopMenu";
import {useStore} from "../../../store";
import {Card} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {Lang} from "../../../Utils";
import MiddleMenu from "../Menu/MiddleMenu";
import {START_STEP} from "../../../config";
import SelectDTDSimple from "../Menu/SelectDTDSimple";

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDTDOut = ({fullScreenHandle, onClickSteps}) => {
    const objType = useStore(state => state.objType);
    const mainSize = useStore(state => state.mainSize);
    const selectedDTDType = useStore(state => state.selectedDTDType);
    const secondDTDId = useStore(state => state.secondDTDId);

    const isTypeNotSelected = useMemo(() => {
        return objType === '';
    }, [objType]);

    if (isTypeNotSelected) {
        return <Redirect to={'/' + Lang(START_STEP, 'navigation')}/>
    }

    return (
        <>
            <TopMenu/>
            <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps}/>
            <Card>
                <SelectDTDSimple
                    mainSize={mainSize}
                    objType={objType}
                    selectedDTDType={selectedDTDType}
                    onClickSteps={onClickSteps}
                    dtdId={secondDTDId}
                />
            </Card>
            <VerticalSidebar onClickSteps={onClickSteps}/>
        </>
    );
};

export default SelectDTDOut;