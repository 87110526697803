import React, {useCallback, useState} from "react";
import {useStore} from "../../../store";
import {useSteps} from "../../../Stores/Steps";
import {makeStyles, Modal, Box, Grid, Button, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Lang} from "../../../Utils";
import Alert from '@material-ui/lab/Alert';
import ErrorDialog from "../Components/Molecules/ErrorDialog";
import NumberFormat from 'react-number-format';
import {isEmailAddressValid} from "../../../utils/control";
import {apiOrder} from "../../../Stores/Order";

const useStyles = makeStyles((theme) => ({
    form: {
        paddingBottom: 20,
    },
    mainBox: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    infoBox: {
        width: 330,
        paddingTop: 10,
        paddingBottom: 10,
    },
    errorBox: {
        paddingTop: 10,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainGridCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainGridRight: {
        textAlign: 'right',
    },
    buttonActiveText: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    buttonActiveCaption: {
        margin: 0,
        padding: 0,
        fontSize: 12,
    },
    previewButtonActive: {
        textTransform: 'none',
    },
    nextButtonActive: {
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
}));

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            defaultValue="421"
            format="+### ### ### ###"
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

/**
 * @returns {*}
 * @constructor
 */
const PhoneSave = ({orContactUs, onSavePhoneEmail}) => {
    const classes = useStyles();
    const [errorTexts, setErrorText] = useState({});
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const isAttributesModalOpen = useSteps(state => state.isModalOpen['phoneSave']);
    const closeModal = useSteps(state => state.closeModal);
    const openModal = useSteps(state => state.openModal);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const phoneNumber = useStore(state => state.phoneNumber);
    const emailAddress = useStore(state => state.emailAddress);
    const setPhoneNumber = useStore(state => state.setPhoneNumber);
    const setEmailAddress = useStore(state => state.setEmailAddress);
    const setPriceVersion = useStore(state => state.setPriceVersion);

    const [inputPhoneValue, setInputPhoneValue] = useState(phoneNumber);
    const [inputEmailValue, setInputEmailValue] = useState(emailAddress);

    const handleClose = () => {
        closeModal('phoneSave');
        setIsFirstOpen(false);
    };

    const handleOpen = useCallback(
        () => {
            openModal('phoneSave');
            setInputPhoneValue(phoneNumber);
            setInputEmailValue(emailAddress);
        },
        [openModal, setInputPhoneValue, setInputEmailValue, phoneNumber, emailAddress]
    );

    const onClickSave = useCallback(
        () => {
            const errorTexts = {};

            if (inputEmailValue && !isEmailAddressValid(inputEmailValue)) {
                errorTexts['email'] = Lang('BadEmailFormatError', 'inputs');
            }

            if (Object.keys(errorTexts).length > 0) {
                setErrorText(errorTexts);
                setOpenErrorDialog(true);
            } else {
                setPhoneNumber(inputPhoneValue);
                setEmailAddress(inputEmailValue);
                setPriceVersion();
                apiOrder.getState().setInsert(true);
                onSavePhoneEmail();
                closeModal('phoneSave');
            }
        },
        [setOpenErrorDialog, setErrorText, closeModal, inputPhoneValue, inputEmailValue, setPriceVersion, onSavePhoneEmail, setPhoneNumber, setEmailAddress]
    );

    const openDialogErrors = [];

    if (errorTexts) {
        if (errorTexts['email']) {
            openDialogErrors.push(errorTexts['email']);
        }
    }

    const handleChangePhone = useCallback(
        (event) => {
            setInputPhoneValue(event.target.value);
        },
        [setPhoneNumber]
    );

    const handleChangeEmail = useCallback(
        (event) => {
            setInputEmailValue(event.target.value);
            errorTexts['email'] = '';
            setErrorText(errorTexts);
        },
        [setInputEmailValue, setErrorText, errorTexts]
    );

    const handleBlurEmail = useCallback(
        (event) => {
            errorTexts['email'] = '';

            if (event.target.value !== '' && !isEmailAddressValid(event.target.value)) {
                errorTexts['email'] = Lang('BadEmailFormatError', 'inputs');
            }

            setErrorText(errorTexts);
        },
        [setErrorText, errorTexts]
    );

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isAttributesModalOpen ?? isFirstOpen}
                onClose={handleClose}
                closeAfterTransition
                disableEnforceFocus
                disableBackdropClick
            >
                <div className={classes.paper}>
                    <form className={classes.form} noValidate autoComplete="off">
                        <Box className={classes.infoBox}>
                            <Alert
                                severity="info"
                            >
                                {Lang('SavePhoneInfo', 'info')}{orContactUs.split(/[\r\n]+/).map((line, index) => {
                                    if (index === 0) {
                                        return (<>{line}</>);
                                    } else {
                                        return (<div>{line}</div>);
                                    }
                                })}
                            </Alert>
                        </Box>
                        <TextField
                            label={Lang('Phone')}
                            value={inputPhoneValue}
                            onChange={handleChangePhone}
                            name="phone"
                            id="phone-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                        <Box className={classes.infoBox}>
                            <Alert severity="info">{Lang('SaveEmailInfo', 'info')}</Alert>
                        </Box>
                        <TextField
                            error={(errorTexts['email'])}
                            label={Lang('Email')}
                            value={inputEmailValue}
                            onChange={handleChangeEmail}
                            onBlur={handleBlurEmail}
                            name="emailAddress"
                            id="email-address-input"
                            helperText={errorTexts['email']}
                        />
                    </form>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Button
                                variant="outlined"
                                className={classes.previewButtonActive}
                                onClick={handleClose}
                            >
                                <div className={classes.buttonActiveDiv}>
                                    <Typography className={classes.buttonActiveCaption}>
                                        {Lang("Skip")}
                                    </Typography>
                                </div>
                            </Button>
                        </Grid>
                        <Grid item xs className={classes.mainGridRight}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onClickSave()}
                                className={classes.nextButtonActive}
                            >
                                <Typography className={classes.buttonActiveText}>
                                    {Lang("Save")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            <ErrorDialog
                openDialogErrors={openDialogErrors}
                openErrorDialog={openErrorDialog}
                setOpenErrorDialog={setOpenErrorDialog}
            />
        </>
    );
};

export default PhoneSave;